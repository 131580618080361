// NOTE: allSafelist means this feature is safelisted for all outputTypes
const allSafelist = [
  "fronts/flex-feature",
  "fronts/carousel",
  "fronts/state-nav-dropdown",
  "fronts/newsletter-module",
  "fronts/in-table-ad",
  "fronts/in-table-habit-tiles"
];

// NOTE: defaultSafelist means this feature is safelisted for the default outputType
export const defaultSafelist = [
  ...allSafelist,
  "fronts/diversions",
  "fronts/in-line-brandconnect-ad"
];

// NOTE: jsonappSafelist means this feature is safelisted for the jsonapp outputType
export const jsonappSafelist = [...allSafelist];

/**
 * Because a feature needs a lot of special treatment to work in the multi-chain table
 * only specific feartures are allowed in. All others are prohibited. Working in the jsonapp
 * is even more stringent, so there are additional ones blocked there.
 *
 * @param {object} props - item of the childProps list
 * @param {string} outputType - the outputType.
 *
 * @returns {boolean} - Whether the feature if supported by the multi-table chain
 *
 */
export const isSafeListed = ({ props = {}, outputType = "default" }) => {
  switch (outputType) {
    case "jsonapp":
      return jsonappSafelist.includes(props.type);
    default:
      return defaultSafelist.includes(props.type);
  }
};
