import React, { useContext, useState, useEffect, createContext } from "react";
import PropTypes from "@arc-fusion/prop-types";

const AdRenderContext = createContext();

function AdRenderProvider({ children }) {
  const [adsViewable, setAdsViewable] = useState([]);

  useEffect(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.cmd.push(() => {
      window.googletag
        .pubads()
        .addEventListener("impressionViewable", (event) => {
          const slotId = event.slot.getSlotElementId();
          setAdsViewable((prev) => {
            return [...prev, slotId];
          });
        });
    });
  }, []);

  return (
    <AdRenderContext.Provider value={{ adsViewable }}>
      {children}
    </AdRenderContext.Provider>
  );
}
AdRenderProvider.propTypes = {
  children: PropTypes.node
};

function useAdRenderState() {
  return useContext(AdRenderContext);
}

export { AdRenderProvider, useAdRenderState };
