import { Duration } from "luxon";

/**
 * Accepts a timestamp string and formats it to EST to be used atop articles
 *
 * @param {string} dateString="" a valid dateString
 * @returns {string} formatted date string
 */
export const formatToEST = (dateString = "") => {
  try {
    return dateString && Date.parse(dateString)
      ? new Date(dateString)
          .toLocaleString("en-US", {
            timeZone: "America/New_York",
            year: "numeric",
            day: "numeric",
            month: "long",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short"
          })
          .replace(/(,)(.*?)(,)/, "$1$2 at") // replace second comma with 'at'
      : "";
  } catch (ex) {
    return dateString;
  }
};

/**
 * Accepts a timestamp string and formats only the date it to EST
 *
 * @param {string} dateString="" a valid dateString
 * @returns {string} formatted date string
 */
export const formatToESTDateOnly = (dateString = "") => {
  try {
    return dateString && Date.parse(dateString)
      ? new Date(dateString).toLocaleString("en-US", {
          timeZone: "America/New_York",
          year: "numeric",
          day: "numeric",
          month: "long"
        })
      : "";
  } catch (ex) {
    return dateString;
  }
};

/**
 * Accepts a timestamp string and formats only the date it to EST
 *
 * @param {string} dateString="" a valid dateString
 * @returns {string} formatted date string
 */
export const formatToLocalDateOnly = (dateString = "") => {
  return dateString && Date.parse(dateString)
    ? new Date(dateString).toLocaleString("en-US", {
        localeMatcher: "best fit",
        year: "numeric",
        day: "numeric",
        month: "long"
      })
    : "";
};

const checkZero = (data) => {
  if (data < 10) {
    return `0${data}`;
  }
  return data;
};

// return MM/DD/YYYY date format
export const formatToMMDDYYYY = (date) => {
  if (!date) return "Invalid Date";
  const displayDate = new Date(date);

  const mm = displayDate.getMonth() + 1;
  const dd = displayDate.getDate();
  const yyyy = displayDate.getFullYear();
  if (!mm || !dd || !yyyy) return "Invalid Date";
  return `${checkZero(mm)}/${checkZero(dd)}/${yyyy}`;
};

// return YYYYMMDD date format
export const formatToYYYYMMDD = (date) => {
  if (!date) return "Invalid Date";
  const displayDate = new Date(date);

  const mm = displayDate.getMonth() + 1;
  const dd = displayDate.getDate();
  const yyyy = displayDate.getFullYear();
  if (!mm || !dd || !yyyy) return "Invalid Date";
  return `${yyyy}${checkZero(mm)}${checkZero(dd)}`;
};

/**
 * @returns {string} formatted date string after adding 1 to the date
 */
export const plusOneDay = (expDt) => {
  const stringDate = expDt.toString();
  const newExpDate = new Date(`${stringDate}-05:00`);
  const FormatDate = new Date(newExpDate);

  FormatDate.setDate(FormatDate.getDate() + 1);

  const dd = FormatDate.getDate();
  const mm = FormatDate.getMonth() + 1;
  const y = FormatDate.getFullYear();

  const finalDate = `${mm}/${dd}/${y}`;
  const finalFormatDate = formatToLocalDateOnly(finalDate);
  return finalFormatDate;
};

export const getDayOfTheWeek = (datetime) => {
  return new Date(datetime).toLocaleString("en", { weekday: "long" });
};

/**
 *
 * @param minutes minutes value as an integer
 * @returns parsed string that seperates minutes into the format -- hours -- mins
 */
export function toHoursAndMinutesFromMinutes(mins) {
  const duration = Duration.fromObject({ hours: 0, minutes: mins })
    .normalize()
    .toObject();

  const hours = duration?.hours || 0;
  const minutes = duration?.minutes || 0;

  return (
    (hours > 0 ? `${hours} ${hours > 1 ? "hours " : "hour "}` : "") +
    (minutes > 0 ? `${minutes} ${minutes > 1 ? "mins" : "min"}` : "")
  );
}
