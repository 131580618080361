import {
  labelIcons,
  labelTypes,
  ctaLabelTypes
} from "~/components/features/fronts/utilities/common-front-properties";

export const makeGetKey = (namespace) => (suffix) => {
  suffix = namespace ? suffix[0].toUpperCase().concat(suffix.slice(1)) : suffix;
  return `${namespace}${suffix}`;
};

const getKeys = (namespace) => {
  // NOTE: Ultimately, these keys get transformed into:
  //  1. A prop value that's used by the Label components itself, e.g.
  //      "Url" -> "labelUrl"
  //      etc.
  //  2. And a path value corresponding to the actual custom fields of this particular label, e.g.
  //      "Url" -> "${namespace}LabelUrl"
  //      etc.
  const keys = ["Url", "Type", "Show", "Alignment", "Icon"];

  // NOTE: filter out (or add) keys that are needed for a particular label
  // NOTE: Cta will match the chain and table cta
  if (/Cta/.test(namespace)) return keys.filter((v) => /^(Url|Show)$/.test(v));
  switch (namespace) {
    // NOTE: No menu items
    case "liveTicker":
    case "relatedLinks":
      // return keys.filter((v) => /^Type$/.test(v));
      return keys.filter(() => false);
    case "cta":
      return keys.filter((v) => !/^Type|Alignment|Icon/.test(v));
    default:
      return keys;
  }
};

export const getPrimaryLabelMenuItems = (obj, namespace = "") => {
  const getUpdatePath = makeGetKey(namespace);

  const fields = getKeys(namespace).reduce((acc, key) => {
    const prop = `label${key}`;
    acc[key] = {
      prop,
      path: getUpdatePath(prop)
    };
    return acc;
  }, {});

  return [
    // NOTE: This construct...
    //
    //    ...(fields.prop ? [{ ... }] : [])
    //
    // ...will only add to the array if fields.prop exists
    // which is ultimately controlled by the keys arrray (see above)

    ...(fields.Url
      ? [
          {
            buttonType: "link",
            defaultValue: obj[fields.Url.prop],
            updatePath: fields.Url.path
          }
        ]
      : []),

    ...(fields.Type
      ? [
          {
            buttonType: "labelType",
            defaultValue: obj[fields.Type.prop],
            updatePath: fields.Type.path
          }
        ]
      : []),

    ...(fields.Show
      ? [
          {
            buttonType: "hide",
            updatePath: fields.Show.path
          }
        ]
      : []),

    ...(fields.Alignment
      ? [
          {
            buttonType: "leftAlign",
            defaultValue: obj[fields.Alignment.prop] === "left",
            updatePath: fields.Alignment.path
          }
        ]
      : []),

    ...(fields.Alignment
      ? [
          {
            buttonType: "centerAlign",
            defaultValue: obj[fields.Alignment.prop] === "center",
            updatePath: fields.Alignment.path
          }
        ]
      : []),

    ...(fields.Icon
      ? [
          {
            buttonType: "icon",
            defaultValue: obj[fields.Icon.prop],
            updatePath: fields.Icon.path
          }
        ]
      : [])
  ];
};

export const getLabelToolbarItems = ({ isAdmin, path, menuItems }) => {
  return !isAdmin
    ? { path }
    : {
        path,
        menuItems: menuItems.map(({ buttonType, defaultValue, updatePath }) => {
          switch (buttonType) {
            case "link":
              return {
                type: "textInput",
                props: { iconName: "link", label: "Link" },
                initialVals: { defaultValue },
                handlers: {
                  onBlur: (props) => {
                    props.setEntity((entity) => {
                      entity.props.customFields[updatePath] = props.value;
                    });
                  }
                }
              };
            case "labelType":
              return {
                type: "select",
                props: {
                  items: Object.entries(
                    /cta/i.test(path) ? ctaLabelTypes : labelTypes
                  ),
                  labelIcon: "textType"
                },
                initialVals: {
                  defaultValue
                },
                handlers: {
                  onChange: (props) => {
                    props.setEntity((entity) => {
                      entity.props.customFields[updatePath] = props.value;
                    });
                  }
                }
              };
            case "hide":
              return {
                type: "button",
                props: {
                  iconName: "hide"
                },
                initialVals: { selected: false },
                handlers: {
                  onClick: (props) => {
                    props.setEntity((entity) => {
                      entity.props.customFields[updatePath] =
                        !entity.props.customFields[updatePath];
                    });
                  }
                }
              };
            case "leftAlign":
              return {
                type: "button",
                props: {
                  iconName: "leftAlign"
                },
                initialVals: { selected: defaultValue },
                handlers: {
                  onClick: (props) => {
                    props.setEntity((entity) => {
                      entity.props.customFields[updatePath] =
                        entity.props.customFields[updatePath] === "left"
                          ? "inherit"
                          : "left";
                    });
                  }
                }
              };
            case "centerAlign":
              return {
                type: "button",
                props: {
                  iconName: "centerAlign"
                },
                initialVals: { selected: defaultValue },
                handlers: {
                  onClick: (props) => {
                    props.setEntity((entity) => {
                      entity.props.customFields[updatePath] =
                        entity.props.customFields[updatePath] === "center"
                          ? "inherit"
                          : "center";
                    });
                  }
                }
              };
            case "icon":
              return {
                type: "select",
                props: {
                  items: Object.entries(labelIcons),
                  labelIcon: "icon"
                },
                initialVals: {
                  defaultValue
                },
                handlers: {
                  onChange: (props) => {
                    props.setEntity((entity) => {
                      entity.props.customFields[updatePath] = props.value;
                    });
                  }
                }
              };
            default:
              return null;
          }
        })
      };
};
