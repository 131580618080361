import React from "react";
import PropTypes from "prop-types";

// utils
import { ConditionalWrapper } from "~/shared-components/story-card/_utilities/components";
import { getSeparator } from "~/shared-components/story-card/_children/Sigline.helpers";

const AuthorBioUrl = ({ name, url, org }) => {
  return (
    <ConditionalWrapper
      condition={url}
      wrapper={(children) => <a href={url}>{children}</a>}
    >
      {name || org}
    </ConditionalWrapper>
  );
};

export const Byline = ({ authors }) => {
  // not showing `opinions by` for now
  const prefix = "";

  return (
    <React.Fragment>
      {authors.map((author, index) => {
        const { name, url, org } = author;

        // Very rare edge case typically caused
        // By a referent not properly inflating
        if (!name && !url && !org) return null;

        return (
          <span key={index}>
            {index === 0 ? prefix : ``}
            <AuthorBioUrl name={name} url={url} org={org} />
            {getSeparator({ items: authors, i: index })}
          </span>
        );
      })}
    </React.Fragment>
  );
};

export const authorShape = PropTypes.shape({
  name: PropTypes.string,
  org: PropTypes.string,
  url: PropTypes.string
});

AuthorBioUrl.propTypes = {
  name: PropTypes.string,
  org: PropTypes.string,
  url: PropTypes.string
};

Byline.propTypes = {
  authors: PropTypes.arrayOf(authorShape),
  timestamp: PropTypes.string
};
