/*
 * NOTE: Keep this in sync with $art-at-sizes, etc in:
 *   src/websites/washpost/fronts/_art-sizing.scss
 */

export const THUMBNAIL_DESKTOP_WIDTH = 64;
export const THUMBNAIL_DESKTOP_KEY = "tn-d";
export const THUMBNAIL_MOBILE_WIDTH = 100;
export const THUMBNAIL_MOBILE_KEY = "tn-m";
export const MINI_DESKTOP_WIDTH = 48;
export const MINI_DESKTOP_KEY = "mn-d";
export const MINI_MOBILE_WIDTH = 64;
export const MINI_MOBILE_KEY = "mn-m";

// SEE: https://docs.google.com/spreadsheets/d/1siK6kQyZM6QCw6mWKCE-qj5nZjaen3DQ5plkihGJ2ZA
export const artBreakpointSizes = {
  20: {
    xxl: 16,
    xl: 12,
    lg: 10,
    md: 7,
    sm: 6,
    xs: 5,
    tiny: "tn",
    mini: "mn"
  },
  16: {
    xxl: 12,
    xl: 10,
    lg: 8,
    md: 5,
    sm: 4,
    xs: 3,
    tiny: "tn",
    mini: "mn"
  },
  15: {
    xxl: 11,
    xl: 10,
    lg: 8,
    md: 5,
    sm: 4,
    xs: 3,
    tiny: "tn",
    mini: "mn"
  },
  12: {
    xxl: 8,
    xl: 8,
    lg: 6,
    md: 5,
    sm: 4,
    xs: 3,
    tiny: "tn",
    mini: "mn"
  },
  10: {
    xxl: 6,
    xl: 5,
    lg: 5,
    md: 4,
    sm: 3,
    xs: 2,
    tiny: "tn",
    mini: "mn"
  },
  8: {
    xxl: 4,
    xl: 4,
    lg: 4,
    md: 3,
    sm: 3,
    xs: 2,
    tiny: "tn",
    mini: "mn"
  },
  7: {
    xxl: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 3,
    xs: 2,
    tiny: "tn",
    mini: "mn"
  },
  6: {
    xxl: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 2,
    tiny: "tn",
    mini: "mn"
  },
  5: {
    xxl: 2,
    xl: 2,
    lg: 2,
    md: 2,
    sm: 2,
    xs: 2,
    tiny: "tn",
    mini: "mn"
  },
  4: {
    xxl: "tn",
    xl: "tn",
    lg: "tn",
    md: "tn",
    sm: "tn",
    xs: "tn",
    tiny: "tn",
    mini: "mn"
  },
  // NOTE: mini is a special size and needs a spefication at 1. All other sizes don't.
  1: {
    mini: "mn"
  }
};

export const artSizeTranslator = {
  "xx-large": "xxl",
  "x-large": "xl",
  large: "lg",
  medium: "md",
  small: "sm",
  "x-small": "xs",
  tiny: "tiny",
  mini: "mini",
  "XX-Large": "xxl",
  "X-Large": "xl",
  Large: "lg",
  Medium: "md",
  Small: "sm",
  "X-Small": "xs",
  Tiny: "tiny",
  Mini: "mini"
};
