const getLinkTitle = (a) => {
  return a?.pathname.slice(1, -1).split("/").pop();
};

const getLinkIndex = (a) => {
  return a?.getAttribute("data-index");
};
export const getSecondaryNavTracking = (a, secondaryNav, itidParts) => {
  const navChildren = secondaryNav
    ? Array.from(secondaryNav.children[0].children)
    : null;
  // if the secondary nav link is a dropdown menu
  const isDropdown =
    a.getAttribute("data-qa") === "secondary-nav-dropdown-link";
  if (isDropdown) {
    const dropDownMenu = a.closest(`[data-qa="sc-sec-nav-dropdown"]`);
    const ddLinks = Array.from(
      dropDownMenu.querySelectorAll(`[data-qa="secondary-nav-dropdown-link"]`)
    );
    ddLinks.forEach((link, i) => {
      link.setAttribute("data-index", i + 1);
    });
    const topLink = dropDownMenu.previousElementSibling;
    const ddlinkIndex = getLinkIndex(a);
    const topLinkTitle = getLinkTitle(topLink);
    const topLinkIndex = getLinkIndex(topLink);
    itidParts.push(`sn_${topLinkTitle}_${topLinkIndex}_dd_${ddlinkIndex}`);
  }
  if (!isDropdown) {
    const navLinks = navChildren
      ? navChildren.filter((link) => {
          // links that are not displayed have this class
          return !link.classList.contains("skip-link");
        })
      : null;
    if (navLinks) {
      navLinks.forEach((link, i) => {
        link.children[0].setAttribute("data-index", i + 1);
      });
      // get link title from clicked link
      const linkTitle = getLinkTitle(a);
      const linkIndex = getLinkIndex(a);
      itidParts.push(`sn_${linkTitle}_${linkIndex}`);
    }
  }
};
