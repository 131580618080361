import PropTypes from "fusion-prop-types";
/**
 * NOTE: In order to keep PropTypes.siteServiceSection from
 * erroring the page out, we need to point to fusion-prop-types
 * in next.config.js which points to next-fusion-compat/fusion-modules/prop-types.js
 *
 * That file extends the proptypes coming from arc-fusion + React
 * */
export const HomepagePropTypes = {
  children: PropTypes.any,
  customFields: PropTypes.shape({
    displayName: PropTypes.string.tag({
      hidden: true
    }),
    siteServiceSection: PropTypes.siteServiceSection.tag({
      name: "Section",
      defaultValue: "homepage"
    }),
    enableMultiView: PropTypes.bool.tag({
      name: "Enable Tab Multi-View",
      defaultValue: false,
      description:
        "If true, will display 3 tabs on mobile breakpoint for main content, latest headlines, and for you stories. Should be enabled only for homepage."
    }),
    enableDeduping: PropTypes.bool.tag({
      name: "Enable Deduplication",
      defaultValue: false,
      description:
        "If true, de-duplication settings on chains and features will be honored. If false, they will be ignored. To fully activate, the de-duplication settings for chains (and features, as appropriate) need to be configured. NOTE: To quickly de-activate de-duping, set this value to false."
    })
  })
};
