import get from "lodash.get";

import createAudio from "../content-api/audio";
import createLinksToUse from "../content-api/links-to-use";
import createLiveTicker from "../content-api/live-ticker";
import createPromoImage from "../content-api/promo-image";
import createTracking from "../content-api/tracking";
import { addNestedANS } from "../content-api/element-groups";

/**
 * Helper function that checks for unimplemented languages, content elements
 * and story subtypes, throwing a 404 so we fallback to PB Classic
 *
 * Unsupported languages: right-to-left, specifically "Arabic"
 * Unsupported story types: graphics, enterprise
 * Unsupported content elements: subs_form, share-the-facts, ai2html
 *
 * @param {object} globalContent your typical globalContent ANS article
 *
 * */
function throw404(globalContent) {
  const storyType = get(globalContent, "subtype", "");

  if (storyType === "graphic" || storyType === "enterprise") {
    const error = new Error("Invalid story subtype");
    error.statusCode = 404;
    throw error;
  }

  if (storyType === "subscriber") {
    const error = new Error("Invalid story subtype");
    error.statusCode = 404;
    throw error;
  }
}

function enrichWithFusionAdditions(data) {
  // Transform nested ANS content elements, pulling them to the top-level
  const withNestedANS = addNestedANS(data);

  const withFusionAdditions = {
    ...withNestedANS,
    fusion_additions: {
      ...get(withNestedANS, "fusion_additions", {}),
      audio: createAudio(withNestedANS),
      links_to_use: createLinksToUse(withNestedANS),
      live_ticker: createLiveTicker(withNestedANS),
      promo_image: createPromoImage(withNestedANS),
      tracking: createTracking(withNestedANS)
    }
  };

  return withFusionAdditions;
}

/**
 * Transform the ANS global content object's content_elements to have
 * key updates in the correct position and with the necessary data
 * @param {object} data The ANS global content object
 * @returns Transformed ANS global content object with keyupdates
 */
const transformKeyUpdates = (data) => {
  // Transform content elements to handle key update elements
  const formattedElements = [];
  let isKeyUpdates = false;
  const { subtype, content_elements: contentElements } = data;
  const isLiveArticle = subtype === "live-all";
  contentElements.forEach((element, index) => {
    const formattedElement = { ...element, index };

    // For live articles with an h2 followed by a list, we render a <KeyUpdates> component.
    if (
      !isKeyUpdates &&
      element.type === "header" &&
      element.level === 2 &&
      isLiveArticle
    ) {
      const nextEl =
        contentElements[Math.min(index + 1, contentElements.length)];

      if (nextEl.type === "list") {
        // Next element is key updates for live articles.
        isKeyUpdates = true;
        nextEl.type = "keyupdates";
        nextEl.header = element.content;
        contentElements[index].type = "";
        formattedElement.type = "";
      }
    }

    formattedElements.push(formattedElement);
  });

  const formattedData = {
    ...data,
    content_elements: formattedElements
  };
  return { ...addNestedANS(formattedData) };
};

const getFeedKey = (data = {}) => {
  if (/(collection|results)/.test(data.type) && data.content_elements)
    return "content_elements";
  if (data.items) return "items";
  return undefined;
};

/**
 * Transformation function that is used in content sources to manipulate ANS documents coming from a content source.
 * Typically used to generate resized urls server side, to keep images light.
 *
 * @param {object} data typical ANS article
 * @returns {object} transformed content
 *
 * */
export const transform = (data) => {
  if (data.type === "redirect") {
    return null;
  }
  throw404(data);
  const feedKey = getFeedKey(data);
  if (feedKey) {
    // Feed of stories, transform each item in the feed
    const total = data[feedKey].map((item) => {
      return enrichWithFusionAdditions(item);
    });
    const response = {
      ...data,
      [feedKey]: total
    };
    return response;
  }

  return transformKeyUpdates(data);
};

export const promoContentOnlyTransform = (data) => {
  const feedKey = getFeedKey(data);
  if (feedKey) {
    // Feed of stories, transform each item in the feed
    const total = data[feedKey].map((item) => {
      return enrichWithFusionAdditions(item);
    });
    const response = {
      ...data,
      [feedKey]: total
    };
    return response;
  }

  return enrichWithFusionAdditions(data);
};

export const promoContentOnlyTransformAndFlatten = (data) => {
  const feedKey = getFeedKey(data);
  data = promoContentOnlyTransform(data);
  const item = get(data, `${feedKey}[0]`, undefined);
  if (item) data = item;
  return data;
};
