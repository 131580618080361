import { useContent } from "fusion-content";
import getEnv from "~/components/utilities/env";

const ENVIRONMENT = getEnv();

const sources = {
  local: "feature-flags-development",
  sandbox: "feature-flags-staging",
  prod: "feature-flags-production"
};

/**
 * Assembler's Feature flag hook
 * @description This hook is used to check if a feature flag is enabled or not
 * @name useFeatureFlag
 * @example const isFlagEnabled = useFeatureFlag("useFlagName");
 * @param featureFlagName
 * @returns boolean
 */
export const useFeatureFlag = (flag) => {
  const store = useContent({
    source: sources[ENVIRONMENT] || sources.prod
  });

  const isEnabled = Boolean(store?.[flag]?.enabled);

  return isEnabled;
};

export const useFeatureFlagLoading = (flag) => {
  const store = useContent({
    source: sources[ENVIRONMENT] || sources.prod
  });

  const isEnabled = Boolean(store?.[flag]?.enabled);
  const isLoading = Boolean(store?.isLoading);

  return { isEnabled, isLoading };
};
