import React from "react";
import PropTypes from "prop-types";

const MESSAGES = {
  latest: "breaking news and latest headlines.",
  "foryou-flex-headlines": "recommendations."
};
export const ErrorMessage = () => (
  <p className="gray-dark bold font-xxxs pb-xs pt-sm pl-sm pr-sm center b bt ma-xs">
    Uh oh! there was an error loading these articles
  </p>
);
export const FinishedMessage = ({ type }) => {
  return (
    <p className="gray-dark bold font-xxxs pb-xs pt-sm pl-sm pr-sm center b bt ma-xs">
      That&apos;s all for now. Check back later for more {MESSAGES[type]}
    </p>
  );
};
FinishedMessage.propTypes = {
  type: PropTypes.string
};
