import { sm, md, lg } from "./breakpoints";

export const MOBILE = "MOBILE";
export const TABLET = "TABLET";
export const DESKTOP = "DESKTOP";
export const LARGE_DESKTOP = "LARGE_DESKTOP";

/**
 * Function that returns a boolean based on whether user screen size is within standard mobile device width range
 *
 * @return {boolean}
 */
export function isMobile() {
  return typeof window !== "undefined" && window.innerWidth <= sm;
}

/**
 * Function that returns a boolean based on whether user screen size is within standard tablet width range
 *
 * @return {boolean}
 */
export function isTablet() {
  return (
    typeof window !== "undefined" &&
    window.innerWidth > sm &&
    window.innerWidth <= md
  );
}

/**
 * Function that returns a boolean based on whether user screen size is in largest breakpoint range
 *
 * @return {boolean|boolean}
 */
export function isLargeDesktop() {
  return typeof window !== "undefined" && window.innerWidth >= lg;
}

/**
 * Function that returns a string of the device corresponding to the current window breakpoint
 * @return {undefined|string}
 */
export function getDeviceSize() {
  if (typeof window === "undefined") {
    return undefined;
  }
  return (
    (isMobile() && MOBILE) ||
    (isTablet() && TABLET) ||
    (isLargeDesktop() && LARGE_DESKTOP) ||
    DESKTOP
  );
}
