import get from "lodash.get";

/**
 * Receives an ANS content object and creates a formatted links_to_use object for
 * use by features and components. In ANS, the various bits to harvest are structured thus:

  {
    canonical_url: CANONICAL (relative),

    related_content: {
      redirect: [
        {
          redirect_url: REDIRECT (absolute)
        }
      ]
    },

    short_url: SHORT (absolute),

    taxonomy: {
      primary_site: {
        _id: "/classic-apps"
      },
      tags: [ (only tag[0])
        {
          text: ENHANCED_EXPERIENCE (relative)
        }
      ]
    }

  }

 */

const fixUrl = (url) => {
  return url && url.startsWith("/")
    ? `https://www.washingtonpost.com${url}`
    : url;
};

export default function createLinksToUse(ans) {
  const canonical = fixUrl(get(ans, "canonical_url"));

  const redirect = fixUrl(get(ans, "related_content.redirect[0].redirect_url"));

  const provisionalEnhancedExperience = get(
    ans,
    "taxonomy.tags[0].text",
    "NOPE"
  );
  const enhancedExperience = fixUrl(
    get(ans, "taxonomy.primary_site._id") === "/classic-apps" &&
      provisionalEnhancedExperience.startsWith("/")
      ? provisionalEnhancedExperience
      : undefined
  );

  const short = get(ans, "short_url");

  let basic = enhancedExperience || redirect || canonical;
  if (
    basic &&
    basic.match(
      /(https:\/\/www.washingtonpost.com\/)(?!video\/)(.*[^/]+_(live|video).html)/
    )
  ) {
    basic = `${RegExp.$1}video/${RegExp.$2}`;
  }

  const offline =
    (!redirect && enhancedExperience) ||
    (redirect && redirect.match("/news/powerpost/paloma/"))
      ? canonical
      : undefined;

  return {
    basic,
    offline,
    dangerous: {
      canonical,
      redirect,
      enhanced_experience: enhancedExperience,
      short
    }
  };
}
