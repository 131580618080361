// Filter for a query of stories
// Reuses the story card filter.

import storyCardFilter from "./story-card";

// NOTES:
// * count and next are for prism queries
// * description and headlines are for collections

export default `
{
  _id
  count
  description {
    basic
  }
  headlines {
    basic
  }
  items ${storyCardFilter}
  next
  type
}
`;
