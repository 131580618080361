import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Tabs } from "@washingtonpost/wpds-ui-kit";
import { useMultiView } from "~/shared-components/MultiViewContext";
import { AlwaysPresentContent } from "./shared";
import { FeedContent } from "./FeedContent";

const pauseMediaInDiv = (div) => {
  div.querySelectorAll("video, audio").forEach((media) => {
    const isGif =
      media.getAttribute("loop") && media.getAttribute("playsinline");
    const isPlaying =
      media.currentTime > 0 &&
      !media.paused &&
      !media.ended &&
      media.readyState > 2;

    if (!isGif && isPlaying) {
      media.pause();
    }
  });
};

const TAB_TYPE_COMPONENT_MAP = {
  feed: FeedContent
};

export const TabContent = ({ tabId, type, config, ...props }) => {
  const { activeTab } = useMultiView();
  const isActive = tabId === activeTab;
  const { isAlwaysMounted } = config;
  const ref = useRef();

  useEffect(() => {
    if (!isActive && isAlwaysMounted && ref.current) {
      pauseMediaInDiv(ref.current);
    }
  }, [isActive, isAlwaysMounted]);

  const TabContentWrapper = TAB_TYPE_COMPONENT_MAP[type] || React.Fragment;
  const tabProps =
    typeof TAB_TYPE_COMPONENT_MAP[type] !== "undefined"
      ? { tabId, config, ...props }
      : {};
  const content = (
    <TabContentWrapper {...tabProps}>{props.content}</TabContentWrapper>
  );

  const alwaysPresentClassName = `${isActive ? "" : "in"}active`;

  const forTracking = { "data-mv-tab": tabId };

  return (
    <React.Fragment>
      {isAlwaysMounted && (
        <AlwaysPresentContent
          ref={ref}
          display={isActive}
          className={alwaysPresentClassName}
          {...forTracking}
        >
          {content}
        </AlwaysPresentContent>
      )}
      <Tabs.Content value={tabId} {...forTracking}>
        {!isAlwaysMounted && content}
      </Tabs.Content>
    </React.Fragment>
  );
};

TabContent.propTypes = {
  tabId: PropTypes.string,
  type: PropTypes.string,
  config: PropTypes.shape({ isAlwaysMounted: PropTypes.bool }),
  content: PropTypes.element
};
