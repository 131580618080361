import clonedeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import { getClasses } from "@washingtonpost/front-end-utils";
import { fetchProps } from "~/shared-components/story-card/_utilities/data";
import { tokenizeLabelType } from "~/shared-components/story-card/_children/Label.helpers";

import {
  createStackedStripsColumns,
  adminValidationMessages,
  getTilingStyles,
  gridGapStyles
} from "./settings";
import { isSafeListed } from "./safelist";
import {
  IMPLICIT_GRID_INFO,
  GRID_PLACEHOLDER_INFO,
  createImplicitGridStyles,
  createRowStyles
} from "~/components/layouts/utilities/grid-helpers";
import { shouldUseDesktopOrdering } from "~/shared-components/chains/top-table/utilities/settings";
import { applyMobilePresets } from "~/components/features/fronts/flex-feature/utilities/mobile-presets";
import {
  isFeatureHiddenFromPlatform,
  isFeatureHiddenOnDesktop,
  isFeatureHiddenOnMobile
} from "~/shared-components/story-card/_utilities/helpers";
import customFieldDefaultsForChains from "~/shared-components/chains/top-table/utilities/custom-field-defaults";

export const getGridInfo = ({
  GRID_INFO = IMPLICIT_GRID_INFO,
  bp,
  includeDividers = true
}) =>
  GRID_INFO.filter((info) => !bp || info.bp === bp).map((info) => ({
    ...info,
    // NOTE: For now, includeDividers encompasses table and feature dividers
    // The code further down adds different classNames for tables and features
    // anticipating the need for more fine-grained control
    includeDividers
  }));

/**
 * INTERNAL
 * This builds a set (each key will be a table id) of lists of ints (representing the feature ids)
 * required by the layout to render and gets the order correctly.
 *
 * If table1LeftIds = [1,3,4] and table2Ids = [2,5,6], then this function returns:
 *
 * {
 *  table1: [1,3,4],
 *  table2: [2,5,6]
 * }
 *
 * @param {object} layoutObj - the layout obj
 * @param {object} customFields - props from top table chain, we are looking at these possible
 *                                      values table1LeftIds, table1MainIds, table1RightIds, table2Ids, table3Ids
 * @param {object} itemsPerFeatureMap - Object.keys(itemsPerFeatureMap).length will be used as maxValue. Any ids
 *                                greater than maxValue are ejected. The idea is that maxValue will be number of
 *                                items in the chain overall. If an editor enters, say 55, into table1LeftIds and
 *                                the feature has only 10 children, then the 55 will be ignored.
 *
 *                                chainProps is also be used to identify feeds which, if found, will lead
 *                                to an expanded list of featureIds.
 *
 * @returns {object} - well-formmatted lists of featureIds
 */
export function getAllFeatureIdsByTable(
  layoutObj,
  customFields,
  itemsPerFeatureMap
) {
  const maxValue = Object.keys(itemsPerFeatureMap).length;

  // NOTE: allFeatureIds is to prevent duplicates
  // TODO: Think if the deduping/filtering below could mess up itemsPerRow
  const allFeatureIds = [];
  return (
    Object.keys(layoutObj)
      .filter((v) => v.match(/^table|allcontent/))
      // NOTE: reversing tables list b/c itemsPerRow seems to work this way
      // TODO: Verify this. It may be deduping in natural feature order
      .reverse()
      .reduce((acc, tableKey) => {
        let featureIds = [];
        if (layoutObj[tableKey].columns) {
          featureIds = Object.keys(layoutObj[tableKey].columns).reduce(
            (prev, col) => {
              const tableColKey = `${
                tableKey + col.charAt(0).toUpperCase() + col.substring(1)
              }Ids`;
              if (!customFields[tableColKey]) return prev;
              const ids = customFields[tableColKey]
                .split(",")
                // Force string to int
                .map((v) => (!Number.isNaN(v) ? Number.parseInt(v, 10) : -1))
                // NOTE: Remove out-of-bounds indexes
                .filter((v) => v <= maxValue)
                // Remove duplicates w/in same table
                .filter((v, i, self) => self.indexOf(v) === i)
                // Remove duplicates across all tables
                .filter((v) => {
                  if (allFeatureIds.indexOf(v) === -1) {
                    allFeatureIds.push(v);
                    return true;
                  }
                  return false;
                });
              return prev.concat(ids);
            },
            []
          );
        } else {
          if (tableKey === "allcontent") {
            // assembler2 allows re-ordering top strip and full span in table1LeftIds
            if (customFields.table1LeftIds) {
              featureIds = customFields.table1LeftIds
                .split(",")
                // Force string to int
                .map((v) => (!Number.isNaN(v) ? Number.parseInt(v, 10) : -1))
                // NOTE: Remove out-of-bounds indexes
                .filter((v) => v <= maxValue);
            } else {
              // fallback to the legacy way of ordering things
              featureIds = [...Array(maxValue)].fill(0).map((v, i) => i + 1);
            }
          } else {
            // SETH: This is gross and super tightly coupled to the naming of custom fields
            // but I can't think of a better way to do it yet.
            const customField = tableKey.match(/^table(Ad)?\d+$/)
              ? `${tableKey}LeftIds`
              : `${tableKey}Ids`;
            if (customFields[customField])
              featureIds = customFields[customField]
                .split(",")
                // Force string to int
                .map((v) => (!Number.isNaN(v) ? Number.parseInt(v, 10) : -1))
                // NOTE: Remove out-of-bounds indexes
                .filter((v) => v <= maxValue);
          }

          featureIds = featureIds
            // Force string to int
            .map((v) => (!Number.isNaN(v) ? Number.parseInt(v, 10) : -1))
            // NOTE: Remove out-of-bounds indexes
            .filter((v) => v <= maxValue)
            // Remove duplicates w/in same tableKey
            .filter((v, i, self) => self.indexOf(v) === i)
            // Remove duplicates across all tables
            .filter((v) => {
              if (allFeatureIds.indexOf(v) === -1) {
                allFeatureIds.push(v);
                return true;
              }
              return false;
            });
        }

        featureIds = featureIds
          // NOTE: Remove items where itemsPerFeature is 0
          .filter((v) => {
            const itemsPerFeature = !Number.isNaN(itemsPerFeatureMap[v])
              ? itemsPerFeatureMap[v]
              : 0;
            return itemsPerFeature > 0;
          });

        acc[tableKey] = featureIds;

        return acc;
      }, {})
  );
}

/* TODO
 * Document this
 */
export function getAllFeatureIdsByTablePerBp(
  GRID_INFO,
  layoutObjPerBp,
  customFields,
  itemsPerFeatureMapPerBp
) {
  return GRID_INFO.reduce((acc, info) => {
    acc[info.bp] = getAllFeatureIdsByTable(
      layoutObjPerBp[info.bp],
      customFields,
      itemsPerFeatureMapPerBp[info.bp]
    );
    return acc;
  }, {});
}

/**
 * @param {object} childProps - Used to identify which items are feeds. NOTE: Not all features
 *                                in the chain will be flex features. Keep that in mind when
 *                                enhancing this.
 * @param {boolean} useDesktopOrdering - Whether desktop ordering is in effect
 * @param {string} outputType - one of ["default", "jsonapp"]. passed to isSafelisted
 *
 * @returns {object} - {
 *                       <<featureId>>: int // number of items
 *                       ...
 *                     }
 */
export function getItemsPerFeatureMap(
  childProps,
  useDesktopOrdering,
  outputType
) {
  return childProps.reduce((acc, props, i) => {
    const customFields = applyMobilePresets({
      customFields: props?.customFields || {},
      chainCtx: { useDesktopOrdering }
    });
    if (!isSafeListed({ props, outputType })) {
      acc[i + 1] = 0;
    } else {
      const { contentService, feedLimit, featureLimit } = fetchProps({
        data: customFields,
        keys: {
          contentService: ["flexFeatureContentConfig.contentService"],
          feedLimit: ["flexFeatureContentConfig.contentConfigValues.limit", 5],
          featureLimit: ["limit", 5]
        },
        overrides: {}
      });

      // START: feeds
      // NOTE: This check could be more robust
      if (contentService && !/^(prism|prism-promo)$/.test(contentService)) {
        acc[i + 1] = Math.min(
          // NOTE: This is to assure these values are numbers with proper defaults
          !Number.isNaN(Number.parseInt(feedLimit, 10))
            ? Number.parseInt(feedLimit, 10)
            : 5,
          !Number.isNaN(Number.parseInt(featureLimit, 10))
            ? Number.parseInt(featureLimit, 10)
            : 5
        );
        // END: feeds
      } else {
        acc[i + 1] = featureLimit !== 0 ? 1 : 0;
      }
    }

    return acc;
  }, {});
}

/**
 * @param {object} GRID_INFO - Info about each breakpoint.
 * @param {object} childProps - See getItemsPerFeatureMap
 * @param {boolean} useDesktopOrdering - See getItemsPerFeatureMap
 * @param {string} outputType - See getItemsPerFeatureMap
 *
 * @returns {object} - For each breakpint, an object. See getItemsPerFeatureMap
 */
export function initItemsPerFeatureMapPerBp(
  GRID_INFO,
  childProps,
  desktopOrderingObj,
  outputType
) {
  return GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    const useDesktopOrdering = desktopOrderingObj[bp];
    acc[bp] = getItemsPerFeatureMap(childProps, useDesktopOrdering, outputType);
    return acc;
  }, {});
}

/*
 * @param {object} itemsPerFeatureMap - object returned by getItemsPerFeatureMap
 *
 * @returns {object} - an object with the same keys as the object passed in, but
 *  whose values are all 0. This is used to track adjustments to feeds when they
 *  have fewer items to show than configured to show. So, the assumption is no
 *  adjustments are needed, then other code updates the map. When de-duping is handled
 *  properly, this same function can set the initial adjustment values.
 */
export function initItemsPerFeatureMapAdjustments(itemsPerFeatureMap) {
  return Object.keys(itemsPerFeatureMap).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
  }, {});
}

/*
 * @param {int} id - id of the feature
 * @param {object} itemsPerFeatureMaps - an object with a number of keys whose values are
 *  all objects that have the shape of an object returned by getItemsPerFeatureMap,
 *  In practice, there should minimally be an object with the initial items per feature, and
 *  optionally an object for each kind of adjustment, like feed adjustments or
 *  duplicate adjustments, etc.
 *
 * @returns {int} - running total of itemsPerFeatureMaps[key][id]
 */
export function computeItemsPerFeature(id, itemsPerFeatureMaps) {
  return Object.keys(itemsPerFeatureMaps).reduce((acc, key) => {
    acc += get(itemsPerFeatureMaps, `${key}.${id}`, 0);
    return acc;
  }, 0);
}

/*
 * @param {array} childProps - list of props for each child
 * @param {object} itemsPerFeatureMap - object returned by getItemsPerFeatureMap
 * @param {object} itemsPerFeatureMaps - an object with a number of keys whose values are
 *  all objects that have the shape of an object returned by getItemsPerFeatureMap
 * @param {object} desktopOrderingObj - object returned by getDesktopOrderingObj
 * @param {boolean} isAdmin - Hiding in admin can lead to buggy behavior
 *   b/c the admin itself seems to lose track of the feature in some
 *   not fully reproducible situations. So, instead, provide an
 *   altenrate "pink bar" experience in the admin.
 *
 * @returns {array} - reordered and combined list of ids
 */
export function getItemsPerFeatureMapPerBp(
  GRID_INFO,
  childProps,
  itemsPerFeatureMap,
  itemsPerFeatureMaps,
  desktopOrderingObj,
  outputType
) {
  return Object.keys(desktopOrderingObj).reduce((acc, bp) => {
    if (GRID_INFO.map((info) => info.bp).includes(bp)) {
      const itemsPerFeatureMapDesktop = Object.keys(itemsPerFeatureMap).reduce(
        (accd, key, i) => {
          const customFields = get(childProps[i], "customFields");
          accd[key] =
            desktopOrderingObj[bp] &&
            isFeatureHiddenOnDesktop(customFields, outputType)
              ? 0
              : computeItemsPerFeature(key, itemsPerFeatureMaps);
          return accd;
        },
        {}
      );

      const itemsPerFeatureMapMobile = Object.keys(itemsPerFeatureMap).reduce(
        (accm, key, i) => {
          const customFields = get(childProps[i], "customFields");
          accm[key] =
            !desktopOrderingObj[bp] &&
            isFeatureHiddenOnMobile(customFields, outputType)
              ? 0
              : computeItemsPerFeature(key, itemsPerFeatureMaps);
          return accm;
        },
        {}
      );

      acc[bp] = desktopOrderingObj[bp]
        ? itemsPerFeatureMapDesktop
        : itemsPerFeatureMapMobile;
    }
    return acc;
  }, {});
}

/*
 * @desc adjusts the layout taking column splits into account which currently only has an effect
 *   on tables 1 - 8 of the "Stacked strips with split tables and wide far-right table" layout
 * @param {string} layoutName - A layout name like "Stacked strips with split tables"
 * @param {object} layoutObj - A layout obj
 * @param {object} customFields - custom fields object from which the colum split setting is extracted
 * @returns {object} - The layoutObj updated based on the column split setting
 */
const adjustLayoutObj = (layoutName, layoutObj, customFields) => {
  if (
    /Stacked strips with split tables and wide far-right table/i.test(
      layoutName
    )
  ) {
    return Object.keys(clonedeep(layoutObj)).reduce((acc, key) => {
      if (
        [
          "table1",
          "table2",
          "table3",
          "table4",
          "table5",
          "table6",
          "table7",
          "table8"
        ].includes(key)
      ) {
        const table = layoutObj[key];
        const columnSplit = get(
          customFields,
          `${key}ColumnSplit`,
          "wide-right"
        );
        set(
          table,
          "columns",
          createStackedStripsColumns(layoutName, columnSplit)
        );
        acc[key] = table;
        return acc;
      }
      acc[key] = layoutObj[key];
      return acc;
    }, {});
  }
  return layoutObj;
};

export function getLayoutObj(
  layoutStyles,
  layoutName,
  HPLayoutSynonyms,
  customFields,
  itemsPerFeatureMap
) {
  const maxValue = Object.keys(itemsPerFeatureMap).length;
  const topStripLayoutName = `${layoutName} with top strip`;
  if (
    layoutStyles[topStripLayoutName] ||
    layoutStyles[HPLayoutSynonyms[topStripLayoutName]]
  ) {
    let topStripIds = `${customFields.table0LeftIds}`;
    if (topStripIds) {
      topStripIds = topStripIds
        .split(",")
        // Force string to int
        .map((v) => (!Number.isNaN(v) ? Number.parseInt(v, 10) : -1))
        // NOTE: Remove out-of-bounds indexes
        .filter((v) => v <= maxValue)
        // Remove duplicates w/in same table
        .filter((v, i, self) => self.indexOf(v) === i)
        // NOTE: Remove items where itemsPerFeature is 0
        .filter((v) => {
          const itemsPerFeature = !Number.isNaN(itemsPerFeatureMap[v])
            ? itemsPerFeatureMap[v]
            : 0;
          return itemsPerFeature > 0;
        });
      if (topStripIds.length) {
        return {
          layoutName: topStripLayoutName,
          layoutObj: adjustLayoutObj(
            topStripLayoutName,
            layoutStyles[topStripLayoutName] ||
              layoutStyles[HPLayoutSynonyms[topStripLayoutName]],
            customFields
          )
        };
      }
    }
  }
  return {
    layoutName,
    layoutObj: adjustLayoutObj(
      layoutName,
      layoutStyles[layoutName] || layoutStyles[HPLayoutSynonyms[layoutName]],
      customFields
    )
  };
}

/*
 * Create a layoutObj per breakpoint taking into account the fact
 * that entire tables or columns could be empty. This code rips
 * out such empty tables/columns from the layoutObj.
 *
 * In the case of a top strip item that is hidden on mobile, the layout
 * must be switched out for the corresponding one w/o a top strip.
 *
 * @param {object} GRID_INFO - Info about each breakpoint.
 * @param {object} layoutStyles - All the supported layout objects. Needed
 *   to get the corresponding layout obj of "with top strip".
 * @param {string} layoutName - The initial layoutName used as a key
 *   to fetch new layout objects from layoutStyles
 * @param {object} HPLayoutSynonyms - To aid in finding a layout obj
 *   to fetch new layout objects from layoutStyles
 * @param {object} layoutObj - The initial layoutObj to use as a basis
 *   making revisions.
 * @param {object} itemsPerColumnPerBp - Has the data regarding empty
 *   tables/columns
 * @param {boolean} isAdmin - Is it the admin or the published page
 *
 * @returns {object} - of the form:
 *                      {
 *                        [bp]: layoutObj,
 *                        ...
 *                      }
 */
export function getLayoutObjPerBp(
  GRID_INFO,
  customFields,
  layoutStyles,
  layoutName,
  HPLayoutSynonyms,
  layoutObj,
  itemsPerColumnPerBp,
  showHelpers = false,
  isAdmin = false
) {
  return GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    const fullColSpan = info.fullColSpan;
    let correctedLayoutObj = {
      ...layoutObj
    };

    const itemsPerColumn = itemsPerColumnPerBp[bp];

    // START: Reconcile table0 (Top Strip) with itemsPerColumn
    // NOTE: Motivation for this is if layout is "with top strip"
    // but the item in top strip is hidden on mobile than the
    // layoutObj used at those breakpoints should be the one w/o
    // the top strip.
    const itemsInTable0 = itemsPerColumn["table0.childStyles"];
    if (itemsInTable0 !== undefined) {
      let correctedLayoutName = layoutName;
      if (itemsInTable0 === 0 && layoutName.match(/ with top strip$/)) {
        // NOTE: This will select the layout w/o table0
        // cuz there are no items in table0
        correctedLayoutName = layoutName.replace(/ with top strip$/, "");
      } else if (itemsInTable0 > 0 && !layoutName.match(/ with top strip$/)) {
        // NOTE: This will select the layout w table0
        // cuz there are items in table0
        correctedLayoutName = `${layoutName} with top strip`;
      }
      correctedLayoutObj = {
        ...(layoutStyles[correctedLayoutName] ||
          layoutStyles[HPLayoutSynonyms[correctedLayoutName]])
      };
    }
    // END: Reconcile table0 (Top Strip) with itemsPerColumn

    // START: If no items in table and table full width, remove it from layout
    // NOTE: Don't do this at the mx breakpoint in the admin cuz then dropzones would disappear
    if (!isAdmin || !/mx/.test(bp)) {
      correctedLayoutObj = Object.keys(correctedLayoutObj).reduce(
        (accObj, tableKey) => {
          const tableLayout = correctedLayoutObj[tableKey];
          let total = 0;
          const isNotFullWidth =
            tableLayout.styles[`--c-span-${bp}`] !== undefined &&
            tableLayout.styles[`--c-span-${bp}`] < fullColSpan;
          if (tableLayout.columns) {
            total = Object.keys(tableLayout.columns).reduce((tot, column) => {
              const key = `${tableKey}.columns.${column}`;
              const count = itemsPerColumn[key] || 0;
              return tot + count;
            }, total);
          } else {
            const key = tableLayout.allcontent
              ? `${tableKey}.allcontent`
              : `${tableKey}.childStyles`;
            total = itemsPerColumn[key] || 0;
          }
          if (total !== 0 || isNotFullWidth) {
            accObj[tableKey] = tableLayout;
          }
          return accObj;
        },
        {}
      );
    }
    // END: If no items in table and table full width, remove it from layout

    // START: Remove ad table in admin unless
    //  1. there is content or
    //  2. showHelpers is on
    // NOTE: Only Stacked strips layouts have the ad table
    const tableAd1HasContent = !!(itemsPerColumn["tableAd1.columns.left"] || 0);
    // NOTE: Cuz in-table ad is not the last table in the stack, show a reminder in the admin
    // if it has content. This check is primitive and can't detect, for example, if there is
    // a feature, but it's hidden at all breakpoints
    const showInTableAdReminders = /\d/.test(customFields.tableAd1LeftIds);
    if (
      isAdmin &&
      /Stacked strips/.test(layoutName) &&
      !showHelpers &&
      !tableAd1HasContent &&
      !showInTableAdReminders
    ) {
      correctedLayoutObj = clonedeep(correctedLayoutObj);
      delete correctedLayoutObj.tableAd1;
      const rows = correctedLayoutObj?.table9?.styles[`--r-span-${bp}`];

      if (rows) {
        set(correctedLayoutObj, `table9.styles["--r-span-${bp}"]`, rows - 1);
      }
    }
    // END: Remove ad table in admin unless showHelpers is on

    // START: Remove tables from layout that are empty and are spanned by tablesThatSpan
    // TODO: Make this code smart enough for all layouts
    if (!isAdmin && /Stacked strips/.test(layoutName)) {
      // NOTE: Cuz the correctedLayoutObject is altered fromn instance to instance
      // depending on content, clone it
      correctedLayoutObj = clonedeep(correctedLayoutObj);
      // NOTE: Harvest the key of the table(s) that span the other table(s). Note that this code
      // assumes if there is more than 1 such table that they all span the remaining other non-full-width
      // tables, which is currently (5/4/2022) true for the Stacked strip layouts
      const { tables: tablesThatSpan, rSpan: rowSpan } = Object.keys(
        correctedLayoutObj
      ).reduce(
        ({ tables, rSpan }, candidate) => {
          if (
            (correctedLayoutObj[candidate]?.styles || {}).hasOwnProperty(
              `--r-span-${bp}`
            )
          ) {
            const span =
              correctedLayoutObj[candidate].styles[`--r-span-${bp}`] || 1;
            if (span > 1) {
              rSpan = span;
              tables.push(candidate);
            }
          }
          return { tables, rSpan };
        },
        { tables: [], rSpan: 1 }
      );

      if (rowSpan > 1) {
        const tablesThatGetSpanned = Object.keys(correctedLayoutObj)
          .filter((_) => !tablesThatSpan.includes(_))
          .filter(
            (_) => correctedLayoutObj[_].styles[`--c-span-${bp}`] < fullColSpan
          );

        // START: Figure out rows to span; delete empty tablesThatGetSpanned
        let rows = 0;
        if (rowSpan > 1) {
          tablesThatGetSpanned.forEach((table) => {
            const keys = Object.keys(correctedLayoutObj[table]).reduce(
              (accKeys, key) => {
                if (/^(allcontent|childStyles)$/.test(key))
                  accKeys.push(`${table}.${key}`);
                if (key === "columns") {
                  Object.keys(correctedLayoutObj[table][key]).forEach((col) => {
                    accKeys.push(`${table}.${key}.${col}`);
                  });
                }
                return accKeys;
              },
              []
            );
            const total = keys.reduce(
              (tot, key) => tot + (itemsPerColumn[key] || 0),
              0
            );
            if (total === 0)
              // NOTE: delete tables with no items
              delete correctedLayoutObj[table];
            // NOTE: tally up the actual number of rows present
            else rows += 1;
          });
        }
        // END: Figure out rows to span; delete empty tablesThatGetSpanned

        // START: When updating the layout obj, need to do it for each table that spans
        if (rows > 1)
          tablesThatSpan.forEach((table) => {
            correctedLayoutObj[table].styles[`--r-span-${bp}`] = rows;
          });
        else
          tablesThatSpan.forEach((table) => {
            // NOTE: If 1 or 0 rows, no need for tablesThatSpan row to have its row span specified
            delete correctedLayoutObj[table].styles[`--r-span-${bp}`];
          });
        // END: When updating the layout obj, need to do it for each table that spans
      }
    }

    acc[bp] = correctedLayoutObj;
    return acc;
  }, {});
}

/**
 * INTERNAL
 * This builds the list of ints ids (representing the feature ids) required by the layout
 * to render and gets the order correctly.
 *
 * If table1LeftIds = [1,3,4] and table2Ids = [2,5,6], then this function returns:
 *
 * [1,3,4,2,5,6]
 *
 * @param {object} allFeatureIdsByTable - object returned by getAllFeatureIdsByTable()
 *
 * @returns {array} - reordered and combined list of ids
 */
export function getAllFeatureIds(allFeatureIdsByTable) {
  return Object.keys(allFeatureIdsByTable).reduce(
    (acc, tableKey) => acc.concat(allFeatureIdsByTable[tableKey]),
    []
  );
}

/* TODO
 * Document this
 */
export function getAllFeatureIdsPerBp(GRID_INFO, allFeatureIdsByTablePerBp) {
  return GRID_INFO.reduce((acc, info) => {
    acc[info.bp] = getAllFeatureIds(allFeatureIdsByTablePerBp[info.bp]);
    return acc;
  }, {});
}

/*
 * Gets the position info of a value in a grid. Assumes values in the grid come in blocks.
 *
 * @param {any} value - the value to get info about
 * @param {array of arrays} grid - grid filled in with value
 *
 * @returns {string} - object with position info that can be used for many things.
 *
 * {
 *  firstIndex: {row: int, col: int}
 *  lastIndex: {row: int, col: int}
 *  spans: {row: int, col: int}
 * }
 */
export const getPositionInfo = (value, grid) => {
  return grid.reduce(
    (acc, row, rowIndex) => {
      const firstIndex = row.indexOf(value);
      if (firstIndex > -1) {
        acc.firstIndex.row =
          acc.firstIndex.row === -1 ? rowIndex : acc.firstIndex.row;
        acc.firstIndex.col =
          acc.firstIndex.col === -1 ? firstIndex : acc.firstIndex.col;
        const lastIndex = row.length - row.slice().reverse().indexOf(value) - 1;
        acc.lastIndex.row = rowIndex;
        acc.lastIndex.col = lastIndex;
      }
      if (rowIndex === grid.length - 1) {
        const rowDiff = acc.lastIndex.row - acc.firstIndex.row;
        acc.spans.row = rowDiff >= 0 ? rowDiff + 1 : acc.lastIndex.row;
        const colDiff = acc.lastIndex.col - acc.firstIndex.col;
        acc.spans.col = colDiff >= 0 ? colDiff + 1 : acc.lastIndex.col;
      }
      return acc;
    },
    {
      firstIndex: { row: -1, col: -1 },
      lastIndex: { row: -1, col: -1 },
      spans: { row: 0, col: 0 }
    }
  );
};

/*
 * Gets the position info of a value in a grid. Assumes values in the grid come in blocks.
 *
 * @param {any} value - the value to get info about
 * @param {array of arrays} grid - grid filled in with value
 *
 * @returns {object} - object with position info that can be used for many things, esp jsonapp.
 *
 * {
 *   { row: int, rowspan: int, col: int, colspan: int }
 * },
 */
export const getPositionInfoAltForm = (value, grid) => {
  const info = getPositionInfo(value, grid);
  if (info.firstIndex.row === -1 || info.firstIndex.col === -1) {
    return undefined;
  }
  return {
    row: info.firstIndex.row,
    rowspan: info.spans.row,
    col: info.firstIndex.col,
    colspan: info.spans.col
  };
};

/*
 * INTERNAL
 *
 * @param {any} value -- The target value in the grid to get tiling info about
 * @param {obj} gridObj -- The grid itself, plus more data about the grid
 * @param {string} bp -- The breakpoint of interest
 *
 * @returns {boolean} - Whether the item is tiling or not.
 *
 * NOTE: At xs breakpoint, there really is no difference between
 * tiling and not tiling b/c it's always full width. B/c it doesn't
 * make a difference, some layouts configure xs as tiling, and some not.
 * B/c of that, this function will always return false at the xs breakpoint
 *
 * TODO: Go through all layouts and make sure xs bp is not configured as tiling
 */
const isItemTiling = (value, gridObj, bp) => {
  return (
    !/^xs$/i.test(bp) &&
    gridObj.styles[gridObj.items[value].styleKey][`--c-start-${bp}`] === 0
  );
};

/*
 * INTERNAL
 *
 * @param {any} value -- The target value in the grid to get span info about
 * @param {any} targetValue -- The target value in the grid to get span info about
 * @param {obj} gridObj -- The grid itself, plus more data about the grid
 * @param {string} bp -- The breakpoint of interest
 *
 * @returns {boolean} - If the value is exclipsed by (i.e. underneath) the targetValue
 */
const isItemUnderneathTarget = (value, targetValue, gridObj, bp) => {
  const itemStyleKey = /chain|table/.test(value)
    ? value
    : get(gridObj, `items[${value}].styleKey`);
  const itemSpan = get(
    gridObj,
    `styles["${itemStyleKey}"]["--c-span-${bp}"]`,
    "itemSpan"
  );
  const itemStart = get(
    gridObj,
    `styles["${itemStyleKey}"]["--c-start-${bp}"]`,
    "itemStart"
  );
  const itemEnd = itemStart + itemSpan;
  const targetStyleKey = /chain|table/.test(targetValue)
    ? targetValue
    : get(gridObj, `items[${targetValue}].styleKey`);
  const targetSpan = get(
    gridObj,
    `styles["${targetStyleKey}"]["--c-span-${bp}"]`,
    "targetSpan"
  );
  const targetStart = get(
    gridObj,
    `styles["${targetStyleKey}"]["--c-start-${bp}"]`,
    "targetStart"
  );
  const targetEnd = targetStart + targetSpan;

  // NOTE: Not completely to the left or right.
  return !(itemEnd <= targetStart || itemStart >= targetEnd);
};

/**
 * INTERNAL
 *
 * @param {object} grid - Grid of interest
 * @param {rowIdx} int - row number
 * @param {colIdx} int - col number
 *
 * @returns {string-like} - the key at the given position of the grid, like "table1"
 */
export const getItemAtPosition = (grid, rowIdx, colIdx) =>
  get(grid, `${rowIdx}.${colIdx}`, undefined);

/**
 * INTERNAL
 *
 * @param {object} grid - Grid of interest
 * @param {rowIdx} int - row number
 *
 * @returns {array of string-like} - the keys present in rowId of the grid, like "table1"
 */
export const getItemsInRow = (grid, rowIdx) =>
  ((rowIdx !== undefined && grid[rowIdx]) || []).filter(
    (v, i, a) => v !== GRID_PLACEHOLDER_INFO.value && a.indexOf(v) === i
  );

/**
 * INTERNAL
 *
 * @param {any} targetValue -- The target value in the grid to get span info about
 * @param {object} grid - Grid of interest
 * @param {obj} gridObj -- The grid itself, plus more data about the grid
 * @param {rowIdx} int - row number
 * @param {string} bp -- The breakpoint of interest
 *
 * @returns {array of string-like} - the keys underneath the targetValue
 */
export const getItemsUnderneathTarget = (
  targetValue,
  grid,
  gridObj,
  rowIdx,
  bp
) =>
  getItemsInRow(grid, rowIdx).filter((value) =>
    isItemUnderneathTarget(value, targetValue, gridObj, bp)
  );

/*
 * INTERNAL
 *
 * @param {any} value -- The target value in the grid to get position info about
 * @param {array of arrays} grid -- The grid of all values
 * @param {obj} gridObj -- The grid itself, plus more data about the grid
 * @param {string} bp -- The breakpoint of interest
 *
 * @returns {object} - details of where the dividers go in the gridObj
 * {
 *   isTop: boolean -- is in the first row of the grid
 *   isRight: boolean -- is in the right-most column of the grid
 *   isBottom: boolean -- is in the last row of the grid
 *   isLeft: boolean -- is in the left-most column of the grid
 *   isPseudoTop: boolean -- is in a row whose previous row has a different pattern of features
 *   isPseudoLeft: boolean -- is in a row whose item to the left has a rowspan > 1 (checking previous row only)
 *   isPseudoRight: boolean -- is in a row whose item to the right has a rowspan > 1 (checking previous row only)
 *   isTiling: boolean -- has no fixed start column, but rather tiles in to fill space
 * }
 */
const getPositionInfoForValueAtBp = (value, grid, gridObj, bp) => {
  let valueHandled = false;
  return grid.reduce((acc, row, rowIndex) => {
    const index = row.indexOf(value);
    if (index !== -1 && !valueHandled) {
      valueHandled = true;
      // TODO: Document the whys and wherefores
      // signature is a concatenation of the length of the row and the indices where the array changes value
      // [1,1,1,2,2,2,3,3] => '8|,3,,6,'
      // [4,4,4,5,5,5,6,6] => '8|,3,,6,' NOTE: Same signature as above
      // [1] => '1|,0,'
      // [1,1,1] => '3|,0,0,0,'
      const { firstIndex, lastIndex, spans } = getPositionInfo(value, grid);
      const lastRowIndex = rowIndex + spans.row - 1;
      const createRowSignature = (r) => {
        return r.reduce((rowAcc, v, j) => {
          if (r[j - 1] !== r[j]) rowAcc = `${rowAcc},${j},`;
          return rowAcc;
        }, `${r.length}|`);
      };
      acc = {
        isTop: rowIndex === 0,
        isRight: row.slice().reverse().indexOf(value) === 0,
        isBottom: lastRowIndex === grid.length - 1,
        isLeft: index === 0,
        isPseudoTop:
          rowIndex === 0
            ? false
            : (() => {
                const prev = grid[rowIndex - 1];
                const thisSig = createRowSignature(row);
                const prevSig = createRowSignature(prev);
                const haveSameSig = thisSig === prevSig;
                const thisIndex = row.indexOf(value);
                const prevValue = prev[thisIndex];
                if (!haveSameSig) {
                  const thisIdLookup = gridObj.items[value].styleKey;
                  // NOTE: prevValue might not correspond to a feature
                  // e.g. the GRID_PLACEHOLDER_INFO.value occupies "holes" in the grid
                  if (gridObj.items[prevValue]) {
                    const prevIdLookup = gridObj.items[prevValue].styleKey;
                    // NOTE: row and prev transition at the same column as value
                    // and have same idLookup
                    if (thisIdLookup === prevIdLookup)
                      return !prevSig.match(`,${thisIndex},`);
                  }
                }
                return !haveSameSig;
              })(),
        isPseudoLeft:
          rowIndex === 0 || index === 0
            ? false
            : (() => {
                const prev = grid[rowIndex - 1];
                const prevIndex = firstIndex.col - 1;
                return row[prevIndex] === prev[prevIndex];
              })(),
        isPseudoRight:
          rowIndex === 0 || row.slice().reverse().indexOf(value) === 0
            ? false
            : (() => {
                const prev = grid[rowIndex - 1];
                const nextIndex = lastIndex.col + 1;
                return row[nextIndex] === prev[nextIndex];
              })(),
        isTiling: isItemTiling(value, gridObj, bp)
      };
    }
    return acc;
  }, {});
};

/*
 * INTERNAL
 *
 * @param {any} value -- The target value in the grid to get position info about
 * @param {array of arrays} grid -- The grid of all values
 * @param {obj} gridObj -- The grid itself, plus more data about the grid
 *
 * @returns {object} - Map where keys are each breakpoint and values are what's returned by
 *   getPositionInfoForValueAtBp(). See above.
 */
const getPositionInfoForValue = (GRID_INFO, value, gridKey, gridObj) => {
  return GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    const grid = gridObj.grids[gridKey][bp];
    acc[bp] = getPositionInfoForValueAtBp(value, grid, gridObj, bp);
    return acc;
  }, {});
};

/*
 * The jsonapp outputType consumes this
 *
 * @param {any} gridKey -- The particular grid of interest, e.g. "table1" or "table2", etc.
 * @param {obj} gridObj -- The grid itself, plus more data about the grid
 * @param {options} obj -- THe only supported option currently is "useVerboseKeys" cuz the jsonapp
 *   uses its own, verbose breakpoint keys which the web does not.
 *
 * @returns {object} - Map where keys are each breakpoint and values are what's returned by
 *   getPositionInfoForValueAtBp(). See above.
 *
 * @returns {object} - details of where the dividers go in the gridObj
 * {
 *   ${bp}: {
 *     horizontal: [
 *        { row: 1, col: 0, colpan: 4},
 *        { row: 1, col: 4, colpan: 8},
 *        { row: 0, col: 12, colpan: 4}
 *        ...
 *     ],
 *     vertical: [
 *        { col: 4, row: 0, rowspan: 2 },
 *        { col: 6, row: 2, rowspan: 1 }
 *        ...
 *      ]
 *   }
 *   ...
 * }
 */
export const createDividers = (GRID_INFO, gridKey, gridObj, options = {}) => {
  const { useVerboseKeys = false } = options;

  const createRowSignature = (r) => {
    return r.reduce((acc, v, j) => {
      if (r[j - 1] !== r[j] && j !== 0) acc.push(j);
      return acc;
    }, []);
  };

  const dividers = GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    const bpKey = useVerboseKeys ? info.bpVerbose : bp;
    const includeDividers = info.includeDividers;
    const grid = gridObj.grids[gridKey][bp];
    if (includeDividers && grid) {
      const totalRows = grid.length;

      // NOTE: For each row in a grid, note where values change, effectively
      // identifying where column gaps are.
      const signatures = grid.reduce((sigAcc, row) => {
        sigAcc.push(createRowSignature(row));
        return sigAcc;
      }, []);

      // NOTE: Identify all unique column gaps so that later, their row and rowpsan info
      // can be determined
      const uniqueCols = signatures.reduce((colsAcc, sig) => {
        sig.forEach((col) => {
          if (colsAcc.indexOf(col) === -1) colsAcc.push(col);
        });
        return colsAcc;
      }, []);

      acc[bpKey] = {
        /* horizontal goal is:
         * [
         *   { row: 1, col: 0, colpan: 4},
         *   { row: 1, col: 4, colpan: 8},
         *   { row: 0, col: 12, colpan: 4}
         *   ...
         * ]
         */

        horizontal: grid.reduce((horizontalAcc, thisRow, i) => {
          if (i > 0) {
            let colspan = 1;
            thisRow.forEach((value, j) => {
              const prevRow = grid[i - 1];
              const prevRowVal = prevRow[j];
              const nextColVal = thisRow[j + 1];
              let endOfRow = j + 1 === thisRow.length;
              endOfRow =
                nextColVal === GRID_PLACEHOLDER_INFO.value ? true : endOfRow;
              const endOfValueHor = value !== nextColVal;
              const endOfValueVer = value !== prevRowVal;
              let endHorizontalDivider =
                endOfValueVer && (endOfRow || endOfValueHor);
              if (endHorizontalDivider && !endOfRow) {
                // NOTE: Because horizontal dividers get broken up by vertical dividers,
                // must know isPseudoTop and isPseudoRight (see above for what that means)
                const { isPseudoTop, isPseudoRight } =
                  getPositionInfoForValueAtBp(value, grid, gridObj, bp);
                endHorizontalDivider = !isPseudoTop || isPseudoRight;
              }
              // NOTE: web can't render dividers when there is no item. Tracking value
              // and nextColVal variables as this code strives to mimic that behavior
              if (
                endHorizontalDivider &&
                (value !== GRID_PLACEHOLDER_INFO.value ||
                  nextColVal === GRID_PLACEHOLDER_INFO.value)
              ) {
                const dividerOk = value !== GRID_PLACEHOLDER_INFO.value;
                if (dividerOk) {
                  horizontalAcc.push({
                    row: i,
                    col: j + 1 - colspan,
                    colspan
                  });
                }
                colspan = 0;
              } else if (endOfValueHor && !endOfValueVer) {
                // NOTE: This catches rowspans
                colspan = 0;
              }
              colspan += 1;
            });
          }

          return horizontalAcc;
        }, []),

        /* vertical goal is:
         * [
         *   { col: 4, row: 0, rowspan: 2 },
         *   { col: 6, row: 2, rowspan: 1 }
         *   ...
         * ]
         */
        vertical: uniqueCols.reduce((verticalAcc, col) => {
          let row;
          let rowspan;
          signatures.forEach((sig, rowIdx) => {
            // NOTE: web can't render dividers when there is no item. Tracking value
            // variable as this code strives to mimic that behavior
            const value = grid[rowIdx][col];
            const isLastRow = rowIdx + 1 === totalRows;
            const rowHasCol = sig.indexOf(col) !== -1;
            if (rowHasCol && value !== GRID_PLACEHOLDER_INFO.value) {
              row = row !== undefined ? row : rowIdx;
              rowspan = rowspan !== undefined ? rowspan + 1 : 1;
            }
            // BEWARE: isLastSigOfItsKind fixed a bug, but will it cause others?
            let isLastSigOfItsKind = false;
            if (
              !isLastRow &&
              rowHasCol &&
              value !== GRID_PLACEHOLDER_INFO.value
            ) {
              const nextValue = grid[rowIdx + 1][col];
              isLastSigOfItsKind =
                gridObj.items[value].styleKey !==
                gridObj.items[nextValue].styleKey;
            }
            // NOTE: Make the line
            if (
              row !== undefined &&
              (isLastRow ||
                !rowHasCol ||
                value === GRID_PLACEHOLDER_INFO.value ||
                isLastSigOfItsKind)
            ) {
              verticalAcc.push({
                col,
                row,
                rowspan
              });
              row = undefined;
              rowspan = undefined;
            }
          });
          return verticalAcc;
        }, [])
      };
    }

    // START: This is less of a complete hack than the previous code!
    // This code identifies horizontal and vertical dividers to remove
    // TODO: Consider functionalizing this into getDividersToRemove()
    const directions = ["horizontal", "vertical"];
    const hasHorizontalDividers = get(acc, `${bpKey}.horizontal`, []).length;
    const hasVerticalDividers = get(acc, `${bpKey}.vertical`, []).length;
    const hasAnyDividers = hasHorizontalDividers || hasVerticalDividers;
    if (hasAnyDividers && grid && grid.length) {
      const targetDividersToRemove = { horizontal: [], vertical: [] };
      const targetDividerWeightsToAdd = { horizontal: [], vertical: [] };

      const keys = Object.keys(gridObj.items)
        // NOTE: coerce key to number b/c it could be "1" (string), but in the grid it will be 1 (number)
        .map((key) => (Number.isNaN(key / 1) ? key : key / 1))
        // NOTE: Filter out grid placeholder value
        .filter((key) => key !== GRID_PLACEHOLDER_INFO.value);

      keys.forEach((key) => {
        const posInfo = getPositionInfoAltForm(key, grid);
        const isTiling = isItemTiling(key, gridObj, bp);

        if (posInfo) {
          directions.forEach((dir) => {
            const {
              hasDividers,
              includeDividerKey,
              dividerWeightKey,
              inheritedFromParentKey,
              targetRowVal,
              targetColVal,
              targetSpanKey,
              targetSpanVal
            } =
              dir === "horizontal"
                ? {
                    hasDividers: hasHorizontalDividers,
                    includeDividerKey: "includeHorizontalDivider",
                    dividerWeightKey: "horizontalDividerWeight",
                    inheritedFromParentKey:
                      "horizontalDividerInheritedFromParent",
                    targetRowVal: posInfo.row + posInfo.rowspan,
                    targetColVal: posInfo.col,
                    targetSpanKey: "colspan",
                    targetSpanVal: posInfo.colspan
                  }
                : {
                    hasDividers: hasVerticalDividers,
                    includeDividerKey: "includeVerticalDivider",
                    dividerWeightKey: "verticalDividerWeight",
                    inheritedFromParentKey:
                      "verticalDividerInheritedFromParent",
                    targetRowVal: posInfo.row,
                    targetColVal: posInfo.col,
                    targetSpanKey: "rowspan",
                    targetSpanVal: posInfo.rowspan
                  };

            // NOTE: If not inherited from parent, let divider stand as-is if tiling. This is
            // because solitary and dis-harmonious dividers when tiling tend to look bad.
            const isOrphanedDivider = !get(
              gridObj.items[key],
              inheritedFromParentKey,
              false
            );
            const keepDividerAsIs = isTiling && isOrphanedDivider;

            // START: dividers
            if (hasDividers && !keepDividerAsIs) {
              let removeDivider = false;
              // START: dividers configured to be off
              removeDivider = !get(gridObj.items[key], includeDividerKey, true);

              // START: Double-line mitigation is only needed for horizontal dividers
              if (dir === "horizontal" && !removeDivider) {
                // NOTE: dividers known to be above items that have a label with a line on top
                // NOTE: Find all the nextKeys that are underneath the main key
                const nextKeys = getItemsUnderneathTarget(
                  key,
                  grid,
                  gridObj,
                  targetRowVal,
                  bp
                );
                if (nextKeys.length) {
                  removeDivider = nextKeys.every((nextKey) =>
                    get(
                      gridObj.items[nextKey],
                      `doubleLineMitigationCandidate.${bp}`,
                      false
                    )
                  );
                }
              }
              // END: Double-line mitigation

              if (removeDivider) {
                targetDividersToRemove[dir].push({
                  row: targetRowVal,
                  col: targetColVal
                  // NOTE: Not needed. If needed, would only be conditional
                  // [targetSpanKey]: targetSpanVal,
                });
              }

              // START: Enhance dividers with style as appropropriate based on divider weight
              // Of course, a divider that's going to be removed, need not be enhanced.
              if (!removeDivider) {
                let dividerWeight = get(
                  gridObj.items[key],
                  dividerWeightKey,
                  "normal"
                );
                dividerWeight = /^(none|normal)$/.test(dividerWeight)
                  ? undefined
                  : dividerWeight;
                if (dividerWeight) {
                  targetDividerWeightsToAdd[dir].push({
                    row: targetRowVal,
                    col: targetColVal,
                    [targetSpanKey]: targetSpanVal,
                    style: dividerWeight
                  });
                }
              }
              // END: Enhance dividers with style as appropropriate based on divider weight
            }
          });
        }
      });

      // START: remove dividers and add divider weights
      directions.forEach((dir) => {
        // TODO: Consider functionalizing this into removeDividers()
        targetDividersToRemove[dir].forEach((target) => {
          acc[bpKey][dir] = acc[bpKey][dir].reduce((hAcc, divider) => {
            if (
              !Object.keys(target).every((key) => divider[key] === target[key])
            ) {
              hAcc.push(divider);
            }
            return hAcc;
          }, []);
        });

        // TODO: Consider functionalizing this into addDividerWeights()
        targetDividerWeightsToAdd[dir].forEach((target) => {
          const axisKeys =
            dir === "horizontal"
              ? { off: "col", on: "row" }
              : { off: "row", on: "col" };
          acc[bpKey][dir].forEach((divider) => {
            if (
              Object.keys(target).every((key) => {
                if (key === axisKeys.off) {
                  return (
                    target[key] >= divider[key] &&
                    target[key] < divider[key] + divider[`${key}span`]
                  );
                }
                if (key === axisKeys.on) {
                  return divider[key] === target[key];
                }
                return true;
              })
            ) {
              divider.style = target.style;
            }
          });
        });
      });
      // END: remove dividers and add divider weights
    }

    // NOTE: Clean up empty array and undefined values
    if (acc[bpKey]) {
      if (!acc[bpKey].horizontal || !acc[bpKey].horizontal.length)
        delete acc[bpKey].horizontal;
      if (!acc[bpKey].vertical || !acc[bpKey].vertical.length)
        delete acc[bpKey].vertical;
      if (!acc[bpKey].horizontal && !acc[bpKey].vertical) delete acc[bpKey];
    }

    return acc;
  }, {});

  return dividers && Object.keys(dividers).length !== 0 ? dividers : undefined;
};

/*
 * Gets the classNames indicating the position of the item in the grid.
 *
 * @param {string} type - the dividers of interest, either tables or features
 *
 * @returns {string} - string representing the classNames to associate with the feature if the form:
 *
 */
export const getDividerClasses = (GRID_INFO, type) => {
  return GRID_INFO.reduce((acc, info) => {
    if (info.includeDividers) {
      acc = /^(tables|features)$/.test(type) ? `include-dividers-${type}` : "";
    }
    return acc;
  }, "");
};

/*
 * Gets the classNames indicating the position of the item in the grid.
 *
 * @param {any} value - the value of interest in the grid
 * @param {string} tableKey - the table (e.g. table1, table2, table3) where the feature comes from
 * @param {object} gridObj - custom object representing the position of items in the grid (see below)
 *                            at each breakpoint
 *
 * @returns {string} - string representing the classNames to associate with the feature if the form:
 *
 *                       ${bp}-vertical-pos ${bp}-horizontal-pos
 *                         [${bp}-tiling ${bp}-pseudo-top ${bp}-pseudo-right]
 *
 *                       vertical-pos can be: top, middle, bottom
 *                       the same item can be top and bottom (basically full-height of the grid it's in).
 *
 *                       horizontal-pos can be: left, center, right
 *                       the same item can be left and right (basically full-width of the grid it's in).
 *
 *                       tiling - means there is no fixed col start and this feature got its position
 *                                  because space was available in the grid.
 *
 *                       pseudo-top - means the feature is not actually at the top of the grid, but that
 *                                      the row above does not have a one-to-one correspondence in terms
 *                                      of how the items occupy the row. top and psuedo-top do not
 *                                      ever appear together.
 *
 *                                      This is useful for determining if horizontal lines should be lengthened
 *
 *                       pseudo-right - means to the right of the first row of this feature there is another
 *                                        feature whose first row is less than this feature's first row.
 *
 *                       Example below from the first of two items in table1.column.right
 *                       of 'Wide center - 3-col table at left' chain configuration
 *
 *                        mx-bottom mx-right
 *                        lg-bottom lg-right
 *                        md-tiling md-pseudo-top md-bottom md-left
 *                        sm-tiling sm-pseudo-top sm-middle sm-left sm-pseudo-right
 *                        xs-middle xs-left xs-right
 *
 *                     YES, it's that many classNames!
 */
export const getPositionClasses = (GRID_INFO, value, gridKey, gridObj) => {
  const positionInfo = getPositionInfoForValue(
    GRID_INFO,
    value,
    gridKey,
    gridObj
  );
  const classes = GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    const includeDividers = info.includeDividers;

    const {
      isTop,
      isRight,
      isBottom,
      isLeft,
      isPseudoTop,
      isPseudoLeft,
      isPseudoRight,
      isTiling
    } = positionInfo[bp];

    // START: Determine if horizontal/vertical dividers are off
    let isHorizontalDividerOff = false;
    let horizontalDividerWeight;

    let isVerticalDividerOff = false;
    let verticalDividerWeight;

    // NOTE: if something is isTop && isLeft it cannot have
    // a vertical or horizontal divider, so no use checking
    if (includeDividers && (!isTop || !isLeft)) {
      // NOTE: createDividers generates an object with dividers
      // removed based on various factors, including labels
      // so leverage that code to help here.
      const dividers = includeDividers
        ? createDividers(GRID_INFO, gridKey, gridObj)
        : undefined;

      // START: horizontal divider
      const hDivs = get(dividers, `${bp}.horizontal`, []);
      if (!hDivs.length) {
        // NOTE: EASY CASE. If no horizontal dividers exist whatsoever
        // then of course shutting them off is safe
        isHorizontalDividerOff = true;
      } else {
        // NOTE: HARD CASE
        const target =
          getPositionInfoAltForm(value, gridObj.grids[gridKey][bp]) || {};
        const doesMatchAnyRow = hDivs.reduce((anyRowAcc, divider) => {
          const doesMatchThisRow = Object.keys(target).reduce(
            (thisRowAcc, key) => {
              if (key === "col") {
                return (
                  thisRowAcc &&
                  target[key] >= divider[key] &&
                  target[key] < divider[key] + divider[`${key}span`]
                );
              }
              if (key === "row") {
                return thisRowAcc && target[key] === divider[key];
              }
              return thisRowAcc;
            },
            true
          );
          if (doesMatchThisRow) horizontalDividerWeight = divider.style;
          return anyRowAcc || doesMatchThisRow;
        }, false);
        isHorizontalDividerOff = !doesMatchAnyRow;
      }
      // END: horizontal divider

      // START: vertical divider
      const vDivs = get(dividers, `${bp}.vertical`, []);
      if (!vDivs.length) {
        // NOTE: EASY CASE. If no vertical dividers exist whatsoever
        // then of course shutting them off is safe
        isVerticalDividerOff = true;
      } else {
        // NOTE: HARD CASE
        const target =
          getPositionInfoAltForm(value, gridObj.grids[gridKey][bp]) || {};
        const doesMatchAnyCol = vDivs.reduce((anyColAcc, divider) => {
          const doesMatchThisCol = Object.keys(target).reduce(
            (thisColAcc, key) => {
              if (key === "row") {
                return (
                  thisColAcc &&
                  target[key] >= divider[key] &&
                  target[key] < divider[key] + divider[`${key}span`]
                );
              }
              if (key === "col") {
                return thisColAcc && target[key] === divider[key];
              }
              return thisColAcc;
            },
            true
          );
          if (doesMatchThisCol) verticalDividerWeight = divider.style;
          return anyColAcc || doesMatchThisCol;
        }, false);
        isVerticalDividerOff = !doesMatchAnyCol;
      }
      // END: vertical divider
    }
    // END: Determine if horizontal/vertical dividers are off

    // NOTE: Keep in sync with @include dividers("grid");
    // in src/websites/washpost/fronts/css-grid.scss
    const prefix = "grid";
    // const prefix = bp;

    let vpos = "";
    if (isTop) vpos = `${vpos} ${prefix}-top`;
    if (!isTop && !isBottom) vpos = `${vpos} ${prefix}-middle`;
    if (isBottom) vpos = `${vpos} ${prefix}-bottom`;

    let hpos = "";
    if (isLeft) hpos = `${hpos} ${prefix}-left`;
    if (!isLeft && !isRight) hpos = `${hpos} ${prefix}-center`;
    if (isRight) hpos = `${hpos} ${prefix}-right`;

    const pseudoTop = isPseudoTop ? `${prefix}-pseudo-top` : "";
    const pseudoLeft = isPseudoLeft ? `${prefix}-pseudo-left` : "";
    const pseudoRight = isPseudoRight ? `${prefix}-pseudo-right` : "";
    const tiling = isTiling ? `${prefix}-tiling` : "";
    const horizontalDividerOff = isHorizontalDividerOff
      ? `${prefix}-horizontal-divider-off`
      : "";
    horizontalDividerWeight = horizontalDividerWeight
      ? `${prefix}-horizontal-divider-${horizontalDividerWeight}`
      : "";

    const verticalDividerOff = isVerticalDividerOff
      ? `${prefix}-vertical-divider-off`
      : "";
    verticalDividerWeight = verticalDividerWeight
      ? `${prefix}-vertical-divider-${verticalDividerWeight}`
      : "";

    acc = `${acc} ${tiling} ${pseudoTop} ${pseudoLeft} ${pseudoRight} ${horizontalDividerOff} ${horizontalDividerWeight} ${verticalDividerOff} ${verticalDividerWeight} ${vpos} ${hpos}`;
    return acc;
  }, "");

  return classes.replace(/\s+/g, " ");
};

/**
 * Take each id and makes it the key in and object and the value is the table and column where
 * the feature should be placed
 * @param {object} layoutObj - the layout chosen by an editor
 * @param {object} customFields - props from top table chain, we are looking at these possible
 *                                      values table1LeftIds, table1MainIds, table1RightIds, table2Ids, table3Ids
 * @param {object} itemsPerFeatureMap - Object.keys(itemsPerFeatureMap).length will be used as maxValue. Any ids
 *                                greater than maxValue are ejected. The idea is that maxValue will be number of
 *                                items in the chain overall.
 *
 *                                chainProps is also be used to identify feeds which, if found, will lead
 *                                to an expanded lookup table.
 *
 * @returns {object}  keys are the ids and the values are the "table.column" string
 */
export function createIdLookup(layoutObj, customFields, itemsPerFeatureMap) {
  const maxValue = Object.keys(itemsPerFeatureMap).length;
  const lookup = Object.keys(layoutObj).reduce((acc, tableKey) => {
    let idKeys = {};
    if (layoutObj[tableKey].columns) {
      idKeys = Object.keys(layoutObj[tableKey].columns).reduce(
        (prevVal, col) => {
          const idKey = `${
            tableKey + col.charAt(0).toUpperCase() + col.substring(1)
          }Ids`;
          if (!customFields[idKey]) return prevVal;
          const idList = customFields[idKey].split(",").map((v) => v.trim());
          const idsLookup = idList.reduce((last, id) => {
            if (Number.parseInt(id, 10) <= maxValue)
              return { ...last, [id]: `${tableKey}.columns.${col}` };
            return last;
          }, {});
          return { ...prevVal, ...idsLookup };
        },
        idKeys
      );
    } else if (layoutObj.allcontent) {
      idKeys = Object.keys(layoutObj.allcontent).reduce((prevVal) => {
        const idList = [...Array(maxValue)].fill(0).map((v, i) => i + 1);
        const idsLookup = idList.reduce((last, id) => {
          if (Number.parseInt(id, 10) <= maxValue)
            return { ...last, [id]: `${tableKey}.childStyles` };
          return last;
        }, {});
        return { ...prevVal, ...idsLookup };
      }, idKeys);
    } else {
      const idKey = tableKey.match(/^table(Ad)?\d+$/)
        ? `${tableKey}LeftIds`
        : `${tableKey}Ids`;
      if (customFields[idKey]) {
        const idList = customFields[idKey].split(",").map((v) => v.trim());
        idKeys = idList.reduce((last, id) => {
          if (Number.parseInt(id, 10) <= maxValue)
            return { ...last, [id]: `${tableKey}.childStyles` };
          return last;
        }, {});
      }
    }
    return { ...acc, ...idKeys };
  }, {});
  return lookup;
}

/**
 * INTERNAL
 * Takes in a layoutObj and returns the column keys so we can validate for missing data
 * @param {object} layoutObj - selected top table layout
 *
 * @returns {array} array of table/column keys
 */
function getAllLayoutKeys(layoutObj) {
  return Object.keys(layoutObj).reduce((acc, tableKey) => {
    if (layoutObj[tableKey].columns) {
      acc = acc.concat(
        Object.keys(layoutObj[tableKey].columns).reduce((accumulator, col) => {
          accumulator.push(`${tableKey}.columns.${col}`);
          return accumulator;
        }, [])
      );
    } else {
      acc.push(`${tableKey}.childStyles`);
    }
    return acc;
  }, []);
}

/**
 * Take in the idLookup table and return a object with keys per table column with counts
 * @param {object} idLookup  - child id to table.column location
 * @returns {object} - keys per table.column and the values are count in each
 *  i.e. table1.right: 2
 */
export function getItemsPerColumn(idLookup, itemsPerFeatureMap) {
  const maxValue = Object.keys(itemsPerFeatureMap).length;
  return Object.keys(idLookup).reduce((acc, key) => {
    if (Number.parseInt(key, 10) <= maxValue) {
      if (acc[idLookup[key]] === undefined) acc[idLookup[key]] = 0;
      acc[idLookup[key]] += 1 * itemsPerFeatureMap[key];
    }
    return acc;
  }, {});
}

/* TODO
 * Document this
 */
export function getItemsPerColumnPerBp(
  GRID_INFO,
  idLookup,
  itemsPerFeatureMapPerBp
) {
  return GRID_INFO.reduce((acc, info) => {
    acc[info.bp] = getItemsPerColumn(
      idLookup,
      itemsPerFeatureMapPerBp[info.bp]
    );
    return acc;
  }, {});
}

/**
 * INTERNAL
 *
 * Based on the data passed in, creates a unified layoutStyle without any gaps by combining
 * styles from various sources (inerited from position in the grid and defaults for tiling/responsive
 * overflow
 *
 * @param {object} layoutObj - the layout object
 * @param {idLookup} object - helps find the styleKey
 * @param {string} styleKeys - ${styleKey}.split(".") where styleKey is like 'table1.columns.left'
 * @param {int} itemsPerColumn - number of items in a table or column of a table
 * @param {object} tilingStyles - the object containing all responsive/tiling layout specs
 * @param {int} featureId - id of the feature
 *
 * @returns {object} - combined layoutStyle object
 */
const createColStyles = (
  layoutObj,
  idLookup,
  styleKeys,
  itemsPerColumn,
  tilingStyles,
  featureId
) => {
  const baseStyleObj = {
    ...(styleKeys.length === 2
      ? layoutObj[styleKeys[0]][styleKeys[1]]
      : layoutObj[styleKeys[0]][styleKeys[1]][styleKeys[2]])
  };

  let responsiveKey = itemsPerColumn[idLookup[featureId]];
  if (responsiveKey > 8) {
    if (responsiveKey === 9) responsiveKey = 8;
    else if (responsiveKey === 10) responsiveKey = 5;
    else responsiveKey = 8;
  }
  responsiveKey = responsiveKey > 8 ? 5 : responsiveKey;

  // to avoid altering the underlying obj - js man it's weird sometimess
  const responsiveStyleObj = {
    ...(responsiveKey && tilingStyles[responsiveKey]
      ? tilingStyles[responsiveKey]
      : {})
  };
  const combinedStyleObj = {
    ...responsiveStyleObj,
    ...baseStyleObj
  };

  return combinedStyleObj;
};

// START: helpers for slotItem
const initRow = (length, value = GRID_PLACEHOLDER_INFO.value) =>
  new Array(length).fill(value);
const addRow = (grid, length, value = GRID_PLACEHOLDER_INFO.value) => {
  grid[grid.length] = initRow(length, value);
  return grid;
};
const fillRow = (value, start, end, info) => {
  let { grid } = info;
  const { parentColSpan, rowIndex } = info;
  if (!grid[rowIndex]) grid = addRow(grid, parentColSpan);
  grid[rowIndex].fill(value, start, end);
  return grid;
};
// END: helpers for slotItem
/*
 * INTERNAL -- May need to go EXTERNAL to get this to work with rowSpans and feeds
 *
 * Given an existing grid (which is just an Array of Arrays), find the positions
 * in the grid that can accomodate a new item given the positioning rules (col-span and col-start)
 *
 * @param {any} value - item to slot
 * @param {object} info - info needed to slot the item, including the grid to slot it into,
 *                          the parent col span, and col-span and col-start of the item
 *
 * returns {array of arrays} - Array of Arrays (i.e. a grid) with each position occupied by an int
 * representing a feature id
 *
 * Use it like this where these things have already been initialized:
 *      grid = slotItem(value, {
 *        grid,
 *        parentColSpan,
 *        itemRowSpan,
 *        itemColSpan,
 *        itemColStart
 *      });
 * }
 */
const slotItem = (value, info) => {
  let { grid } = info;
  const { parentColSpan, itemRowSpan, itemColSpan, itemColStart } = info;
  const slottingRegime =
    itemColStart === 0 ? "first-available-col" : "exact-col";
  let itemSlotted = false;
  let consecutiveRowsSlotted = 0;
  let firstSlottedRowIndex;
  const { rowIndex, colIndex } = grid.reduce(
    (acc, row, i) => {
      if (!itemSlotted) {
        if (slottingRegime === "first-available-col") {
          const tryColIndex = row.indexOf(GRID_PLACEHOLDER_INFO.value);
          if (tryColIndex === -1 && i === grid.length - 1) {
            acc.rowIndex = i + 1;
            itemSlotted = true;
          } else {
            const slice = row.slice(tryColIndex, tryColIndex + itemColSpan);
            const areSlotsAvailable =
              slice.join("").split(GRID_PLACEHOLDER_INFO.value).join("")
                .length === 0 &&
              slice.join("").length /
                `${GRID_PLACEHOLDER_INFO.value}`.length ===
                itemColSpan;
            if (areSlotsAvailable) {
              if (firstSlottedRowIndex === undefined) {
                firstSlottedRowIndex = i;
                consecutiveRowsSlotted += 1;
              } else if (i === firstSlottedRowIndex + consecutiveRowsSlotted) {
                consecutiveRowsSlotted += 1;
              } else {
                firstSlottedRowIndex = i;
                consecutiveRowsSlotted = 1;
              }
              acc.rowIndex = firstSlottedRowIndex;
              acc.colIndex = tryColIndex;
              itemSlotted = consecutiveRowsSlotted === itemRowSpan;
            } else {
              // TODO: Use the index of the last non-GRID_PLACEHOLDER_INFO.value in the slice,
              // to determine the next tryColIndex and try again, recursively
              // b/c there could be a slot in the row, just not the first one checked
              // fit [2,2,2,2] into say [0,0,1,0,1,0,1,0,0,0,0]
              // there's room at the end, but not the beginning or middle
              // the hypothetical code would try indexes in this order: 0,3,5,7 and not
              // find the slot until it tries 7
              // or fit [2,2,2,2] into say [0,0,1,0,0,0,0]
              // there's room at the end, but not the beginning
              // the hypothetical code would try indexes in this order: 0,3 and find a slot at 3

              // eslint-disable-next-line no-lonely-if
              if (!itemSlotted && i === grid.length - 1) {
                acc.rowIndex = i + 1;
                itemSlotted = true;
              }
            }
          }
        } else if (slottingRegime === "exact-col") {
          const slice = row.slice(acc.colIndex, acc.colIndex + itemColSpan);
          const areSlotsAvailable =
            slice.join("").split(GRID_PLACEHOLDER_INFO.value).join("")
              .length === 0;
          if (areSlotsAvailable) {
            if (firstSlottedRowIndex === undefined) {
              firstSlottedRowIndex = i;
              consecutiveRowsSlotted += 1;
            } else if (i === firstSlottedRowIndex + consecutiveRowsSlotted) {
              consecutiveRowsSlotted += 1;
            } else {
              firstSlottedRowIndex = i;
              consecutiveRowsSlotted = 1;
            }
            acc.rowIndex = firstSlottedRowIndex;
            itemSlotted = consecutiveRowsSlotted === itemRowSpan;
          } else if (i === grid.length - 1) {
            acc.rowIndex = i + 1;
            itemSlotted = true;
          }
        }
      }
      return acc;
    },
    {
      rowIndex: 0,
      colIndex:
        slottingRegime === "first-available-col"
          ? itemColStart
          : itemColStart - 1
    }
  );

  [...Array(itemRowSpan)].forEach((v, i) => {
    grid = fillRow(value, colIndex, colIndex + itemColSpan, {
      grid,
      parentColSpan,
      rowIndex: rowIndex + i
    });
  });

  return grid;
};

/*
 * INTERNAL
 *
 * @param {object} customFields -- props to harvest data from
 *
 * @returns {object} -- has the form:
 * {
 *   horizontalDividerWeight: boolean
 *   includeHorizontalDivider: boolean
 *   verticalDividerWeight: boolean
 *   includeVerticalDivider: boolean
 *   doubleLineMitigationCandidate: { bp: boolean }
 * }
 */
export const getExtraItemInfo = (
  GRID_INFO,
  gridKey,
  customFields,
  parent,
  index
) => {
  const extraInfo = {};

  // NOTE: Pack in more data if/when it becomes needed
  // START: divider weights
  const directions = ["horizontal", "vertical"];
  const dividerInfo = {
    horizontal: {},
    vertical: {}
  };
  directions.forEach((dir) => {
    const { namespace } =
      dir === "horizontal"
        ? {
            namespace: /^table(Ad)?\d+/.test(gridKey)
              ? `${gridKey}HorizontalDividerWeight`
              : "horizontalDividerWeight"
          }
        : {
            namespace: /^table(Ad)?\d+/.test(gridKey)
              ? `${gridKey}VerticalDividerWeight`
              : "verticalDividerWeight"
          };
    dividerInfo[dir].weight = get(
      customFields,
      namespace,
      // NOTE: Chains and features pass through this code but this will
      // only lookup the actual custom field default for chains. It is
      // only table0 that isn't "normal" so this is ok for now.
      get(customFieldDefaultsForChains, namespace, "normal")
    );
  });

  // START: parent dividers
  // NOTE: For features, the parent table can determine the divider weights
  let inheritedFromParent = false;
  const parentDividerInfo = { horizontal: {}, vertical: {} };
  if (parent) {
    let parentKey = get(parent, "key", undefined);
    parentKey = /^allcontent$/.test(parentKey) ? "table1" : parentKey;

    directions.forEach((dir) => {
      const { namespace } =
        dir === "horizontal"
          ? {
              namespace: `${parentKey}HorizontalDividerWeight`
            }
          : {
              namespace: `${parentKey}VerticalDividerWeight`
            };

      parentDividerInfo[dir].weight = get(
        parent.customFields,
        namespace,
        undefined
      );
    });

    // NOTE: The pay-off: if parent hDiv and vDiv are both "bold" or both are "none",
    // then the child inherits that value
    if (
      /^(bold|none)$/.test(parentDividerInfo.horizontal.weight) &&
      parentDividerInfo.horizontal.weight === parentDividerInfo.vertical.weight
    ) {
      inheritedFromParent = true;
      dividerInfo.horizontal.weight = parentDividerInfo.horizontal.weight;
      dividerInfo.vertical.weight = parentDividerInfo.vertical.weight;
    }
  }
  // END: parent dividers

  extraInfo.horizontalDividerWeight = dividerInfo.horizontal.weight;
  extraInfo.includeHorizontalDivider =
    extraInfo.horizontalDividerWeight !== "none";
  extraInfo.horizontalDividerInheritedFromParent = inheritedFromParent;
  // NOTE: tables always have default vertical divider weight. The vertical divider weights
  // on tables is passed down to the features in the code above.
  if (/^(table(Ad)?\d+|allcontent)/.test(gridKey)) {
    dividerInfo.vertical.weight = get(
      customFieldDefaultsForChains,
      dividerInfo.vertical.namespace,
      "normal"
    );
  }
  extraInfo.verticalDividerWeight = dividerInfo.vertical.weight;
  extraInfo.includeVerticalDivider = extraInfo.verticalDividerWeight !== "none";
  extraInfo.verticalDividerInheritedFromParent = inheritedFromParent;

  // START: doubleLineMitigationCandidate
  // NOTE: Feature-specific gotchas
  // 1. mobilePreset -- This could affect the arrangement.
  // 2. label -- in admin, could be blank preventing proper double-line mitigation.
  const namespace = /^table(Ad)?\d+/.test(gridKey)
    ? `${gridKey}Label`
    : "label";
  const defaultLabelType = /^table(Ad)?\d+/.test(gridKey)
    ? "Package"
    : "Kicker";

  // START: topper label is a feature config and there should be an index
  if (index === 0) {
    const topperNamespace = "topperLabel";
    const defaultTopperLabelType = "Package";
    const labelShow = get(customFields, `${topperNamespace}Show`, false);
    const label = get(customFields, `${topperNamespace}`);
    if (labelShow && label) {
      const labelType = tokenizeLabelType(
        get(customFields, `${topperNamespace}Type`, defaultTopperLabelType)
      );
      extraInfo.doubleLineMitigationCandidateFromTopperLabel = GRID_INFO.reduce(
        (acc, info) => {
          const bp = info.bp;

          const labelQualifies = labelType === "package";
          acc[bp] = labelQualifies;
          return acc;
        },
        {}
      );
    }
  }
  // END: topper label

  const labelShow = get(customFields, `${namespace}Show`, false);
  const label = get(customFields, `${namespace}`);
  const labelPosition = get(customFields, `${namespace}Position`, "Default");
  if (labelShow && label) {
    const labelType = tokenizeLabelType(
      get(customFields, `${namespace}Type`, defaultLabelType)
    );
    extraInfo.doubleLineMitigationCandidateFromLabel = GRID_INFO.reduce(
      (acc, info) => {
        const bp = info.bp;

        const labelQualifies = labelType === "package";

        // NOTE: artHide will always be true for table labels or
        // anything else that doesn't have an artHide customField
        const artHide = get(customFields, "artHide", true);
        let positionQualifies = artHide;
        if (labelQualifies && !positionQualifies) {
          const artPosition = get(customFields, "artPosition", "unknown");
          positionQualifies =
            !/^Art (left|right)$/.test(artPosition) &&
            (/Default/.test(labelPosition) ||
              !/^Art above head$/.test(artPosition));
        }
        acc[bp] = labelQualifies && positionQualifies;
        return acc;
      },
      {}
    );
  }

  // NOTE: If mitigate is true or false from topper obey it. Otherwise use label
  extraInfo.doubleLineMitigationCandidate = GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    acc[bp] =
      typeof extraInfo?.doubleLineMitigationCandidateFromTopperLabel?.[bp] ===
      "boolean"
        ? extraInfo.doubleLineMitigationCandidateFromTopperLabel[bp]
        : !!extraInfo?.doubleLineMitigationCandidateFromLabel?.[bp];
    return acc;
  }, {});
  delete extraInfo.doubleLineMitigationCandidateFromLabel;
  // END: doubleLineMitigationCandidate

  return extraInfo;
};

/*
 * @param {object} layoutObj - selected top table layout
 *
 * @returns {object} - has the form:
 * {
 *   grids: {
 *    "chain": {
 *      ${bp}: Array of Arrays (i.e. a grid) with each position occupied by a string
 *            representing a the table key (table1, table2, table3, allcontent, etc.). Generated by slotItem
 *      ...
 *    }
 *   }
 *   items: {
 *    ${tableKey}: {
 *      styleKey: 'table1' or 'table2' etc
 *      ... extend with other useful info
 *    }
 *    ...
 *   }
 *   styles: {
 *    ${tableKey}: layout style
 *    ...
 *   }
 * }
 *
 * WHERE:
 * tableKey -- "table1", "table2", etc.
 * bp -- mx, lg, md, sm, xs
 *
 */
export const createChainGridObj = (GRID_INFO, layoutObjPerBp, customFields) => {
  return GRID_INFO.reduce(
    (acc, info) => {
      const bp = info.bp;
      const fullColSpan = info.fullColSpan;
      let grid = [];

      const layoutObj = layoutObjPerBp[bp];
      Object.keys(layoutObj).forEach((tableKey) => {
        acc.grids.chain = acc.grids.chain || {};

        const tableStyles = layoutObj[tableKey].styles;
        // NOTE: Pack in more data if/when it becomes needed
        acc.styles[tableKey] = acc.styles[tableKey] || tableStyles;
        acc.items[tableKey] = {
          styleKey: tableKey,
          ...getExtraItemInfo(GRID_INFO, tableKey, customFields)
        };

        const tableRowSpan = tableStyles[`--r-span-${bp}`] || 1;
        const tableColSpan = tableStyles[`--c-span-${bp}`];
        const tableColStart = tableStyles[`--c-start-${bp}`];

        grid = slotItem(tableKey, {
          grid,
          parentColSpan: fullColSpan,
          itemRowSpan: tableRowSpan,
          itemColSpan: tableColSpan,
          itemColStart: tableColStart
        });

        acc.grids.chain[bp] = grid;
      });
      return acc;
    },
    {
      grids: {},
      items: {
        [GRID_PLACEHOLDER_INFO.value]: {
          styleKey: GRID_PLACEHOLDER_INFO.styleKey
        }
      },
      styles: {
        [GRID_PLACEHOLDER_INFO.styleKey]: GRID_PLACEHOLDER_INFO.styles,
        chain: createImplicitGridStyles()
      }
    }
  );
};

/*
 * @param {object} children - react children for the top table
 * @param {object} layoutObj - the layout obj
 * @param {object} allFeatureIdsByTable - object generated by getAllFeatureIdsByTable() (see above)
 * @param {object} idLookup - object created by createIdLookup() (see above)
 * @param {object} itemsPerColumn - table.column to number of items in that table.column
 *
 * @returns {object} - has the form:
 * {
 *   grids: {
 *    ${tableKey}: {
 *      ${bp}: Array of Arrays (i.e. a grid) with each position occupied by an int
 *            representing a feature id. Generated by slotItem
 *      ...
 *    }
 *    ...
 *   }
 *   items: {
 *    ${featureId}: {
 *      styleKey: 'table1.columns.main' or 'table2.childStyles' etc
 *      itemRowStyles: layoutStyles for rows
 *      ... extend with other useful info
 *    }
 *    ...
 *   }
 *   styles: {
 *    ${tableKey}: layout style
 *    ...
 *    ${styleKey}: layout style
 *    ...
 *   }
 * }
 *
 * WHERE:
 * featureId -- int representing the feature sequence
 * tableKey -- "table1", "table2", etc.
 * styleKey -- "table1.columns.main" or "table2.childStyles" etc
 * bp -- mx, lg, md, sm, xs
 *
 */
export const createGridObj = (
  GRID_INFO,
  children,
  childProps,
  layoutObjPerBp,
  allFeatureIdsByTablePerBp,
  idLookup,
  itemsPerFeatureMapPerBp,
  itemsPerColumnPerBp,
  desktopOrderingObj,
  chainCustomFields
) => {
  return GRID_INFO.reduce(
    (acc, info) => {
      const bp = info.bp;
      const useDesktopOrdering = desktopOrderingObj[bp];

      const layoutObj = layoutObjPerBp[bp];
      Object.keys(allFeatureIdsByTablePerBp[bp]).forEach((tableKey) => {
        const allFeatureIdsByTable = allFeatureIdsByTablePerBp[bp];
        const itemsPerFeatureMap = itemsPerFeatureMapPerBp[bp];
        const itemsPerColumn = itemsPerColumnPerBp[bp];

        acc.grids[tableKey] = acc.grids[tableKey] || {};

        // NOTE: Pack in more data if/when it becomes needed
        acc.styles[tableKey] =
          acc.styles[tableKey] || layoutObj[tableKey].styles;
        const tilingStyles = getTilingStyles(acc.styles[tableKey]);

        const tableColStyles = acc.styles[tableKey];
        const tableColSpan = tableColStyles[`--c-span-${bp}`];

        let grid = [];

        // TODO: Need to sort() for mobile ordering
        const featureIdsToUse = useDesktopOrdering
          ? allFeatureIdsByTable[tableKey]
          : allFeatureIdsByTable[tableKey].slice().sort((a, b) => a - b);
        featureIdsToUse.forEach((featureId) => {
          const featureIndex = featureId - 1;
          const styleKey = idLookup[featureId];
          // NOTE: Ugh. Needed for double-line mitigation
          const customFields = applyMobilePresets({
            customFields: get(childProps[featureIndex], "customFields", {}),
            chainCtx: { useDesktopOrdering }
          });

          // NOTE: b/c a user can enter a feature id that's out of bounds
          // (say '10', in a chain that only had 3 items) and those get
          // filtered out upstream of this code, only proceed if styleKey exists
          if (styleKey) {
            // NOTE: colStyles come by virtue of position in table/column
            const itemColStyles = createColStyles(
              layoutObj,
              idLookup,
              styleKey.split("."),
              itemsPerColumn,
              tilingStyles,
              featureId
            );

            // NOTE: rowStyles come by virtue of the rowSpan customField on the feature
            const itemRowStyles =
              acc.items[featureId] && acc.items[featureId].itemRowStyles
                ? acc.items[featureId].itemRowStyles
                : (() => {
                    let rowSpan = customFields.rowSpan;
                    rowSpan =
                      !Number.isNaN(rowSpan) && rowSpan >= 1
                        ? Number.parseInt(rowSpan, 10)
                        : 1;

                    return createRowStyles(
                      rowSpan,
                      itemColStyles,
                      tableColStyles
                    );
                  })();
            const itemRowSpan = itemRowStyles[`--r-span-${bp}`] || 1;
            const itemColStart = itemColStyles[`--c-start-${bp}`];
            const itemColSpan = itemColStyles[`--c-span-${bp}`];

            const itemsPerFeature = itemsPerFeatureMap
              ? itemsPerFeatureMap[featureId]
              : 1;
            [...Array(itemsPerFeature)].forEach((_, i) => {
              grid = slotItem(featureId + i * 0.001, {
                grid,
                parentColSpan: tableColSpan,
                itemRowSpan,
                itemColSpan,
                itemColStart
              });

              // NOTE: Pack in more data if/when it becomes needed
              acc.items[featureId + i * 0.001] = acc.items[
                featureId + i * 0.001
              ] || {
                styleKey,
                itemRowStyles,
                ...getExtraItemInfo(
                  GRID_INFO,
                  featureIndex,
                  customFields,
                  {
                    customFields: chainCustomFields,
                    key: tableKey
                  },
                  i
                )
              };
            });

            acc.styles[styleKey] = acc.styles[styleKey] || itemColStyles;
          }
        }); // tableKeysToUse

        acc.grids[tableKey][bp] = grid;
      }); // allFeatureIdsByTable
      return acc;
    },
    {
      grids: {},
      items: {
        [GRID_PLACEHOLDER_INFO.value]: {
          styleKey: GRID_PLACEHOLDER_INFO.styleKey
        }
      },
      styles: {
        [GRID_PLACEHOLDER_INFO.styleKey]: GRID_PLACEHOLDER_INFO.styles
      }
    }
  ); // GRID_INFO
};

/*
 * Used to determine how to order the features at each breakpoint
 *
 * @param {string} layoutName - A layout name like "Wide center - 3-col table at left"
 *
 * @returns {object} - keys are breakpoints and values are booleans
 *   indicating whether to use desktop ordering at that breakpoint or not.
 *
 *      {
 *        mx: boolean,
 *        lg: boolean,
 *        md: boolean,
 *        sm: boolean,
 *        xs: boolean
 *      }
 */
export const createDesktopOrderingObj = (GRID_INFO, layoutName) => {
  return GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    acc[bp] = shouldUseDesktopOrdering(layoutName, bp);
    return acc;
  }, {});
};

/*
 * Gets the classNames indicating the whether to use desktop ordering or not. These classNames
 *   are put on the chain containter.
 *
 * @param {object} desktopOrderingObj - The object generated by createDesktopOrderingObj
 *
 * @returns {string} - string representing the desktop ordering classNames, like:
 *
 *        mx-dsktp-order lg-dsktp-order
 *
 */
export const getDesktopOrderingClasses = (desktopOrderingObj) => {
  return Object.keys(desktopOrderingObj).reduce((acc, bp) => {
    if (desktopOrderingObj[bp]) {
      acc = `${acc} ${bp}-dsktp-order`;
    }
    return acc;
  }, "");
};

/**
 * Takes in parameters and returns data structured for rendering
 *
 * @param {object} children - react children for the top table
 * @param {object} layoutObj - the layout obj
 *                            values table1LeftIds, table1MainIds, table1RightIds, table2Ids, table3Ids
 * @param {array} allFeatureIds - as generated by getAllFeatureIds() (see above)
 * @param {object} gridObj - as created by createGridObj() (see above)
 *
 * @returns {object} - keys per table and array of items to render per table
 *
 *  {
 *    table1: [
 *      {flexFeature: child, styleVar: obj, key: 1}
 *    ],
 *    table2: [
 *      {flexFeature: child, styleVars: obj, key: 1} //order per table starts at 0
 *    ]
 * }
 */
export function createRenderDataStructure(
  GRID_INFO,
  children,
  layoutObj,
  itemsPerFeatureMap,
  allFeatureIds,
  gridObj
) {
  // Single items are passed to chain in tests (and may theoretically be possible?)
  // so in case that children isn't an array
  const renderData = children.reduce
    ? children.reduce((acc, child, featureIndex) => {
        const desktopIndex = allFeatureIds.indexOf(featureIndex + 1);
        if (desktopIndex === -1) return acc;
        // Desktop Order only for Max + Lg breakpoints. Will need to revisit
        const featureId = featureIndex + 1;
        const styleKey = gridObj.items[featureId].styleKey;
        const styleKeys = styleKey.split(".");
        const tableKey = styleKeys[0];
        // NOTE: This is an obscure location for something quite important ...
        // The merging of:
        //    col layoutStyles - (which is consistent per table/column) and
        //    row layoutStyles - (which are specific to each feature)
        // The gridObj could hold the merged styles, but then there'd be object bloat
        const itemStyles = {
          ...gridObj.styles[styleKey],
          ...gridObj.items[featureId].itemRowStyles
        };

        const itemsPerFeature = itemsPerFeatureMap
          ? itemsPerFeatureMap[featureId]
          : 1;
        const defaultStyle = styleKeys.join("-");
        const positionClassesArray = [...Array(itemsPerFeature)].map(
          (_, i) =>
            `${defaultStyle} ${getPositionClasses(
              GRID_INFO,
              featureId + i * 0.001,
              tableKey,
              gridObj
            )}`
        );
        const positionClasses = positionClassesArray.length
          ? positionClassesArray[0]
          : defaultStyle;

        acc[tableKey] = acc[tableKey] || [];
        acc[tableKey].push({
          feature: child,
          styleVars: {
            ...itemStyles,
            "--dsktp-order": desktopIndex
          },
          positionClassesArray,
          positionClasses,
          key: `${styleKey}_${desktopIndex}`,
          itemId: featureId
        });
        return acc;
      }, {})
    : {};
  // NOTE: This assures tables are in layoutObj order
  const allTables = Object.keys(layoutObj).reduce((acc, tableKey) => {
    acc[tableKey] = renderData[tableKey] || [];
    return acc;
  }, {});
  return allTables;
}

export function validateTopTable(
  GRID_INFO,
  isAdmin,
  layoutName,
  layoutObj,
  itemsPerColumn
) {
  // NOTE: Special behavior for full span -- any number is valid
  // though not completely empty
  const isFullSpanAtAllBpsAndNotZero =
    layoutObj &&
    layoutObj.allcontent &&
    layoutObj.allcontent.childStyles &&
    itemsPerColumn["allcontent.childStyles"] &&
    itemsPerColumn["allcontent.childStyles"] !== 0
      ? GRID_INFO.reduce((acc, info) => {
          return (
            acc &&
            layoutObj.allcontent.childStyles[`--c-span-${info.bp}`] ===
              info.fullColSpan
          );
        }, true)
      : false;

  if (isFullSpanAtAllBpsAndNotZero) return {};

  const layoutKeys = getAllLayoutKeys(layoutObj);

  const reduceValidationMessages = (acc, k) => {
    if (!itemsPerColumn[k]) {
      acc[k] = adminValidationMessages["add-features"];
      /* NOTE: For now, three-item warning has been deemed unnecessary by Newsroom/Design
    } else if (itemsPerColumn[k] === 3) {
      acc[k] = adminValidationMessages["three-items"];
      */
    } else if (itemsPerColumn[k] === 5 || itemsPerColumn[k] === 7) {
      acc[k] = adminValidationMessages["odd-items"];
    } else if (itemsPerColumn[k] > 8) {
      acc[k] = adminValidationMessages["too-many"];
    } else if (
      itemsPerColumn["table9.childStyles"] < 3 &&
      k === "table9.childStyles"
    ) {
      acc[k] = adminValidationMessages["far-right"];
    } else if (
      (layoutName.match(/(extra-)?wide center/i) ||
        layoutName.match(/wide left/i)) &&
      k === "table1.columns.right"
    ) {
      if (itemsPerColumn["table1.columns.right"] === 2)
        acc[k] = adminValidationMessages["same-preset"];
      if (itemsPerColumn["table1.columns.right"] === 1)
        acc[k] = adminValidationMessages["full-width"];
    }
    return acc;
  };

  return isAdmin ? layoutKeys.reduce(reduceValidationMessages, {}) : {};
}

/**
 * This builds the list of children not assigned to a column or table but in the chain
 * @param {object} layoutObj - the layout obj
 * @param {object} customFields - props from top table chain, we are looking at these possible
 *                                      values table1LeftIds, table1MainIds, table1RightIds, table2Ids, table3Ids
 *
 * @returns {array} - the ids of unassigned children
 */
export function findMissingChildrenIds(
  allAssignedIds,
  itemsPerFeatureMap,
  childProps,
  showHelpers
) {
  const missingIds = Object.keys(itemsPerFeatureMap).map
    ? Object.keys(itemsPerFeatureMap)
        .map((c, i) => i + 1)
        .filter((idx) => allAssignedIds.indexOf(idx) === -1)
        .filter((c) =>
          showHelpers
            ? true
            : !/fronts\/in-table-ad/.test(childProps[c - 1]?.type)
        )
    : [];
  return missingIds;
}

export const getAssignedFeatureIds = ({
  HPLayoutSynonyms,
  layoutStyles,
  customFields
}) => {
  let { layout: layoutName } = customFields;
  layoutName = HPLayoutSynonyms[layoutName] || layoutName;
  const hasTopStrip =
    !/Tiling|Full span|======/.test(layoutName) && !!customFields.table0LeftIds;
  layoutName = `${layoutName}${hasTopStrip ? " with top strip" : ""}`;
  const layoutObj = layoutStyles[layoutName];

  if (!layoutObj) return [];

  const zones = Object.keys(layoutObj)
    .filter((v) => v.match(/^table|allcontent/))
    .reduce((acc, tableKey) => {
      if (layoutObj[tableKey].childStyles) {
        // NOTE: Unlike Fusion, Assembler only shows table1LeftIds instead of
        // all content, so get table1LeftIds only
        if (tableKey === "allcontent") tableKey = "table1";
        acc.push(`${tableKey}LeftIds`);
      } else if (layoutObj[tableKey].columns) {
        Object.keys(layoutObj[tableKey].columns).forEach((col) => {
          acc.push(
            `${tableKey}${col.charAt(0).toUpperCase()}${col.substring(1)}Ids`
          );
        });
      }
      return acc;
    }, []);

  return zones.reduce((acc, zone) => {
    if (customFields[zone]) {
      return [...acc, ...customFields[zone].split(",")];
    }
    return acc;
  }, []);
};

export const getAssignedFeaturesByIndex = (props) =>
  getAssignedFeatureIds(props).map((_) => Number.parseInt(_, 10) - 1);

export const findHiddenFeatureIndices = (
  outputType,
  useDesktopOrdering,
  childProps,
  showHelpers
) => {
  const checkHidden = useDesktopOrdering
    ? ({ customFields } = {}) =>
        isFeatureHiddenOnDesktop(customFields, outputType)
    : ({ customFields } = {}) =>
        isFeatureHiddenOnMobile(customFields, outputType);
  return childProps.reduce((acc, child, i) => {
    if (
      !showHelpers &&
      !/fronts\/in-table-ad/.test(child.type) &&
      checkHidden(child)
    )
      acc.push(i);
    return acc;
  }, []);
};

export const findUnwebbedFeatureIndices = (
  outputType,
  childProps,
  showHelpers
) => {
  const checkHidden = ({ customFields } = {}) =>
    isFeatureHiddenFromPlatform(customFields, outputType);
  return childProps.reduce((acc, child, i) => {
    if (
      !showHelpers &&
      !/fronts\/in-table-ad/.test(child.type) &&
      checkHidden(child)
    )
      acc.push(i);
    return acc;
  }, []);
};

const isChainHiddenFromPlatform = (customFields, outputType) => {
  const { hideFromApps, hideFromAppsLite, hideFromWeb } = customFields;
  if (/jsonapp/.test(outputType)) {
    if (outputType === "jsonapp-lite")
      return !!hideFromApps || !!hideFromAppsLite;
    return !!hideFromApps;
  }
  return !!hideFromWeb;
};

/**
 * This builds an object with keys as breakpoint. Each breakpoint has a "hide" boolean.
 * @param {object} desktopOrderingObj - the desktop ordering obj
 * @param {object} customFields - props from top table chain, we are looking for hideFrom(Desktop|Mobile)
 *
 * @returns {object} - { [bp]: { hide: boolean } }
 */
export const getHiddenChainInfo = (
  desktopOrderingObj,
  customFields,
  outputType = "default"
) => {
  const { hideFromDesktop = false, hideFromMobile = false } = customFields;
  const hideFromDevice = isChainHiddenFromPlatform(customFields, outputType);
  return Object.keys(desktopOrderingObj).reduce((acc, bp) => {
    const useDesktopOrdering = get(desktopOrderingObj, `${bp}`, true);
    const hide =
      hideFromDevice ||
      (useDesktopOrdering ? !!hideFromDesktop : !!hideFromMobile);
    let msg = "";
    if (hide) {
      const size = useDesktopOrdering ? "desktop" : "mobile";
      const device = /jsonapp/.test(outputType) ? "apps" : "web";
      msg = hideFromDevice ? device : size;
    }
    acc[bp] = { hide, msg };
    return acc;
  }, {});
};

/**
 * This builds an object with breakpoints as keys. This is used in combination with this css:
 *    grid-template-rows: repeat(var(--extra-rows-[bp]), max-content) 1fr;
 * which makes layouts that have tables that span multiple rows render without extra space
 * between the tables that get spanned. The classic example is a layout with a top-strip and tables
 * below that with a far-right table spanning them all.
 *
 * @param {object} GRID_INFO - Info about each breakpoint.
 * @param {object} layoutObj - the layout obj
 *
 * @returns {object} - { [bp]: { classNames: string, styles: { --extra-rows-[bp]: int } } }
 */
export const getExtraRowInfo = (GRID_INFO = IMPLICIT_GRID_INFO, layoutObj) =>
  GRID_INFO.reduce((acc, info) => {
    const bp = info.bp;
    const extraRows = Object.keys(layoutObj).reduce((v, key) => {
      const rowSpan = (layoutObj[key]?.styles || {})[`--r-span-${bp}`] || 1;
      return rowSpan - 1 > v ? rowSpan - 1 : v;
    }, 0);
    acc[bp] = {
      classNames: extraRows ? "has-spanning-rows" : "",
      styles: extraRows
        ? {
            [`--extra-rows-${bp}`]: extraRows
          }
        : {}
    };
    return acc;
  }, {});

/* Used to determine the gaps in the grid of tables and grid of features that comprise a chain
 *
 * @param {string} layoutName - A layout name like "Wide center - 3-col table at left"
 *
 * @returns {object} - In all combinations of table|feature and row|col
 *
 *    { "--(table|feature)-(row|col)-gap": "{int}px" }
 */
export const getGridGapStyles = (layoutName) => ({
  ...gridGapStyles.default,
  ...(gridGapStyles[layoutName] || {})
});

/* Used jsonapp to translate the web-friendly grid gap object into a jsonapp-friendly one.
 * It currently only checks if the --table-row-gap is 64px and if it does, it creates an
 * object per breakpoint. Otherwise, it returns undefined.
 *
 * @param {object} GRID_INFO - Info about each breakpoint.
 * @param {string} layoutName - A layout name like "Wide center - 3-col table at left"
 * @param {options} obj -- The only supported option currently is "useVerboseKeys" cuz the jsonapp
 *   uses its own, verbose breakpoint keys which the web does not.
 *
 * @returns {object} - Can return undefined, too. But when an object:
 *
 *    { [bp]: vertical: { "large" } }
 */
export const createGridGaps = (GRID_INFO, layoutName, options = {}) => {
  const { useVerboseKeys = false } = options;
  const styles = getGridGapStyles(layoutName);

  return GRID_INFO.reduce((accGaps, info) => {
    if (styles["--table-row-gap"] === "64px") {
      const bpKey = useVerboseKeys ? info.bpVerbose : info.bp;
      accGaps = accGaps || {};
      accGaps[bpKey] = { vertical: "large" };
    }
    return accGaps;
  }, undefined);
};

export const getHideClasses = (
  layoutName,
  customFields,
  outputType,
  isAdmin
) => {
  if (isAdmin) return "";

  const { hideFromWeb = false } = customFields;
  let { hideFromDesktop = false, hideFromMobile } = customFields;

  // NOTE: Custom fields are not consistent, so reconcile them
  hideFromDesktop =
    hideFromDesktop || isFeatureHiddenOnDesktop(customFields, outputType);
  hideFromMobile =
    hideFromMobile || isFeatureHiddenOnMobile(customFields, outputType);

  if (hideFromWeb || (hideFromDesktop && hideFromMobile)) return "dn";

  if (!hideFromDesktop && !hideFromMobile) return "";

  // NOTE: hideFromDesktop || hideFromMobile
  const desktopOrderingObj = createDesktopOrderingObj(
    IMPLICIT_GRID_INFO,
    layoutName
  );
  return Object.keys(desktopOrderingObj).reduce((acc, bp) => {
    const useDesktopOrdering = desktopOrderingObj[bp];
    if (useDesktopOrdering) {
      acc = getClasses(acc, { [`dn-hp-${bp}`]: hideFromDesktop });
    } else {
      acc = getClasses(acc, { [`dn-hp-${bp}`]: hideFromMobile });
    }
    return acc;
  }, "");
};

export const getActiveBreakpoints = ({
  GRID_INFO = IMPLICIT_GRID_INFO,
  layoutName,
  customFields,
  outputType
}) => {
  const desktopOrderingObj = createDesktopOrderingObj(GRID_INFO, layoutName);
  return GRID_INFO.reduce((acc, info) => {
    const isDesktop = desktopOrderingObj[info.bp];
    if (
      (isDesktop && !isFeatureHiddenOnDesktop(customFields, outputType)) ||
      (!isDesktop && !isFeatureHiddenOnMobile(customFields, outputType))
    )
      acc.push(info.bp);
    return acc;
  }, []);
};

/*
 * The compound label can have a label and a search box. When not centered,
 * whether these elements should render stacked or side-by-side
 * depends on the colspan. This method returns the correct string
 *
 * @param {number} colspan - the number of cols
 * @returns {string} - side-by-side or stacked
 */
export const getLabelArrangement = (colspan) =>
  colspan >= 10 ? "side-by-side" : "stacked";

/**
 * @func showChainOnDesktopOrMobile
 * @desc determine if the chain appears on either desktop or mobile.
 *   this is useful for apps, less useful for web.
 * @param {obj} the chain extracted from the tree object
 * @return {bool}
 *
 */
export const showChainOnDesktopOrMobile = (customFields, outputType) => {
  const { hideFromDesktop, hideFromMobile } = customFields;
  return !(
    isChainHiddenFromPlatform(customFields, outputType) ||
    (hideFromDesktop && hideFromMobile)
  );
};
