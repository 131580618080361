const getCookie = (name) => {
  if (typeof window === "undefined") return undefined;
  if (typeof document === "undefined") return undefined;
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

export const setCookie = (cookieName, cookieValue, days) => {
  if (typeof document !== "undefined") {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = !days ? "" : ` expires=${date.toUTCString()};`;
    document.cookie = `${cookieName}=${cookieValue};${expires}`;
  }
};

export const getAllCookieNames = () => {
  if (
    typeof window === "undefined" ||
    typeof document === "undefined" ||
    typeof document.cookie === "undefined"
  ) {
    return [];
  }
  return document.cookie
    .split(";")
    .filter((s) => s)
    .map((keyVal) => {
      const [key] = keyVal.split("=");
      return key.trim();
    });
};

export const deleteCookie = (cookieName) => {
  if (getCookie(cookieName)) {
    const expirationDate = new Date(-1).toUTCString();
    document.cookie = `${cookieName}=;expires=${expirationDate}`;
    document.cookie = `${cookieName}=;path=/;expires=${expirationDate}`;

    const hostnameArray = window.location.hostname.split(".");
    while (hostnameArray.length > 1) {
      const host = hostnameArray.join(".");
      document.cookie = `${cookieName}=;path=/;expires=${expirationDate}`;
      document.cookie = `${cookieName}=;domain=.${host};expires=${expirationDate}`;
      document.cookie = `${cookieName}=;path=/;domain=.${host};expires=${expirationDate}`;
      hostnameArray.splice(0, 1);
    }
  }
};

export default getCookie;
