// TODO: Add the zones/sections for other layouts here
export const layoutToZonesMap = {
  homepage: [
    "top-furniture",
    "full-width-topper",
    "content",
    "full-width-footer",
    "footer-furniture"
  ]
};
