import PropTypes from "prop-types";
import {
  FlexFeaturePropTypes as BorrowedPropTypes,
  everythingButTheCustomFields
} from "./flex-feature";

const safelist = [
  "displayName",
  "rowSpan",
  "textAlignment",
  "horizontalDividerWeight",
  "verticalDividerWeight",
  "hideOnDesktop",
  "labelShow",
  "labelType",
  "labelPosition",
  "label",
  "alternateArt",
  "alternateArtMobileOnly",
  "coverArtUrl",
  "artHide",
  "artPosition",
  "artSize",
  "artAspectRatio",
  "wrapText",
  "artUseHiRes",
  "captionContent",
  "headlineHide",
  "headline",
  "headlineMobileOnly",
  "blurbHide",
  "descriptionText",
  "relatedLinksPosition",
  "relatedLinksNum",
  "relatedLinksNumMobileOnly",
  "relatedLinksOrder",
  "relatedLinksStyle",
  "relatedLinksLabel"
];

const customFields = Object.keys(BorrowedPropTypes.customFields.args)
  .filter((key) => safelist.includes(key))
  .reduce((obj, key) => {
    obj[key] = BorrowedPropTypes.customFields.args[key];
    return obj;
  }, {});

export const AppFallbackPropTypes = {
  ...everythingButTheCustomFields,
  customFields: PropTypes.shape({
    ...customFields,
    previewAppFallback: PropTypes.bool.tag({
      name: "Preview app fallback",
      description: "Check this to preview the app fallback (admin only)",
      defaultValue: false
    })
  })
};
