import get from "lodash.get";

// NOTE: Extend this as necessary
export const commonDefects = {
  "THE WASHINGTON POST": "The Washington Post",
  REUTERS: "Reuters",
  ap: "AP"
};

const fixCommonDefects = (text) => {
  const fix = commonDefects[text];
  if (fix) return commonDefects[text];

  return text;
};

/**
 * Receives anglerfish content object and creates caption and credits
 * of the form:
 *
 * {
 *    credits_display: string
 *    credits_caption_display: string
 *    dangerous: {
 *      raw_caption
 *    }
 * }
 *
 */
export default function createCaptionAndCredits(data) {
  const rawCaption = get(data, "caption", "");
  const org = fixCommonDefects(get(data, "credits.affiliation[0].name", ""));
  const name = fixCommonDefects(get(data, "credits.by[0].name", ""));

  let credits;

  if (name && org) {
    if (name.toLowerCase() === org.toLowerCase()) {
      credits = `(${name})`;
    } else if (org.match(/for the washington post/i)) {
      credits = `(Photo by ${name} for the Washington Post)`;
    } else {
      credits = `(${name}/${org})`;
    }
  } else if (name || org) {
    credits = `(${name}${org})`;
  }

  if (credits || rawCaption) {
    return {
      credits_display: credits || undefined,
      credits_caption_display: credits || undefined,
      dangerous: rawCaption
        ? {
            raw_caption: rawCaption
          }
        : undefined
    };
  }

  return undefined;
}
