// To alter the mobile presets, update the imported file below.

// NOTE: Mobile presets are different than the normal presets
// (regular presets and the mini-preset) in that they are used
// by the components powering the flex feature.

import mobilePresets from "../components/features/fronts/flex-feature/utilities/mobile-presets";

const mobilePresetOptions = Object.keys(mobilePresets);

export default mobilePresetOptions;
