import React from "react";
import PropTypes from "prop-types";
import { InputSearchRoot, InputSearchInput } from "@washingtonpost/wpds-ui-kit";

/*
 * NOTE: Only supports a single field with type="search"
 * + any number of hidden fields
 */
const Form = ({ method = "GET", action, fields, linkDetail }) => {
  // NOTE: return null if there isn't good form data
  if (!method || !action || !fields?.length) return null;

  const { type, param, placeholder } =
    fields.find((field) => field.type === "search") || {};

  // NOTE: return null if there isn't good field data
  if (type !== "search" || !param) return null;

  const hiddenFields = fields.filter((field) => field.type === "hidden");

  const attrs = linkDetail ? { "data-link-detail": linkDetail } : {};

  return (
    <form role="search" method={method} action={action} {...attrs}>
      <InputSearchRoot aria-label={placeholder}>
        <InputSearchInput
          label={placeholder}
          type={type}
          id={param}
          name={param}
          buttonIconType="submit"
        />
      </InputSearchRoot>
      {hiddenFields.map(({ param: name, value }, i) => {
        return (
          <input
            key={`${name}_${i}`}
            type="hidden"
            data-testid={name}
            id={name}
            name={name}
            value={value}
          />
        );
      })}
    </form>
  );
};

Form.propTypes = {
  method: PropTypes.string,
  action: PropTypes.string,
  fields: PropTypes.array,
  linkDetail: PropTypes.string
};

export default Form;
