import PropTypes from "@arc-fusion/prop-types";

// NOTE: in-table-ad and in-line-brandconnect-ad are almost exactly the same. They differ
// in two ways:
//
//  1. They have a different set of ads in the adType dropdown (bigbox v. brandconnect)
//  2. in-table-ad is safelisted for jsonapp while in-line-brandconnect is not.
//
// Once the apps support the brandconnect ad, the brandconnect ad types can be included in
// in-table-ad and in-line-brandconnect can be deleted. Of course, any brandconnect features
// will need to be swapped out for the in-table-ad feature with the correct settings.

import { brandConnectAdTypes, sharedProps } from "~/proptypes/zeus";

export const defaultAdType = "bs_card_front";

const adTypes = {
  ...brandConnectAdTypes
};

export const BrandConnectAdPropTypes = {
  customFields: PropTypes.shape({
    adType: PropTypes.oneOf(Object.keys(adTypes)).tag({
      labels: { ...adTypes },
      defaultValue: defaultAdType
    })
  }),
  ...sharedProps
};
