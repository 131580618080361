import {
  nileEnv,
  vercelEnv,
  assetPrefix
} from "../../spartan-homepage/assmblrEnv";

export default function getEnv() {
  const ENVIRONMENT = nileEnv;

  if (
    !ENVIRONMENT ||
    ENVIRONMENT.indexOf("dev") > -1 ||
    ENVIRONMENT.indexOf("localhost") > -1
  ) {
    return "dev";
  }

  if (ENVIRONMENT.indexOf("local") > -1) {
    return "local";
  }

  if (ENVIRONMENT.indexOf("sandbox") > -1) {
    return "sandbox";
  }
  return "prod";
}

export function isProd() {
  return getEnv() === "prod";
}

export function getIsVercel() {
  return vercelEnv === "true" || vercelEnv === true;
}

export function getAssetPrefix() {
  return assetPrefix || "";
}
