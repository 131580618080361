import get from "lodash.get";

/**
 * Receives an ANS content object and creates a formatted audio object
 * of the form:
 *
 * {
 *    id
 * }
 *
 * Sadly, the underlying prism audio object does not explicitly contain the lionfish id
 * so this code parses it from another field, ans.source.edit_url, which
 * has the form:
 *   http://washpost.arcpublishing.com/lionfish/index.html#/podcast/edit/5efe359acff47e00094aa32f
 * ...where the bit after the last slash is the lionfish id
 */
export default function createAudio(ans) {
  const system = get(ans, "source.system", undefined);
  if (system && system !== null && system.match(/lionfish/i)) {
    let id = get(ans, "source.edit_url", undefined);
    if (
      id &&
      id !== null &&
      typeof id === "string" &&
      id.lastIndexOf("/") >= 0
    ) {
      id = id.substring(id.lastIndexOf("/") + 1);
      return {
        id
      };
    }
  }
  return undefined;
}
