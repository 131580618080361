import PropTypes from "prop-types";

/**
 * Check if the url is an html file
 * @param {string} string to check
 * @return {boolean} returns true if string matches an HTML file pattern
 */
export const isHTMLFile = (url) => /^https?:\/\/.*\.html?$/i.test(url);

/**
 * Check if a string is a fragment of html
 * @param {string} string to check
 * @return {boolean} returns true if string matches an html tag
 */
export const isHTMLFragment = (fragment) => /<[a-z]+[^>]*\/?>/i.test(fragment);

export const getCustomHTML = ({ customHTML, overrides = {}, isAdmin }) => {
  const isFragment = isHTMLFragment(customHTML);
  const isFile = isHTMLFile(customHTML);

  if (!isFragment && !isFile) return undefined;

  const { coverArtUrl } = overrides;

  const warning =
    isAdmin && !coverArtUrl
      ? "Enter a Cover Art URL, otherwise no art (or the wrong art) will appear on the Apps."
      : undefined;

  return {
    html: customHTML,
    warning,
    isAdmin
  };
};

getCustomHTML.propTypes = {
  customHTML: PropTypes.string,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};
