/**
 * @func hasLocalStorage
 * @return {Object|false} localStorage if available otherwise false
 */
export function hasLocalStorage() {
  try {
    const uid = new Date().toString();
    window.localStorage.setItem(uid, uid);
    const hasWriteableStorage = window.localStorage.getItem(uid) === uid;
    window.localStorage.removeItem(uid);
    return hasWriteableStorage;
  } catch (e) {
    return false;
  }
}

/**
 * @func hasSessionStorage
 * @return {Object|false} sessionStorage if available otherwise false
 */
export function hasSessionStorage() {
  try {
    const uid = new Date().toString();
    window.sessionStorage.setItem(uid, uid);
    const hasWriteableStorage = window.sessionStorage.getItem(uid) === uid;
    window.sessionStorage.removeItem(uid);
    return hasWriteableStorage;
  } catch (e) {
    return false;
  }
}
