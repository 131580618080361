import React from "react";
import Lazy from "lazy-child";
import PropTypes from "prop-types";

const WapoLazyChild = ({
  eager = false,
  children,
  renderPlaceholder = (ref) => <div ref={ref} />,
  offsetTop = 300,
  offsetBottom = 300,
  ...rest
}) => {
  return eager ? (
    children
  ) : (
    <Lazy
      renderPlaceholder={renderPlaceholder}
      offsetTop={offsetTop}
      offsetBottom={offsetBottom}
      {...rest}
    >
      {children}
    </Lazy>
  );
};

export default WapoLazyChild;

WapoLazyChild.propTypes = {
  renderPlaceholder: PropTypes.func,
  eager: PropTypes.bool,
  offsetTop: PropTypes.number,
  offsetBottom: PropTypes.number,
  throttle: PropTypes.number,
  children: PropTypes.node
};
