import React from "react";
import PropTypes from "prop-types";

import { GlobalContextProvider } from "@washingtonpost/carta-newsletters";
import getEnv from "~/components/utilities/env";

const apiConfig = {
  clientApp: "homepage",
  env: ["local", "dev", "sandbox"].includes(getEnv()) ? "sandbox" : "prod"
};

export function NewsletterSignupProvider({ children }) {
  return (
    <GlobalContextProvider
      apiConfig={apiConfig}
      /**
       * Don't currently need user-specific enrollment data on page load.
       * This prevents GlobalContextProvider from trying to look up enrollments by itself,
       * which we don't want to have happen automatically on every page.
       * */
      initialEnrollments={[]}
    >
      {children}
    </GlobalContextProvider>
  );
}
NewsletterSignupProvider.propTypes = { children: PropTypes.node };
