import React from "react";
import PropTypes from "prop-types";

/* Utilities */
import { getClasses } from "@washingtonpost/front-end-utils";
import { theme, Icon } from "@washingtonpost/wpds-ui-kit";
import ArrowRight from "@washingtonpost/wpds-assets/asset/arrow-right";
import Camera from "@washingtonpost/wpds-assets/asset/camera";
import Chart from "@washingtonpost/wpds-assets/asset/chart";
import ChevronRight from "@washingtonpost/wpds-assets/asset/chevron-right";
import Headphones from "@washingtonpost/wpds-assets/asset/headphones";
import Play from "@washingtonpost/wpds-assets/asset/play";
import WpMarkWhite from "@washingtonpost/wpds-assets/asset/wp-mark-white";
import Waveform from "@washingtonpost/wpds-assets/asset/soundwave";

// NOTE: Put every imported icon here
const iconMap = {
  ArrowRight,
  Camera,
  Chart,
  ChevronRight,
  Headphones,
  Play,
  WpMarkWhite,
  Waveform
};

const pxToWpdsSize = (size) => `$${Number.parseInt((100 * size) / 16, 10)}`;

export const ICON_SIZES = {
  tiny: "16",
  small: "16",
  medium: "16",
  normal: "24",
  large: "24",
  "x-large": "24"
};

export const icons = {
  Chevron: {
    key: "chevron-right",
    componentKey: "ChevronRight",
    size: {
      tiny: "16",
      small: "16",
      medium: "16",
      normal: "16",
      large: "16",
      "x-large": "16"
    }
  },
  Arrow: {
    key: "arrow-right",
    componentKey: "ArrowRight",
    size: {
      tiny: "16",
      small: "16",
      medium: "16",
      normal: "16",
      large: "16",
      "x-large": "16"
    }
  },
  Camera: {
    key: "photo-solid",
    componentKey: "Camera",
    size: ICON_SIZES
  },
  Chart: {
    key: "chart",
    componentKey: "Chart",
    size: ICON_SIZES
  },
  Headphones: {
    key: "listen-solid",
    componentKey: "Headphones",
    size: ICON_SIZES
  },
  Play: {
    key: "play",
    componentKey: "Play",
    size: ICON_SIZES
  },
  WPLogo: {
    key: "wp",
    componentKey: "WpMarkWhite",
    size: ICON_SIZES
  },
  Waveform: {
    key: "waveform",
    componentKey: "Waveform",
    size: ICON_SIZES
  }
};

/*
 * NOTE: Makes an object like this
 * {
 *   [iconName]: {
 *     [size]: component
 *   }
 * }
 */
export const iconsComponents = Object.keys(icons).reduce((acc, key) => {
  const icon = icons[key];
  acc[key] = Object.keys(icon.size).reduce((sacc, size) => {
    const componentName = icon.componentKey;
    sacc[size] = iconMap[componentName]
      ? iconMap[componentName]
      : React.Fragment;
    return sacc;
  }, {});
  return acc;
}, {});

const hoverFillColors = {
  red: "hover-fill-red-dark",
  black: "hover-fill-gray-dark",
  offblack: "hover-fill-gray-dark",
  "gray-darkest": "hover-fill-gray-dark",
  "gray-dark": "hover-fill-gray-dark",
  gray: "hover-fill-gray"
};
const scToWpdsColorMap = {
  black: theme.colors.gray0,
  offblack: theme.colors.gray20,
  "gray-darkest": theme.colors.gray40,
  "gray-dark": theme.colors.gray80,
  gray: theme.colors.gray100,
  red: theme.colors.red100,
  "red-dark": theme.colors.red80,
  white: theme.colors.gray600
};

export const getIcon = (props) => {
  const {
    name,
    size,
    classes,
    style,
    fill,
    iconClasses,
    nextToPill,
    isLinked,
    suppressHoverState = false,
    atEnd = false
  } = props;
  const icon = icons[name];
  const iconComponent = name !== "None" && iconsComponents[name];

  if (!icon) return null;
  if (!iconComponent) return null;

  const InnerIcon = iconComponent[size];
  const iconSize = icon.size[size] || 16;
  const wpdsIconSize = icon.componentKey ? pxToWpdsSize(iconSize) : undefined;

  const marginFactor = size === "medium" ? 1.5 : 1.25;
  const marginRight = !atEnd
    ? { marginRight: `${marginFactor * iconSize}px` }
    : {};

  let marginLeft = {};
  if (atEnd) marginLeft = size !== "tiny" ? { marginLeft: "0.125rem" } : {};

  const wrapperStyle = { ...marginRight, ...marginLeft };

  return (
    <span className="relative" style={wrapperStyle}>
      <span className={classes} style={style}>
        <Icon
          fill={scToWpdsColorMap[fill]}
          size={wpdsIconSize}
          width={iconSize}
          height={iconSize}
          className={getClasses(iconClasses, {
            [`hover-inherit ${hoverFillColors[fill]}`]:
              !suppressHoverState &&
              isLinked &&
              !nextToPill &&
              !!hoverFillColors[fill],
            "hover-fill-gray-dark hover-inherit":
              !suppressHoverState && isLinked && nextToPill
          })}
        >
          <InnerIcon />
        </Icon>
      </span>
    </span>
  );
};

getIcon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  classes: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
  iconClasses: PropTypes.string,
  nextToPill: PropTypes.boolean,
  isLinked: PropTypes.boolean,
  atEnd: PropTypes.boolean
};
