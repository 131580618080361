import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const SandwichLayoutContext = createContext({});

export function SandwichLayoutProvider({ value, children }) {
  return (
    <SandwichLayoutContext.Provider value={value}>
      {children}
    </SandwichLayoutContext.Provider>
  );
}

SandwichLayoutProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node
};

export function useSandwichLayoutContext() {
  return useContext(SandwichLayoutContext);
}
