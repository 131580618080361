import PropTypes from "prop-types";

export const defaults = {
  rowSpan: 1,
  hideOnDesktop: false,
  mobilePreset: "As is (but not hidden)",
  hideFeatureFromWeb: false,
  hideFeatureFromApps: false,
  labelType: "Package",
  label: "",
  labelUrl: "",
  ctaLabelType: "CTA",
  ctaLabel: "",
  ctaLabelUrl: ""
};

export const InTableHabitTilesPropTypes = {
  customFields: PropTypes.shape({
    rowSpan: PropTypes.number.tag({
      name: "Row Spans",
      description:
        "If there is too much white space use this to adjust row height",
      defaultValue: defaults.rowSpan,
      hidden: true
    }),

    // START: hide/show
    hideOnDesktop: PropTypes.bool.tag({
      group: "OVERALL STYLES",
      name: "Hide feature on desktop",
      description:
        "Check this to hide this feature at desktop breakpoints (exact breakpoints depend on chain layout).",
      defaultValue: defaults.hideOnDesktop,
      hidden: true
    }),
    mobilePreset: PropTypes.string.tag({
      name: "Mobile preset",
      description: "Select the mobile rendering of this feature.",
      defaultValue: defaults.mobilePreset,
      hidden: true
    }),
    // START: hide from platform
    hideFeatureFromWeb: PropTypes.bool.tag({
      group: "OVERALL STYLES",
      name: "Hide feature on web",
      description: "Check this to hide this feature from the web rendering.",
      defaultValue: defaults.hideFeatureFromWeb,
      hidden: false
    }),
    hideFeatureFromApps: PropTypes.bool.tag({
      group: "OVERALL STYLES",
      name: "Hide feature on apps",
      description: "Check this to hide this feature from the app rendering.",
      defaultValue: defaults.hideFeatureFromApps,
      hidden: false
    }),
    // END: hide from platform
    // END: hide/show

    // START: LABEL
    label: PropTypes.string.tag({
      group: "LABEL",
      name: "Text",
      description: "The label text. Optional.",
      defaultValue: defaults.labelUrl
    }),
    labelUrl: PropTypes.string.tag({
      group: "LABEL",
      name: "Link",
      description: "The label link.",
      defaultValue: ""
    }),
    // END: LABEL

    // START: CTA
    ctaLabel: PropTypes.string.tag({
      group: "Call To Action",
      name: "Text",
      defaultValue: defaults.ctaLabel
    }),
    ctaLabelUrl: PropTypes.string.tag({
      group: "Call To Action",
      name: "Link",
      defaultValue: defaults.ctaLabelUrl
    })
    // END: CTA
  })
};
