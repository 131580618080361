import get from "lodash.get";
import { transform as transformContent } from "./transform-content";

// NOTE: If content_elements, rename items to match prism queries
const transformPrismQuery = (data) => {
  if (get(data, "content_elements")) {
    data.items = data.content_elements;
    delete data.content_elements;
  }
  return data;
};

const createCountInfo = (data) => {
  const items = get(data, "items");

  if (items) {
    items.map((v, i) => {
      v.fusion_additions = {
        ...get(v, "fusion_additions", {}),
        count_info: {
          count: i + 1
        }
      };
      return v;
    });
  }

  return data;
};

export const transform = (data) => {
  data = transformPrismQuery(data);
  data = createCountInfo(data);
  data = transformContent(data);
  return data;
};
