import get from "lodash.get";
import React from "react";
import { capitalizeFirstLetter } from "./string-helper";
import getResizedUrl, { PRESETS } from "../core/elements/image/resize-url";

/**
 * Get the credits
 *
 * @param {object} content The global content
 * @returns {array} The credits array
 */
export function getCredits(content) {
  return get(content, "credits.by", []);
}

/**
 * Get the current section name
 *
 * @param {object} content The global content
 * @returns {string} The current section
 */
export function getSection(content) {
  return get(
    content,
    "taxonomy.primary_section.name",
    get(content, "taxonomy.primary_site.name", "")
  );
}

/**
 * Get the current section id
 *
 * @param {object} content The global content
 * @returns {string} The current section id
 */
export function getSectionId(content) {
  return get(
    content,
    "taxonomy.primary_section._id",
    get(content, "taxonomy.primary_site._id", "")
  );
}

/**
 * Get the parent section, if you need to know the top-level section.
 *
 * @param {object} content The global content
 * @returns {string} The current parent section ID (ex: /world)
 */
export function getParentSectionId(content) {
  return get(
    content,
    "taxonomy.primary_site._id",
    get(
      content,
      "taxonomy.primary_site.additional_properties.original.ancestors[0]",
      ""
    )
  );
}

function isTopLevelSection(content) {
  const primarySection = get(content, "taxonomy.primary_section", undefined);
  const primarySite = get(content, "taxonomy.primary_site", undefined);

  if (primarySection) {
    const parentDefault = get(primarySection, "parent.default", "");
    if (parentDefault === "/") return true;
  }

  if (primarySite) {
    const parentId = get(primarySite, "parent_id", "");
    if (parentId === "/") return true;
  }

  return false;
}

function stripSlashAndCapitalize(string) {
  return capitalizeFirstLetter(string.replace("/", ""));
}

/**
 * Get the parent section, if you need to know the top-level section.
 *
 * @param {object} content The global content
 * @returns {string} The current parent section ID (ex: /world)
 */
export function getParentSectionName(content) {
  const topLevel = isTopLevelSection(content);
  let parentSectionNameFromPrimarySection = "";
  let parentSectionNameFromPrimarySite = "";

  if (topLevel) {
    parentSectionNameFromPrimarySection = get(
      content,
      "taxonomy.primary_section.name",
      undefined
    );
    parentSectionNameFromPrimarySite = get(
      content,
      "taxonomy.primary_site.name",
      undefined
    );
  } else {
    parentSectionNameFromPrimarySection = stripSlashAndCapitalize(
      get(
        content,
        "taxonomy.primary_section.additional_properties.original.ancestors[0]",
        ""
      )
    );
    parentSectionNameFromPrimarySite = stripSlashAndCapitalize(
      get(
        content,
        "taxonomy.primary_site.additional_properties.original.ancestors[0]",
        ""
      )
    );
  }

  return {
    parentSectionNameFromPrimarySection,
    parentSectionNameFromPrimarySite
  };

  // const parentSectionName = get(
  //   content,
  //   "taxonomy.primary_section.parent.name",
  //   get(
  //     content,
  //     "taxonomy.primary_site.additional_properties.original.ancestors[0]",
  //     ""
  //   )
  // );
}

/**
 * Get the current section path
 *
 * @param {object} content The global content
 * @returns {string} The current section
 */
export function getSectionPath(content) {
  return get(
    content,
    "taxonomy.primary_section.path",
    get(content, "taxonomy.primary_site.path", "")
  );
}

/**
 * Get the article taxonomy tags
 *
 * @param {object} content The global content
 * @returns {string} list of taxonomy tags
 */
export function getTaxonomyTags(content) {
  return get(content, "taxonomy.tags", [])
    .map((tag) => {
      return tag.text;
    })
    .join(",");
}

/**
 * Get the article subscriber content tags (sct)
 *
 * @param {object} content The global content
 * @returns {string} the subscriber content tags (sct tags)
 */
export function getSubscriberContentTags(content) {
  return get(content, "content_restrictions.content_code", "default");
}

/**
 * Get the article canonical URL
 *
 * @param {string} domain The domain
 * @param {object} content The global content
 * @returns {string} The article canonical URL
 */
export function getCanonicalUrl(domain, content) {
  return domain && content ? domain + content.canonical_url : "";
}

/**
 * Get the article headline
 *
 * @param {object} content The global content
 * @returns {string} The headline
 */
export function getHeadline(content) {
  return get(content, "headlines.basic", "");
}

/**
 * Get the article subheadline
 *
 * @param {object} content The global content
 * @returns {string} The headline
 */
export function getSubheadline(content) {
  return get(content, "subheadlines.basic", "");
}

/**
 * Get the article meta headline, default to basic headline
 *
 * @param {object} content The global content
 * @returns {string} The meta headline
 */
export function getMetaHeadline(content) {
  return get(
    content,
    "headlines.meta_title",
    get(content, "headlines.basic", "")
  );
}

/**
 * Get the article display date
 *
 * @param {object} content The global content
 * @returns {string} The article display date
 */
export function getDisplayDate(content) {
  return get(content, "display_date", "");
}

/**
 * Get the article modified date
 *
 * @param {object} content The global content
 * @returns {string} The article modified date
 */
export function getModifiedDate(content) {
  return get(content, "last_updated_date", "");
}

/**
 * Get the article description
 *
 * @param {object} content The global content
 * @returns {string} The article description
 */
export function getPublishedDate(content) {
  return get(content, "first_publish_date", "");
}

/**
 * Get the article description
 *
 * @param {object} content The global content
 * @returns {string} The article description
 */
export function getDescription(content) {
  return get(content, "description.basic", "");
}

/**
 * Get the author's role
 *
 * @param {object} author The author instance
 * @returns {string} The author's role
 */
export function getRole(author) {
  return get(author, "additional_properties.original.role", "");
}

/**
 * Get the author's area of expertise text
 *
 * @param {object} author The author instance
 * @returns {string} The author's area of expertise text
 */
export function getAreaOfExpertise(author) {
  return get(author, "additional_properties.original.expertise", "");
}

/**
 * Get the author's bio text
 *
 * @param {object} author The author instance
 * @returns {string} The author's bio text
 */
export function getBio(author) {
  return get(author, "additional_properties.original.bio", "");
}

/**
 * Get the article's transparency label
 *
 * @param {object} content The global content
 * @returns {string} The article's transparency label
 */
export function getTransparencyLabel(content) {
  return get(content, "label.transparency.text", "");
}

/**
 * Get the author's image url
 *
 * @param {object} author The author instance
 * @returns {string} The author's image url
 */
export function getAuthorImageUrl(author) {
  const originalUrl = get(author, "image.url", "");
  if (!originalUrl) {
    return "";
  }
  return originalUrl;
}

/**
 * Get the article's promo image url
 *
 * @param {object} content The global content
 * @param {string} fallback The fallback image if no promo is found
 * @param {object} presets The image resize parameters
 * @returns {string} The article's image url
 */
export function getPromoImage(content, fallback, presets = PRESETS["1200"]) {
  const promoImg = get(content, "promo_items.basic.url");
  const resizedPromo = promoImg ? getResizedUrl(promoImg, presets) : null;
  return (
    resizedPromo ||
    fallback ||
    "https://www.washingtonpost.com/resizer/2CjPNwqvXHPS_2RpuRTKY-p3eVo=/1484x0/www.washingtonpost.com/pb/resources/img/twp-social-share.png"
  );
}

/**
 * Get the author's description and strip HTML out
 *
 * @returns {string} sanitized author description
 */

export function cleanAuthorBio({ description = "" }) {
  return description ? description.replace(/<[^]*?>/gi, "") : "";
}

/**
 * Wrap amp features with a section tag designating it as protected content
 *
 * @param {boolean} condition determines if wrapping content wrapper or not
 * @param {object} content The global content
 * @returns {string} content wrapped as a content section for amp
 */
export function ampContentWrapper(condition, content) {
  if (condition) {
    // eslint-disable-next-line react/no-unknown-property
    return <section subscriptions-section="content">{content}</section>;
  }
  return content;
}

/**
 * Get the author's byline text
 *
 * @param {object} author The author instance
 * @returns {string} the author byline text
 */

export function getAuthorByline(author) {
  return get(
    author,
    "additional_properties.original.byline",
    get(author, "credits.by")
  );
}

/**
 * Get the list of article keywords
 *
 * @param {object} content The global content
 * @returns {array} The article keywords
 */
export function getKeywords(content) {
  return get(content, "taxonomy.keywords", []).map((item) => item.keyword);
}

/**
 * Get the list of article keywords
 *
 * @param {object} content The global content
 * @returns {array} The article keywords
 */
export function getSeoKeywords(content) {
  return get(content, "taxonomy.seo_keywords", []);
}

/**
 * Get the tracking section
 *
 * @param {object} content The global content
 * @returns {string} The tracking section
 */
export function getTrackingSection(content) {
  return get(content, "tracking.section", "");
}

/**
 * Get the subType
 *
 * @param {object} content The global content
 * @returns {string} The subType
 */
export function getSubType(content) {
  return get(content, "subType", "");
}

/**
 * Get the commercial node
 *
 * @param {object} content The global content
 * @returns {string} The commercial node
 */
export function getCommercialNode(content) {
  return get(content, "tracking.commercial_node", "");
}

/**
 * Get the content type
 *
 * @param {object} content The global content
 * @returns {string} The content type
 */
export function getContentType(content) {
  return get(content, "fusion_additions.tracking.content_type", "");
}

/**
 * Get the tracking subsection
 *
 * @param {object} content The global content
 * @returns {string} The tracking subsection
 */
export function getTrackingSubSection(content) {
  return get(content, "tracking.subsection", "");
}

/**
 * Get language
 *
 * @param {object} content The global content
 * @returns {string} Direction setting
 */
export function getLanguageDirection(content) {
  const rtl = ["ar", "fa"];
  const lang = get(content, "language", "");
  return rtl.includes(lang) ? "rtl" : null;
}

/**
 * Get the language
 *
 * @param {object} content The global content
 * @returns {string} The language
 */
export function getLanguage(content) {
  return get(content, "language", "en");
}

/**
 * Get the auxilaries
 *
 * @param {object} content The global content
 * @returns {array} The auxiliaries list
 */
export function getAuxilaries(content) {
  return get(content, "taxonomy.auxiliaries", []);
}

/**
 * Get the magnets text - some tags come comma delimited
 *
 * @param {object} content The global content
 * @returns {array} The auxiliaries list
 */
export function getMagnetText(content) {
  const text = get(
    get(content, "taxonomy.tags", []).find((t) => /magnet-/.test(t.text)),
    "text",
    ""
  );
  const magnetOnlyArray = text
    .split(",")
    .filter((tag) => tag.indexOf("magnet") !== -1);
  return magnetOnlyArray && magnetOnlyArray.length > 0
    ? magnetOnlyArray[0].replace("magnet-", "")
    : "";
}

/**
 * Get related content
 *
 * @param {object} content The global content
 * @param {string} type (optional) The name of the property within related_content
 * @returns {array|object} The related content object or the array of related content the given type
 */
export function getRelatedContent(content, type = "") {
  const path = `related_content${type ? `.${type}` : ""}`;
  return get(content, path, []);
}

/**
 * Determine if the page is a "live" article
 *
 * @param {object} content The global content
 * @returns {boolean} Determines whether live or not
 */
export function isLiveArticle(content) {
  const subtype = get(content, "subtype", "");
  // TODO: WFO-1707: eventually remove check for highlights here
  return subtype === "highlights" || subtype === "live-all";
}

/**
 * Determine if a coronavirus free article tag
 * Check in taxonomy.tags and tags depending on CMS
 *
 * @param {object} content The global content
 * @returns {boolean} Determines whether coronavirus free article
 */
export function isCoronaFree(content) {
  const taxTags = get(content, "taxonomy.tags", []);
  const taxCoronaTag = taxTags.filter((tag) => tag.text === "coronavirus-free");

  const methodeTags = get(content, "tags", []);
  const methodeCoronaTag = methodeTags.filter(
    (tag) => tag.text === "coronavirus-free"
  );
  return taxCoronaTag.length > 0 || methodeCoronaTag.length > 0;
}

/**
 * Determine if a coronavirus article
 *
 * @param {object} content The global content
 * @returns {boolean} Determines whether coronavirus article
 */
export function isCoronavirus(content) {
  const taxTags = get(content, "taxonomy.tags", []);
  const taxCoronaTag = taxTags.filter(
    (tag) => tag.text === "magnet-coronavirus"
  );
  return taxCoronaTag.length > 0;
}

/**
 * Determine if article falls within elections section. Checks all sections.
 *
 * @param {object} content The global content
 * @returns {boolean} Determines whether elections article
 */
export function getIsElections(content) {
  return !!(get(content, "taxonomy.sections", []) || []).find(
    ({ _id: id }) => id && id === "/elections"
  );
}

/**
 * Return value in content data for `secondary_nav_alternate_section_to_use`
 *
 * @param {object} content The global content
 * @return {string | null}
 */
export function getSecondaryNavOverride(content) {
  return (
    get(
      content,
      "taxonomy.primary_site.additional_properties.original.site_topper.secondary_nav_alternate_section_to_use",
      null
    ) || null
  );
}

/**
 * Returns whether or not this is within the Made By History section
 *
 * @param {object} content The global content
 * @return {boolean} True / False whether inside Made by History
 */
export function isMadeByHistorySection(content) {
  return getSectionId(content).toLowerCase() === "/opinions/made-by-history";
}

/**
 * Returns whether or not this is an opinion piece
 *
 * @param {object} content The global content
 * @return {boolean} True / False whether opinion piece
 */
export function isOpinion(content) {
  return getTransparencyLabel(content).toLowerCase() === "opinion";
}

/**
 * Determine the if an article is a recipe
 *
 * @param {object} content The global content
 * @returns {boolean} Determines whether article is a recipe
 */
export const isRecipe = (content = {}) =>
  get(content, "additional_properties.recipe_info", null);

/**
 * Checks WEATHER or not an article is a weather article (get it? 😁)
 *
 * @param {object} content The global content
 * @returns {boolean} article is weather
 */
export const isWeather = (content = {}) => {
  const sections = get(content, "taxonomy.sections", []);
  const weatherSections = sections.filter(
    (section) => section._id && section._id.includes("/local/weather")
  );
  return weatherSections.length > 0;
};
