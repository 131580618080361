import get from "lodash.get";

export const BACKUP_PROMO_IMAGE =
  "https://www.washingtonpost.com/wp-stat/spacer.gif";

export const formatDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor(totalSeconds / 60) - hours * 60;
  const seconds = Math.floor(totalSeconds % 60);

  const hourDisplay = hours ? `${hours}:` : "";
  const minuteDisplay = hours && minutes < 10 ? `0${minutes}:` : `${minutes}:`;
  const secondDisplay = seconds < 10 ? `0${seconds}` : seconds;
  return hourDisplay + minuteDisplay + secondDisplay;
};

export const getAspectRatio = (data) => {
  let { width, height } = get(data, "streams[0]", {});
  // NOTE: If either is missing, set to 16 by 9
  if (!width || !height) {
    width = 16;
    height = 9;
  }
  return parseFloat(width / height);
};

export const isGif = (url) => /\.gif($|\?|#)/i.test(url);

export const isMp4 = (url) => /\.mp4($|\?|#)/i.test(url);

const getVanityCredits = (data) => {
  const credits = [
    get(data, "vanity_credits.by[0].name"),
    get(data, "vanity_credits.affiliation[0].name")
  ].filter(Boolean);
  if (credits.length) return credits.join("/");
  return undefined;
};

export const generateVideoCaption = (data) => {
  const vanityCredits = getVanityCredits(data);
  if (vanityCredits) return vanityCredits;

  const authors = get(data, "credits.by", []);
  let source = get(data, "source.name", "");

  const photog = get(data, "promo_image.credits.by[0].name", "");

  const myEditors = [];

  authors.forEach((by) => {
    if (by.name && !myEditors.includes(by.name)) myEditors.push(by.name);
    if (by.org && !source) source = by.org;
  });

  const getPhotogPrefix = () => {
    if (myEditors.length > 0 && photog) return "/Photo: ";
    if (photog) return "Photo: ";
    return "";
  };

  const editorPrefix = myEditors.length > 0 && photog ? "Video: " : "";
  const photogPrefix = getPhotogPrefix(); // in case we have photog but not editor, don't want to show photog as editor

  let credits = `${editorPrefix}${myEditors.join(
    ", "
  )}${photogPrefix}${photog}`;

  if (source) {
    if (credits.length > 0) credits += "/";
    credits += `${source}`;
  }
  return credits;
};

export const getResizedImage = (urls, width = 800) => {
  if (!urls || urls.length === 0) {
    return null;
  }

  // pick the next-biggest image in resizedImages
  // discard all images that are smaller, then pick the smallest of the remaining bunch
  const lookUp = () => {
    const myUrls = urls.filter((url) => url.width >= width);
    if (myUrls.length > 0) {
      return myUrls.reduce((acc, cur) => {
        return acc.width < cur.width ? acc : cur;
      });
    }
    return null;
  };

  // pick the next-smallest image in resizedImages
  // discard all images that are bigger, then pick the biggest of the remaining bunch
  const lookDown = () => {
    const myUrls = urls.filter((url) => url.width < width);
    if (myUrls.length > 0) {
      return myUrls.reduce((acc, cur) => {
        return acc.width > cur.width ? acc : cur;
      });
    }
    return null;
  };

  let resized = lookUp();
  if (resized === null) {
    resized = lookDown();
  }
  return get(resized, "url", null);
};
