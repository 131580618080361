export const RESIZER_PROXY = "https://www.washingtonpost.com/wp-apps/imrs.php";
const ALREADY_RESIZED_RE = new RegExp(
  `${RESIZER_PROXY}.*(?:\\?|&)src=([^&]+)`,
  "g"
);

/**
 * Image presets
 * Note: width and height are w and h respectively
 */
export const PRESETS = {
  lowResBody: { w: 150 },
  small: { w: 767 },
  medium: { w: 1023 },
  large: { w: 1440 },

  // @TODO - replace these specific dimensions with the small/large below
  bylineThumbnail: { w: 70, h: 70 },
  tooltipThumbnail: { w: 90, h: 90 },
  btwThumbnail: { w: 120, h: 120 },

  smallThumbnail: { w: 70, h: 70 },
  mediumSmallThumbnail: { w: 75, h: 75 },
  mediumThumbnail: { w: 80, h: 80 },
  largeThumbnail: { w: 90, h: 90 },
  testThumbnail: { w: 80, h: 53 },

  // the following presets are for article images
  blurThatThumbnail: { w: 32 },
  440: { w: 440 },
  530: { w: 530 },
  691: { w: 691 },
  860: { w: 860 },
  916: { w: 916 },
  960: { w: 960 },
  1200: { w: 1200 }
};

/**
 * Exports HP prests, here for now until I can get it in a better place
 */
export const HP_PRESETS = {
  large: { w: 1028 },
  medium: { w: 960 },
  small: { w: 640 },
  xSmall: { w: 480 },
  xxSmall: { w: 320 },
  thumbnail: { w: 100 }
};
/**
 * Constructs a resized url using an image proxy
 *
 * @param {string} url - the original image src url
 * @param {object} params - desired width and height
 * @returns {string} resizedUrl - new image path resized through the proxy
 */
const getResizedUrl = (url, params = {}) => {
  if (!url) return url;
  // NOTE: Parse out src from an already-resized url
  if (ALREADY_RESIZED_RE.test(url)) {
    // url = RegExp.$1;
    url = decodeURIComponent(RegExp.$1);
  }
  // Need to manually encode periods so EZProxy does not find/replace the image urls.
  let resizerProxy = `${RESIZER_PROXY}?src=${encodeURIComponent(
    url
      .replace(
        "https://www.washingtonpost.com",
        "https://img.washingtonpost.com"
      )
      .replace(/\./g, "%2E")
  )}`;
  Object.keys(params).forEach((paramKey) => {
    resizerProxy = `${resizerProxy}&${paramKey}=${params[paramKey]}`;
  });
  return resizerProxy;
};

export default getResizedUrl;
