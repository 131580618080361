import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import { useBreakpoints } from "./BreakpointContext";
import { shouldUseDesktopOrdering } from "~/shared-components/chains/top-table/utilities/settings";

const defaultValue = {};

const ChainContext = createContext(defaultValue);

function ChainProvider({ value, children }) {
  return (
    <ChainContext.Provider value={value}>{children}</ChainContext.Provider>
  );
}

ChainProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.any
};

const { Consumer: ChainConsumer } = ChainContext;

function useChainContext() {
  const chainCtx = useContext(ChainContext);
  const breakpoints = useBreakpoints();

  chainCtx.useDesktopOrdering = shouldUseDesktopOrdering(
    chainCtx.layout,
    breakpoints.bp
  );

  return chainCtx;
}

export { useChainContext, ChainProvider, ChainConsumer };
