// import React, { createContext, useContext, useEffect } from "react";
import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useChainContext } from "~/shared-components/ChainContext";
import {
  getRenderedContentId,
  isDifferentListOfRenderedContent,
  updateRenderedContentItems
} from "./rendered-content-context.helpers";

const RenderedContentContext = createContext({});

function RenderedContentProvider({ children }) {
  /**
   * Provide information about the content rendered on the page, for the purposes of deduping.
   *
   * `renderedContent` is a map-like object of the type:
   *
   * {
   *   [link]: {
   *     featureIds: string[],
   *     chainDisplayNames: string[],
   *     canonical_url: string
   *   }
   * }
   *
   * The intention is that "all" rendered content will end up in here, via:
   * - StoryCards
   * - Carousels
   * Content like breaking news, newsletters, or anything else that doesn't use the above will not end up here.
   */
  const [renderedContent, setRenderedContent] = React.useState({});

  function updateRenderedContent(props) {
    setRenderedContent((existingContent) => {
      updateRenderedContentItems({
        renderedContent: existingContent,
        ...props
      });
      return existingContent;
    });
  }

  function useUpdateRenderedContentInChainCtx(props) {
    // eslint-disable-next-line react/prop-types
    const { id, content, overrides, curationIndices } = props;
    const chainOverrides = useChainContext();
    updateRenderedContent({
      items: [content],
      id,
      chainOverrides,
      overrides,
      curationIndices
    });
  }

  function getRenderedContent(filters) {
    return Object.entries(renderedContent).filter(([key]) => {
      return Object.entries(filters).some(([filterKey, filterValues]) => {
        return filterValues.some((filterValue) => {
          return renderedContent[key][filterKey]?.includes(filterValue);
        });
      });
    });
  }

  const UpdateRenderedContentInChainCtx = (props) => {
    // NOTE: For foryou exclusions and deduping
    useUpdateRenderedContentInChainCtx(props);
    return null;
  };

  return (
    <RenderedContentContext.Provider
      value={{
        renderedContent,
        getRenderedContentId,
        getRenderedContent,
        isDifferentListOfRenderedContent,
        updateRenderedContent,
        useUpdateRenderedContentInChainCtx,
        UpdateRenderedContentInChainCtx
      }}
    >
      {children}
    </RenderedContentContext.Provider>
  );
}

RenderedContentProvider.propTypes = {
  children: PropTypes.any
};

const { Consumer: RenderedContentConsumer } = RenderedContentContext;

function useRenderedContentContext() {
  return useContext(RenderedContentContext);
}

export {
  useRenderedContentContext,
  RenderedContentProvider,
  RenderedContentConsumer
};
