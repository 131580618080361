import get from "lodash.get";

/**
 *
 * @param {obj} data is of the form
 *
 * {
 *   topSections: null | [
 *     {
 *       rank: <integer>,
 *       score: <float>,
 *       section: <string like "/lifestyle">
 *     },
 *     ...
 *   ],
 *   ...rest,
 * }
 * @returns normalized array of the form
 *
 * [
 *   {
 *     rank: <integer>,
 *     score: <float>,
 *     section: <string like "lifestyle">
 *   },
 *   ...
 * ]
 */
const transformTopics = (data) => {
  return (get(data, "topSections", []) || []).map((s) => ({
    ...s,
    section: s.section?.startsWith("/") ? s.section.slice(1) : s.section
  }));
};

export const transform = (data) => {
  data = transformTopics(data);
  return data;
};
