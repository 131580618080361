import React from "react";

const items = [
  {
    type: "multi-button",
    title: "size",
    fields: ["hideFromDesktop", "hideFromMobile"],
    falseState: {
      title: "Show on desktop and mobile"
    },
    trueState: {
      title: "Hide on desktop and mobile"
    },
    dataQa: `overlay-show-hide-size-btn`
  },
  {
    type: "button",
    field: "hideFromDesktop",
    warnValue: true,
    falseState: {
      title: "Desktop: Shown",
      icon: "desktop"
    },
    trueState: {
      title: "Desktop: Hidden",
      icon: "desktopOff"
    },
    dataQa: `overlay-show-hide-desktop-btn`
  },
  {
    type: "button",
    field: "hideFromMobile",
    warnValue: true,
    falseState: {
      title: "Mobile: Shown",
      icon: "mobile"
    },
    trueState: {
      title: "Mobile: Hidden",
      icon: "mobileOff"
    },
    dataQa: `overlay-show-hide-mobile-btn`
  },
  {
    type: "multi-button",
    title: "platform",
    fields: ["hideFromWeb", "hideFromApps"],
    falseState: {
      title: "Show on web and apps"
    },
    trueState: {
      title: "Hide on web and apps"
    },
    dataQa: `overlay-show-hide-platform-btn`
  },
  {
    type: "button",
    field: "hideFromWeb",
    warnValue: true,
    falseState: {
      title: "Web: Shown",
      icon: "web"
    },
    trueState: {
      title: "Web: Hidden",
      icon: "visibilityOff"
    },
    dataQa: `overlay-show-hide-web-btn`
  },
  {
    type: "button",
    field: "hideFromApps",
    warnValue: true,
    falseState: {
      title: "Apps: Shown",
      icon: "apps"
    },
    trueState: {
      title: "Apps: Hidden",
      icon: "visibilityOff"
    },
    dataQa: `overlay-show-hide-apps-btn`
  }
];

const getItems = (customFields, getIconComponent, entity, setEntity) => {
  return items.reduce(
    (acc, { type, title, field, fields, trueState, falseState, dataQa }) => {
      if (type === "msg") {
        acc.push({ type, title, dataQa });
      } else if (type === "button") {
        const { title: hoverTitle, icon } = customFields[field]
          ? trueState
          : falseState;
        const IconComponent = getIconComponent(icon);
        acc.push({
          type,
          hoverTitle,
          dataQa,
          icon: <IconComponent fontSize="inherit" style={{ fill: "#fff" }} />,
          onClick: () => {
            customFields[field] = !customFields[field];
            // eslint-disable-next-line no-shadow
            setEntity((entity) => {
              entity.props.customFields = customFields;
            });
          }
        });
      } else if (type === "multi-button") {
        const valueToUse = fields.reduce(
          (acc2, f) => acc2 && !customFields[f],
          true
        );
        const { title: hoverTitle } = valueToUse ? trueState : falseState;
        acc.push({
          type,
          title,
          hoverTitle,
          dataQa,
          onClick: () => {
            fields.forEach((f) => {
              customFields[f] = valueToUse;
            });
            // eslint-disable-next-line no-shadow
            setEntity((entity) => {
              entity.props.customFields = customFields;
            });
          }
        });
      }
      return acc;
    },
    []
  );
};

const checkWarnings = (customFields) => {
  return items.reduce((acc, { type, field, warnValue }) => {
    if (type === "button") {
      acc = acc || !!customFields[field] === warnValue;
    }
    return acc;
  }, false);
};

export const getToolbarInfo = ({
  customFields,
  getIconComponent,
  entity,
  setEntity
}) => {
  return {
    items: getItems(customFields, getIconComponent, entity, setEntity),
    warn: checkWarnings(customFields)
  };
};
