import React, { useEffect } from "react";
import get from "lodash.get";
import { useContent } from "fusion-content";
import { useFusionContext } from "fusion-context";
import { getSlotConfig, getVideoData } from "./livebar-content-helper.js";

const defaultConfig = {
  ads: false,
  autoinit: true,
  className: "live-bar",
  loadByFeature: true,
  playthrough: false,
  showBlurb: false,
  showTitle: false,
  playerType: "LiveBar"
};

export const useLiveVideoData = (
  videoFieldsContent,
  videoConfig,
  primaryVideo = "Video 1"
) => {
  const { isAdmin } = useFusionContext();
  videoConfig = { ...defaultConfig, ...videoConfig };

  const { displayWithoutVideo, video1Url, video2Url, video3Url } =
    videoFieldsContent || {};

  // START: fetch video 1, 2, 3 data -- it would be nice if less defensive code were necessary, but this works
  const allVideoContentConfig = [
    getSlotConfig(video1Url),
    getSlotConfig(video2Url),
    getSlotConfig(video3Url)
  ];

  const videoSlot1Data = useContent(allVideoContentConfig[0]);
  const videoSlot2Data = useContent(allVideoContentConfig[1]);
  const videoSlot3Data = useContent(allVideoContentConfig[2]);
  // END: fetch video 1, 2, 3 data

  const video1VideoData = get(videoSlot1Data, "items[0]");
  const video2VideoData = get(videoSlot2Data, "items[0]");
  const video3VideoData = get(videoSlot3Data, "items[0]");

  const allVideoContentData = [
    video1VideoData,
    video2VideoData,
    video3VideoData
  ];

  // combine customField data and content
  const allVideoData = [
    getVideoData(video1VideoData, videoFieldsContent, 1, isAdmin),
    getVideoData(video2VideoData, videoFieldsContent, 2, isAdmin),
    getVideoData(video3VideoData, videoFieldsContent, 3, isAdmin)
  ];

  const primaryVideoIndex = Number((primaryVideo || "1").match(/\d/)[0]) - 1;
  const orderedVideoContentData = allVideoContentData;
  const orderedData = allVideoData;

  // if a custom primary video is selected shift so selected video is first index
  if (primaryVideo !== "Video 1") {
    orderedData.unshift(orderedData.splice(primaryVideoIndex, 1)[0]);
    orderedVideoContentData.unshift(
      orderedVideoContentData.splice(primaryVideoIndex, 1)[0]
    );
  }

  const mappedVideoData = orderedData.map((videoData) => {
    return {
      videoData,
      videoConfig,
      displayWithoutVideo
    };
  });

  const legitVids = !isAdmin
    ? mappedVideoData.filter((slot) => {
        const vidStatus = get(slot, "videoData.status", null);
        return slot.videoData && (!!vidStatus || vidStatus !== "ended");
      })
    : mappedVideoData.filter((slot) => slot.videoData);

  return {
    legitVids,
    listOfConfigs: isAdmin ? allVideoContentConfig : undefined,
    listOfData: isAdmin ? allVideoContentData : undefined
  };
};

export const useDismissIfEnded = (id, dismiss) => {
  const intervalId = React.useRef();

  useEffect(() => {
    const checkForEnd = () => {
      if (!id) return;
      fetch(
        `https://video-api.washingtonpost.com/api/v1/ansvideos/findByUuid?uuid=${id}&noRedirect=true`
      )
        .then((res) => res.json())
        .then((data) => {
          // if its ended we can stop polling
          if (data[0].status === "ended") {
            dismiss();
            clearInterval(intervalId.current);
          }
        });
    };

    intervalId.current = setInterval(checkForEnd, 60000);
    return () => clearInterval(intervalId.current);
  }, [dismiss, id]);

  return !intervalId.current;
};
