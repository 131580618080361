import miniPresets from "./mini-presets";
import mobilePresets from "./mobile-presets";

export function getPresetId({ renderable }) {
  return renderable.split("/").pop();
}

export const getCustomFields = ({ initialFields, values }) => {
  if (initialFields && Object.keys(initialFields).length !== 0) {
    const updatedCustomFields = { ...initialFields };
    Object.entries(values).forEach((entry) => {
      updatedCustomFields[entry[0]] = entry[1];
    });
    return updatedCustomFields;
  }
  return values;
};

/* NOTE: For now, filter of the "global" presets (designed for the flex feature) and apply
 * them to the features of interest. There is no need to add these four custom fields
 * to the features they apply. The hide/show presets are amenable to this b/c:
 *
 * 1. The mini-presets "Hide on desktop" and "Unhide on desktop" work cuz the chain handles them
 * 2. The mobile preset "HIDE ENTIRE FEATURE" works cuz the chain handles it
 * 3. The mobile preset "As is" works cuz it doesn't do anything and acts as a placeholder
 *
 * FURTHER NOTE: The hide/show paradigm only works on features in chains. This is unrelated
 * to this particular trick.
 *
 * @returns {object} -
 * {
 *   mini: the hide/unhide options
 *   mobile: As is, HIDE and do nothing options
 * }
 */
export const getHideAndShowPresets = () => ({
  mini: miniPresets.filter(({ name }) => /(un)?hide on desktop/i.test(name)),
  mobile: mobilePresets.filter((name) =>
    /^(As is( \(but not hidden\))?|HIDE ENTIRE FEATURE)$/i.test(name)
  )
});
