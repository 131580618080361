import React, { useEffect, useState, useRef } from "react";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

const MOBILE_AD_IMPRESSION_TIME = 5000;
const STICKY_BT_AD_ID = "slug_fixedBottom";
const REENGAGE_AD_ID = "slug_interstitial";
const SOFTWALL_ACTION = 6;

const postMessageToIframe = (message) => {
  const iframe = document.getElementById("wallIframe");
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(message, "*");
  } else {
    // for react softwall
    window.postMessage(message, "*");
  }
};

const useDetectRemovableAdsForSoftwall = () => {
  const [removableAds, setRemovableAds] = useState([]);
  const isWallClosedRef = useRef();
  const { isMobile } = useAssemblerContext();
  isWallClosedRef.current = false;

  // Listening softwall iframe's closed postMessage
  const closeMessageListener = (e) => {
    if (typeof e.data === "string" && e.data.indexOf("CloseRegwall") > -1) {
      isWallClosedRef.current = true;
    }
  };

  // some ads may take the same position where softwall needs to be rendered.
  // When softwall action fired(6), remove them after 5 sec(min. time that ads need to be displayed)
  const detectAfterImpressionAds = React.useCallback(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    const adSlots = [];
    const slotIds = [];
    let isBottomAdClosed = false;
    window.googletag.cmd.push(() => {
      window.googletag
        .pubads()
        .addEventListener("impressionViewable", (event) => {
          if (!isWallClosedRef.current) {
            const slotId = event.slot.getSlotElementId();
            const isBottomAd = slotId === STICKY_BT_AD_ID;
            const isReEngageAd = slotId === REENGAGE_AD_ID;
            if (
              (isBottomAd || isReEngageAd) &&
              slotIds.indexOf(slotId) === -1
            ) {
              adSlots.push(event.slot);
              slotIds.push(slotId.replace("slug_", ""));
            }
            if (isBottomAd) {
              setTimeout(() => {
                if (!isWallClosedRef.current) {
                  isBottomAdClosed = true;
                  window.googletag.destroySlots(adSlots);
                  setRemovableAds([...slotIds]);
                  // set softwall height
                  postMessageToIframe("getHeight");
                } else {
                  window.removeEventListener("message", closeMessageListener);
                }
              }, MOBILE_AD_IMPRESSION_TIME);
            } else if (isReEngageAd && isBottomAdClosed) {
              window.googletag.destroySlots([event.slot]);
              setRemovableAds([...slotIds]);
            }
          }
        });
    });
  }, []);

  useEffect(() => {
    if (!window.registerPwapiConsumer || !isMobile)
      return () => {
        window.removeEventListener("message", closeMessageListener);
      };
    window.addEventListener("message", closeMessageListener, false);

    const updateAction = (resp) => {
      if (resp.status !== "final") {
        return;
      }
      const action = resp?.data?.action;
      if (action === SOFTWALL_ACTION) {
        detectAfterImpressionAds();
      } else {
        window.removeEventListener("message", closeMessageListener);
      }
    };
    window.registerPwapiConsumer(updateAction);
    return () => {
      window.removeEventListener("message", closeMessageListener);
    };
  }, [isMobile, detectAfterImpressionAds]);

  return removableAds;
};

export default useDetectRemovableAdsForSoftwall;
