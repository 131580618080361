import get from "lodash.get";

/**
 * Receives an ANS content object and creates a live_ticker object for
 * use by features and components. Live blogs should contain content_elements
 * with entire stories in them (with subtype of 'live-update'. This code
 * harvests those and puts them in live_ticker. The story-card filter filters
 * out most of this data which isn't needed for feature rendering.

 */

const fixUrl = (url) => {
  return url && url.startsWith("/")
    ? `https://www.washingtonpost.com${url}`
    : url;
};

export default function createLiveTicker(ans) {
  if (!ans || !ans.content_elements) return undefined;

  const url = fixUrl(get(ans, "canonical_url"));

  const items = ans.content_elements.filter(
    (v) =>
      v.type === "story" &&
      get(v, "subtype", "").match(
        /live-(outcome|race-call|reporter-insight|update)/
      )
  );

  if (items.length) {
    items.forEach((v) => {
      v.canonical_url = `${url}#link-${v._id}`;
    });
    return { items };
  }

  return undefined;
}
