import PropTypes from "prop-types";
import get from "lodash.get";
import kebabcase from "lodash.kebabcase";

import {
  gridPixValues,
  HPBreakpoints
} from "~/components/layouts/utilities/grid-helpers";

import { isVideoUrl } from "~/shared-components/story-card/_children/Video.helpers";
import { getLink, getAspectRatio } from "../_utilities/helpers";
import { getAudio } from "./Audio.helpers";
import { getCaption } from "./Caption.helpers";
import { getCustomHTML, isHTMLFragment } from "./CustomHTML.helpers";
import {
  artBreakpointSizes,
  artSizeTranslator,
  THUMBNAIL_DESKTOP_WIDTH,
  THUMBNAIL_DESKTOP_KEY,
  THUMBNAIL_MOBILE_WIDTH,
  THUMBNAIL_MOBILE_KEY,
  MINI_DESKTOP_WIDTH,
  MINI_DESKTOP_KEY,
  MINI_MOBILE_WIDTH,
  MINI_MOBILE_KEY
} from "./Image.constants";
import { getBylineText } from "./Sigline.helpers";

const LARGEST_MOBILE_WIDTH = HPBreakpoints.sm;
const SMALLEST_IMAGE_WIDTH = MINI_DESKTOP_WIDTH;

// NOTE: Max width in cols and px for square and vertical aspect ratios
// Should match values in: ~/src/websites/washpost/fronts/story-card.scss
const MAX_WIDTH_SQUARE = 8 * 34 + 7 * 32;
const MAX_WIDTH_VERTICAL = 6 * 34 + 5 * 32;

/**
 * @param {string} url - The url to check
 * @returns {boolean} - Whether the url has any standard image extensions
 */
// TODO: Find a better home for this?
export const isImageUrl = (url) =>
  /^https?:\/\/.*\.(jpg|jpeg|gif|png|bmp|webp)$/i.test(url) ||
  /^https?:\/\/[^/]+anglerfish[^/]+\//i.test(url);

/**
 * Calculate the aspect ratio given a string formatted as width:height
 * @param  {String} [ratio="3:2"] The ratio as a string width:height
 * @return {Float} The width divided by the height
 */
export const calculateAspectRatio = (ratio = "3:2") => {
  let [width, height] = ratio.split(":");

  // make sure these are numbers base 10
  width = Number.parseFloat(width, 10);
  height = Number.parseFloat(height, 10);

  // calculate the aspect ratio
  return Number.parseFloat((width / height).toFixed(2));
};

/**
 * Returns the anglerfish ID from the image url
 * @param {string} url - an image url
 * @returns {string} if the url is from anglerfish, the unique anglerfish id is returned
 */
export function getAnglerfishImageId(url) {
  if (!url || typeof url !== "string" || url.indexOf("arc-anglerfish") === -1) {
    return "";
  }

  const parts = url.split("/");
  return parts[parts.length - 1].split(".")[0].replace(/_size-normalized/, "");
}

/**
 * Generates Anglerfish editor URL from image URL
 * @param {string} url - image url
 * @returns {string | null} returns url to Anglerfish editor if its a valid
 * Anglerfish image otherwise will return `null`.
 */
export function generateAnglerfishEditorUrl(url) {
  const anglerfishImageId = getAnglerfishImageId(url);

  if (anglerfishImageId !== "") {
    const baseUrl = "https://washpost.arcpublishing.com/photo/";
    return baseUrl + anglerfishImageId;
  }
  return null;
}

/**
 *
 * @param {*integer} cols - number of grid columns to calculate pixel size
 * @param {*number} columnWidth - column width in pixels
 * @param {*number} gutterWidth - gutter width in pixels
 */
export const colsToPix = (
  cols,
  columnWidth = gridPixValues.columnWidth,
  gutterWidth = gridPixValues.gutterWidth
) => cols * columnWidth + (cols - 1) * gutterWidth;

export const getArtBreakpointSizes = (featureBreakpoints, artSize) => {
  if (
    /(Full Width|full-width)/.test(artSize) ||
    featureBreakpoints === undefined || // is there a default we can use
    artSize === undefined
  ) {
    return featureBreakpoints; // This seems right?
  }

  return Object.keys(featureBreakpoints).reduce((acc, key) => {
    acc[key] = get(
      artBreakpointSizes[featureBreakpoints[key]],
      `${artSizeTranslator[artSize]}`,
      featureBreakpoints[key]
    );
    return acc;
  }, {});
};

/**
 * Figure out how many columns the image should span given the number of cols
 * the flex feature spans and the size selected for the image
 * @param  {Number} colspan how many columns the flex feature spans
 * @param  {[type]} artWidth the size of the image (full span, large, medium, small, x-small)
 * @return {Number} returns the number of columns the image in this flex feature should span
 */
export const getArtSlotColspan = (colspan, artSlotColspan, isFullWidth) => {
  if (isFullWidth) return colspan;

  if (artSlotColspan === 1) return THUMBNAIL_MOBILE_KEY;
  if (artSlotColspan === "tn") return THUMBNAIL_DESKTOP_KEY;
  if (artSlotColspan === "mn")
    return colspan === 1 ? MINI_MOBILE_KEY : MINI_DESKTOP_KEY;
  return artSlotColspan;
};

export const quantizeNumber = (num = 0, q = 1, method) => {
  return (
    Math[["floor", "round", "ceil"].includes(method) ? method : "ceil"](
      num / q
    ) * q
  );
};

/**
 *
 * @param {number} colspan How many cols the feature spans
 * @param {*} artSlotColspan
 * @param {float} aspectRatio The ratio of the art
 * @param {boolean} isFullWidth is full width or not
 * @returns
 */
export const getWidthAndHeight = (
  colspan,
  artSlotColspan,
  aspectRatio = 1.5,
  isFullWidth
) => {
  const colspanToUse = getArtSlotColspan(colspan, artSlotColspan, isFullWidth);

  let width = SMALLEST_IMAGE_WIDTH; // smallest possible image

  // special cases
  if (colspanToUse === MINI_DESKTOP_KEY) {
    // thumbnail desktop
    width = MINI_DESKTOP_WIDTH;
  } else if (colspanToUse === MINI_MOBILE_KEY) {
    // thumbnail mobile
    width = MINI_MOBILE_WIDTH;
  } else if (colspanToUse === THUMBNAIL_DESKTOP_KEY) {
    // thumbnail desktop
    width = THUMBNAIL_DESKTOP_WIDTH;
  } else if (colspanToUse === THUMBNAIL_MOBILE_KEY) {
    // thumbnail mobile
    width = THUMBNAIL_MOBILE_WIDTH;
  } else if (colspanToUse === 1 && isFullWidth) {
    width = quantizeNumber(
      typeof window !== "undefined" ? window.innerWidth : LARGEST_MOBILE_WIDTH,
      gridPixValues.columnWidth + gridPixValues.gutterWidth
    );
  } else {
    // calculate width from colspanToUse
    width = colsToPix(colspanToUse);
  }

  // NOTE: Set max-widths
  if (aspectRatio === 1) {
    if (width > MAX_WIDTH_SQUARE) width = MAX_WIDTH_SQUARE;
  } else if (aspectRatio < 1) {
    if (width > MAX_WIDTH_VERTICAL) width = MAX_WIDTH_VERTICAL;
  }

  // figure out the aspect ratio to calculate the height
  const height = Math.round(width / aspectRatio);

  return {
    width,
    height,
    colspanToUse
  };
};

export const getImageLink = ({ content, overrides = {} }) => {
  const { alternateArtLinkEnable } = overrides;

  let link;
  // NOTE: Only resort to alternateArtLink if alternateArtLinkEnable is true
  if (alternateArtLinkEnable) {
    const { alternateArtLink, alternateArt, inlineVideo } = overrides;
    link =
      alternateArtLink ||
      (!inlineVideo && isVideoUrl(alternateArt) ? alternateArt : undefined);
  }
  if (!link) {
    const linkObj = (getLink({ content, overrides }) || {}).link;
    if (linkObj && linkObj.url) link = linkObj.url;
  }
  return link;
};

getImageLink.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object
};

/**
 * @param  (standard stuff)
 * @return (object) - art overlay object with "compact" style or undefined
 *
 * Only show audio overlay if all of these are true:
 *  0. obviously, art must be on
 *  1. audio comes from content if there is content
 *  2. audio art overlay is configured to be on
 *  3. audio exists
 *  4. audio is human read
 */
export const getAudioArtOverlay = (props) => {
  const { content, podcastData, overrides } = props;
  const contentToUse = podcastData || content;
  const hasBackingContent = !!content._id;

  // START: return undefined if warranted
  if (hasBackingContent && contentToUse === podcastData) return undefined;
  const { audioArtOverlayShow } = overrides;
  if (!audioArtOverlayShow) return undefined;
  const audio = getAudio(props);
  if (!/human/.test(audio?.audioType)) return undefined;
  // END: return undefined if warranted

  const human = audio.human;
  const { authorNarratedFlag = false } = human;
  let url;
  let readBy;
  if (authorNarratedFlag) {
    readBy = getBylineText({
      content: contentToUse,
      role: "audio_narrators",
      prefix: "Read by"
    });
    url = get(contentToUse, "fusion_additions.headshot.url");
  }

  return {
    text: readBy || "Narrated audio",
    style: "compact",
    prefixIcon: "Waveform",
    ...(url
      ? {
          prefixMedia: {
            aspectRatio: 1,
            url,
            makeItRound: true
          }
        }
      : {})
  };
};

getAudioArtOverlay.propTypes = {
  content: PropTypes.object,
  podcastData: PropTypes.object,
  overrides: PropTypes.object
};

export const getArtOverlay = (props) => {
  /* Goal is:
  {
    text: string,
    style: "string:default|secondary,
    secondary_text: string,
    secondary_style: string:default|secondary|secondary_live,
    prefix_icon: string:Arrow|Camera|Play
    suffix_icon: string:Arrow|Camera|Play
  }
  */

  const audioArtOverlay = getAudioArtOverlay(props);
  if (audioArtOverlay) return audioArtOverlay;

  const { content, overrides } = props;

  // START: helpers
  const getLinkType = (link = "") => {
    if (link.match(/\/photography\/|_gallery.html/)) {
      return "photos";
    }
    if (link.match(/_live\.html/)) {
      return "live-video";
    }
    if (link.match(/(\/video\/|_video\.html)/)) {
      return "video";
    }
    return "other";
  };

  const getDefaultInfo = (text, linkType) => {
    switch (linkType) {
      case "photos":
        text = text || "View Photos";
        break;
      case "live-video":
        // NOTE: b/c of jsonapp constraints, "Live" gets set as secondary text
        text = undefined;
        break;
      case "video":
        text = text || "Play Video";
        break;
      default:
    }
    if (!text) return {};
    return {
      text,
      style: "default"
    };
  };

  const getSecondaryInfo = (text, linkType) => {
    switch (linkType) {
      case "live-video":
        return {
          secondaryStyle: "secondary_live",
          secondaryText: text || "Live"
        };
      default:
        return {};
    }
  };

  const getIcon = (linkType) => {
    switch (linkType) {
      case "live-video":
        return "Play";
      case "photos":
        return "Camera";
      case "video":
        return "Play";
      default:
        return "Arrow";
    }
  };
  // END: helpers

  const { artOverlayText, artOverlayShow = true } = overrides;

  // NOTE: If not due to show, then return undefined
  if (!artOverlayShow) return undefined;

  const link = getImageLink({ content, overrides });
  const linkType = getLinkType(link);
  const { text, style } = getDefaultInfo(artOverlayText, linkType);
  const { secondaryText, secondaryStyle } = getSecondaryInfo(
    artOverlayText,
    linkType
  );

  // NOTE: If no text, then return undefined
  if (!text && !secondaryText) return undefined;

  const icon = getIcon(linkType);
  // const prefixIcon = icon !== "Arrow" ? icon : undefined;
  const prefixIcon = icon;
  const suffixIcon = !prefixIcon ? icon : undefined;

  return {
    text,
    style,
    prefixIcon,
    secondaryText,
    secondaryStyle,
    suffixIcon
  };
};

getArtOverlay.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object
};

/**
 * Find out if the image due to show is of type "graphic" or "illustration" OR
 * if the artUseHiRes override is true.  If so, return useHiRes: true.
 *
 * @param  (standard stuff)
 * @return (object) {
 *   useHiRes: boolean,
 *   warning: string
 * }
 *
 * Re: warning -- for good reason, imageData is only fetched in the admin so on
 * the published page, if art is coming from imageData (which comes from alt art
 * and not the underlying content), image_type will be unknown, and therefore
 * hi-res cannot be forced. Therefore, set a warning (that is destined to appear
 * in the admin) that prompts the editor to check the override that forces hi-res.
 */
export const getHiResInfo = ({ overrides, imageData, artSlot, isAdmin }) => {
  const { artUseHiRes, cardifyForcedBleedType } = overrides;
  const re = /^(graphic|illustration)$/;
  // NOTE: Check imageData first cuz if present, it takes precedence
  const imageType = (imageData || artSlot)?.image_type;
  const shouldBeHiRes = re.test(imageType);
  const cannotForceHiRes = re.test(imageData?.image_type);
  const useHiRes = artUseHiRes || shouldBeHiRes;
  let warning;
  if (isAdmin) {
    warning =
      !artUseHiRes &&
      shouldBeHiRes &&
      cannotForceHiRes &&
      /default/.test(cardifyForcedBleedType)
        ? `Consider checking the "Use high-resolution" box for this image. Find it under Art Layout. Images with text: Consider selecting "Inset" on the  “Force bleed type on Cardify” dropdown, which is also under Art Layout.`
        : undefined;
  }
  return {
    useHiRes,
    warning
  };
};

/**
 * Return formatted image data in a media property
 * @return {
 *    link: {
 *      url: <string>,
 *      type: <string:unknown>,
 *    }
 *    url: <string>,
 *    aspect_ratio: <float>,
 *    caption: <string>,
 *    mediaTtype: <string>,
 *    alternateArt: <string>,
 *    artPosition: <string>,
 *    artWidth: <strng>
 *  },
 */
export const getImage = (props) => {
  const {
    content,
    artSlot = {},
    imageData,
    customHTML = false,
    overrides = {},
    isAdmin = false
  } = props;

  if (!isImageUrl(get(artSlot, "url")) && !get(artSlot, "isHtml", false)) {
    return undefined;
  }
  let link;
  const url = getImageLink({ content, overrides });
  const linkObj = (getLink({ content, overrides }) || {}).link;
  if (url && url !== (linkObj || {}).url) {
    link = {
      url,
      type: "unknown"
      // NOTE: The 'type' of link here is not the same as the type for determining the default overlay text
    };
  }

  const { alternateArt, artMakeItRound, disableLazyLoading } = overrides;

  const overlay = getArtOverlay(props);

  const customhtml = getCustomHTML({ customHTML, overrides, isAdmin });

  const caption = getCaption({
    content,
    artSlot,
    imageData,
    overrides,
    isAdmin
  });

  // START: get hi res info
  const { useHiRes, warning } = getHiResInfo({
    overrides,
    imageData,
    artSlot,
    isAdmin
  });
  // END: get hi res info

  const aspectRatio = getAspectRatio({ artSlot, overrides });

  // START: make it round
  let makeItRound = false;
  if (
    aspectRatio === 1 &&
    !artSlot.isHtml &&
    (/^always$/.test(artMakeItRound) ||
      (/^if-headshot/.test(artMakeItRound) && /^headshot/.test(artSlot.type)))
  ) {
    makeItRound = true;
  }
  // END: make it round

  return {
    altText: artSlot.alt,
    artWidth: artSlot.artWidth,
    aspectRatio,
    caption,
    customhtml,
    lazyLoad: !disableLazyLoading || customhtml,
    linkUrl: url,
    overlay,
    url: artSlot.url,
    useHiRes,
    makeItRound,
    warning,
    // START: needed for jsonapp
    link,
    mediaType: artSlot.mediaType,
    imageType: (imageData || artSlot)?.image_type, // needed for cardify
    // END: needed for jsonapp
    // START: needed for the jsonapp and/or admin toolbar
    alternateArt,
    artAspectRatio: overrides.artAspectRatio,
    artPosition: artSlot.artPosition,
    coverArtUrl: overrides.coverArtUrl,
    slideshowShow: overrides.slideshowShow,
    slideshowUrls: overrides.slideshowUrls
    // END: needed for the jsonapp and/or admin toolbar
  };
};

getImage.propTypes = {
  content: PropTypes.object,
  artSlot: PropTypes.object,
  imageData: PropTypes.object,
  customHTML: PropTypes.string,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const groomArtOverlayForJsonApp = ({ overlay }) => {
  if (!overlay) return overlay;
  if (overlay.prefixIcon) {
    overlay.prefixIcon = kebabcase(overlay.prefixIcon);
  }
  if (overlay.suffixIcon) {
    overlay.suffixIcon = kebabcase(overlay.suffixIcon);
  }
  if (overlay.style === "default") {
    delete overlay.style;
  }
  Object.keys(overlay).forEach((key) => {
    if (overlay[key] === undefined) delete overlay[key];
  });
  return overlay;
};

groomArtOverlayForJsonApp.propTypes = {
  overlay: PropTypes.object
};

export const groomImageForJsonApp = ({ image }) => {
  if (!image) return image;
  if (image.linkUrl) {
    delete image.linkUrl;
  }
  if (image.lazyLoad) {
    delete image.lazyLoad;
  }
  if (image.caption) {
    image.caption = image.caption.text;
    if (isHTMLFragment(image.caption)) {
      image.mime = "text/html";
    }
  }
  if (image.overlay) {
    image.overlay = groomArtOverlayForJsonApp({ overlay: image.overlay });
  }
  if (!image.altText) {
    delete image.altText;
  }
  delete image.alternateArt;
  delete image.artAspectRatio;
  delete image.coverArtUrl;
  delete image.useHiRes;
  delete image.slideshowShow;
  delete image.slideshowUrls;
  if (!image.makeItRound) delete image.makeItRound;
  return image;
};

groomImageForJsonApp.propTypes = {
  image: PropTypes.object
};
