import React from "react";
import { useAppContext } from "fusion-context";
import { fetchAsyncContent } from "fusion-content";
import getEnv from "~/components/utilities/env";

const makeEventKey = (id) => `alerts_${id}`;
const getEventState = (id) => {
  const state =
    localStorage.getItem(makeEventKey(id)) ||
    JSON.stringify({ content: null, id, acked: true });

  return JSON.parse(state);
};

const setEventState = (state) =>
  localStorage.setItem(makeEventKey(state.id), JSON.stringify(state));

const createNewEventState = (event) => {
  const state = { ...event, acked: false };
  return state;
};

const useLiveEventContent = (id, fetchInterval = 30000) => {
  const { isAdmin, apiUrl } = useAppContext();
  const [contentToDisplay, setContent] = React.useState(null);
  const [currentInterval, setCurrentInterval] = React.useState(0);

  const ackContent = React.useCallback(() => {
    setContent(null);
    const state = getEventState(id);
    setEventState({ ...state, acked: true });
  }, [id]);

  if (isAdmin) {
    fetchInterval = 15000;
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentInterval((curr) => curr + 1);
    }, fetchInterval);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [fetchInterval]);

  React.useEffect(() => {
    let isMounted = true;
    const env = getEnv();
    const fetching =
      isAdmin || env === "dev" || env === "local"
        ? fetch(`${apiUrl}/live-event/${id}`).then((res) => res.json())
        : fetchAsyncContent({
            source: "assembler-live-events",
            query: {
              id,
              env: getEnv()
            }
          });
    fetching.then((event) => {
      const { version, content } = event;
      const eventState = getEventState(event.id);
      if (eventState.acked && eventState.version === version) {
        // do nothing if we've already seen this event
        return;
      }
      if (isMounted) {
        setEventState(createNewEventState(event));
        setContent(content);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [apiUrl, currentInterval, id, isAdmin]);
  return [contentToDisplay, ackContent];
};

export default useLiveEventContent;
