/**
 * Transforms an ANS content object to bring nested
 * content_elements to the top-level
 *
 * @param {object} data - the global content
 * @returns {object} - the data with transformed content elements
 */

import get from "lodash.get";

export const hasDateline = (contentElements) => {
  // check that it's length is greated than 2 because
  // there needs to be a second element to merge the dateline into, silly
  return (
    contentElements.length >= 2 &&
    contentElements[0].subtype === "dateline" &&
    // if the next type isn't text we can't do anything and it should be ignored
    contentElements[1].type === "text"
  );
};

export const mergeDateline = (contentElements) => {
  const [datelineElement, second, ...rest] = contentElements;
  const mergedDateline = {
    ...second,
    // we trim for defensiveness and to normalize
    // different behaviors in the news room.
    // Not sure we can depend on them adding a space when there's a dateline
    content: `${datelineElement.content.trim()} ${second.content.trim()}`
  };
  return [mergedDateline, ...rest];
};

export const addNestedANS = (data) => {
  const { content_elements: contentElements } = data;

  if (!contentElements) return data;

  // eslint-disable-next-line prefer-spread
  const newEls = [].concat.apply(
    [],
    contentElements.map((contentEl) => {
      if (contentEl.type !== "element_group" || contentEl.subtype === "image") {
        return [contentEl];
      }
      if (contentEl.subtype === "trailer") {
        const trailer = contentEl.content_elements.map((el) => {
          return el.type === "text" ? { ...el, subtype: "trailer" } : el;
        });
        return trailer;
      }
      const group = get(contentEl, "content_elements", []);
      return hasDateline(group) ? mergeDateline(group) : group;
    })
  );

  return {
    ...data,
    content_elements: newEls
  };
};
