import { useContent } from "fusion-content";
import { useAppContext } from "fusion-context";
import { getAllCookieNames, deleteCookie } from "../../utilities/cookies";

const CookieMonster = () => {
  const { isAdmin } = useAppContext();

  // Fetch the allowed list of cookie names & cookie name patterns
  const allowedCookieData = useContent({
    source: "cookie-monster"
  });
  if (isAdmin || !allowedCookieData || !allowedCookieData.cookies) {
    return null;
  }
  const { cookies } = allowedCookieData;
  const allowedCookieNames = [];
  const allowedCookieRegex = [];

  // eslint-disable-next-line no-useless-escape
  const regexSpecialChars = new RegExp(/[\^\$\|\?\+\.\(\[\\]/);
  cookies.forEach((cookieName) => {
    if (regexSpecialChars.test(cookieName)) {
      allowedCookieRegex.push(cookieName);
    } else {
      allowedCookieNames.push(cookieName.toLowerCase());
    }
  });

  const cookieNames = getAllCookieNames();
  const doesCookieMatchRegex = (cookieName) =>
    allowedCookieRegex.find((regexStr) =>
      new RegExp(regexStr).test(cookieName)
    );

  cookieNames.forEach((cookieName) => {
    const trimmedName = cookieName.trim();
    if (
      !allowedCookieNames.includes(trimmedName.toLowerCase()) &&
      !doesCookieMatchRegex(trimmedName)
    ) {
      deleteCookie(trimmedName);
    }
  });

  return null;
};

export default CookieMonster;
