import React, { Fragment } from "react";
import PropTypes from "@arc-fusion/prop-types";
import Warning from "~/shared-components/Warning";

const REFRAIN = "Fix or remove it";

const defaultMessage = `{key} {count} is invalid. ${REFRAIN}. A developer should add a better warning message.`;

const messages = {
  imageData: `Image alternate art is invalid. ${REFRAIN}.`,
  videoData: `Video alternate art is invalid. ${REFRAIN}.`,
  podcastData: `Audio story is invalid. ${REFRAIN}.`,
  liveTickerData: `Live Ticker story is invalid. ${REFRAIN}.`,
  electionData: `Election live graphic id is invalid. ${REFRAIN}.`,
  olympicsData: `Olympics live graphic id is invalid. ${REFRAIN}.`,
  // NOTE: things that come in lists go here
  carousel: `Carousel item #{count} is invalid. ${REFRAIN}.`,
  livebar: `Video Live Bar item #{count} is invalid. ${REFRAIN}.`,
  slideshow: `Slideshow item #{count} is invalid. ${REFRAIN}.`
};

const getMessage = (key, i = -1) =>
  (messages[key] || defaultMessage)
    .replace("{key}", key)
    .replace("{count}", i >= 0 ? i + 1 : "");

const getWarningsFromContent = ({ requireContent = false, content }) => {
  if (requireContent && !content)
    return [
      "The content backing this feature is invalid or malfunctioning. Remove the URL from the content path and add it via the flex feature's headline link override."
    ];
  return [];
};

const getWarningsFromConfigs = ({ configs = {}, data = {} }) => {
  return Object.keys(configs).reduce((acc, key) => {
    if (!/no(Content|Config)/.test(key) && !data[key])
      acc.push(getMessage(key));
    return acc;
  }, []);
};

const getWarningsFromLists = ({ lists = {} }) => {
  return Object.keys(lists).reduce((acc, key) => {
    const { listOfConfigs = [], listOfData = [] } = lists[key];
    listOfConfigs.forEach((config, i) => {
      const { source } = config;
      if (!/empty/.test(source) && !listOfData[i]) {
        acc.push(getMessage(key, i));
      }
    });
    return acc;
  }, []);
};

const getWarnings = (props) => [
  ...getWarningsFromContent(props),
  ...getWarningsFromConfigs(props),
  ...getWarningsFromLists(props)
];

export const HandleFetchErrors = (props) => {
  const warnings = getWarnings(props);
  if (!warnings.length) return null;
  return (
    <Fragment>
      {warnings.map((w, i) => (
        <Warning key={i} message={w} level="warning" />
      ))}
    </Fragment>
  );
};

HandleFetchErrors.propTypes = {
  requireContent: PropTypes.bool,
  content: PropTypes.object,
  configs: PropTypes.object,
  data: PropTypes.object,
  lists: PropTypes.object
};
