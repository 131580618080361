import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const TableLayoutContext = createContext({});

export function TableLayoutProvider({ value, children }) {
  return (
    <TableLayoutContext.Provider value={value}>
      {children}
    </TableLayoutContext.Provider>
  );
}

TableLayoutProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node
};

export function useTableLayoutContext() {
  return useContext(TableLayoutContext);
}
