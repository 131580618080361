// To alter the presets, update the imported file below.
// To alter the default preset, update the imported file below.

import presets from "./options/presets";
import defaults from "./options/default-preset";
import separator from "./options/separator";

const presetOptions = [...presets, ...separator, ...defaults];

export default presetOptions;
