import { carouselTypes } from "~/proptypes/carousel";
import miniPresets from "~/presets/mini-presets";
import { getHideAndShowPresets } from "~/presets/utils";

// NOTE: Adjust these presets or add another carouselType as necessary
// WARNING: Each value in one preset must be preset in all other presets.
const defaultShape = {
  // label
  labelShow: true,
  labelSecondaryShow: true,
  labelType: "Kicker",
  labelPosition: "Above Headline",
  // art
  artHide: false,
  artOverlayShow: false,
  artPosition: "Art above head",
  artSize: "Full Width",
  artAspectRatio: "3:2",
  artImageFallback: "default",
  // video
  inlineVideo: false,
  // headline
  headlineHide: false,
  headlineSize: "Small",
  // sigline
  siglineHide: false,
  bylineShow: false,
  timestampShow: true,
  // audio
  audioHide: true,
  audioAllowArticleType: "none",
  // excerpt
  excerptHide: true,
  // count
  countShow: false,
  // aggressive suppresion, possibly gratuitous
  // cuz these are current defaults
  captionContent: "None",
  // recipe
  recipeShow: false,
  // live graphics
  liveGraphicsHide: true
};
// NOTE: Each active preset should start with ...defaultShape, then override any differences from the default
const presets = {
  audio: {
    ...defaultShape,
    // audio
    audioHide: false,
    audioAllowArticleType: "any",
    audioListenText: "Listen",
    audioArtOverlayShow: true
  },
  bright: {
    ...defaultShape,
    // label
    labelShow: false,
    labelSecondaryShow: false,
    // art
    artAspectRatio: "1:1",
    artImageFallback: "bright",
    // headline
    headlineHide: true,
    // sigline
    siglineHide: true,
    // excerpt
    excerptHide: false
  },
  "elex-live-graphic": {
    ...defaultShape,
    // label
    labelShow: false,
    labelSecondaryShow: false,
    // headline
    headlineHide: true,
    // sigline
    siglineHide: true,
    // live graphics content config
    liveGraphicsHide: false
  },
  immersion: {
    ...defaultShape
  },
  recipe: {
    ...defaultShape,
    // label
    labelShow: false,
    labelSecondaryShow: false,
    // sigline
    siglineHide: true,
    // recipe
    recipeShow: true
  },
  video: {
    ...defaultShape,
    // label
    labelShow: false,
    labelSecondaryShow: false,
    // art
    artAspectRatio: "16:9",
    artOverlayShow: true,
    // video
    inlineVideo: true,
    includeDurationOrLive: false,
    allowAnimation: false,
    autoplay: false,
    ads: true,
    bylineShow: true
  }
};
presets["vertical-video"] = presets.video;

// NOTE: If jsonapp shape differs from web, add differences here
const jsonapp = {
  audio: {
    // sigline
    siglineHide: true
  },
  immersion: {
    // label
    labelAllowDuplicateOfHeadlinePrefix: true,
    // sigline
    timestampShow: false
  },
  recipe: {
    // headline
    headlineSize: "Tiny"
  },
  video: {
    // sigline
    siglineHide: true
  }
};

export const getPreset = (carouselType, outputType) => {
  let preset = presets[carouselType] || defaultShape;
  if (/jsonapp/.test(outputType)) {
    preset = {
      ...preset,
      ...(jsonapp[carouselType] || {})
    };
  }
  return preset;
};

// NOTE: This is used by spartan-homepage/rootConfig.jsx and powers the blue toolbar
export const getCarouselPresets = () => {
  const carouselPresets = Object.keys(carouselTypes).reduce((acc, type) => {
    const name = carouselTypes[type];
    const values = getPreset(type);
    if (values) {
      acc.push({
        name: `⟲ ${name} shape`,
        values
      });
    }
    return acc;
  }, []);
  carouselPresets.push({ name: "=======", values: {} });

  const { mini: miniHide, mobile: mobileHide } = getHideAndShowPresets();
  const miniKeys = [
    // NOTE: Turn off for now cuz Android cannot handle this
    // "Hide image",
    // "Unhide image",
    "Image aspect ratio: 16:9",
    "Image aspect ratio: 3:2",
    "Image aspect ratio: 1:1",
    "Hide label",
    "Label -- Small show 1° only",
    "Label -- Small show 1° and 2°",
    "Label -- Small show 2° only",
    "Hide byline",
    "Show byline",
    "Turn on count",
    "Turn off count"
  ];
  const mini = miniPresets.reduce((acc, miniPreset) => {
    if (miniKeys.includes(miniPreset?.name)) acc.push(miniPreset);
    return acc;
  }, []);
  return {
    presets: carouselPresets,
    mini: [...mini, ...miniHide],
    mobile: mobileHide
  };
};
