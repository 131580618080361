import presetGenerator from "./preset-generator";

import defaults from "../../components/features/fronts/flex-feature/utilities/custom-field-defaults";

const presets = {
  "⟲ Head + byline (Clear all Custom Fields)": {
    ...defaults
  }
};

export default Object.keys(presets).map((name) =>
  presetGenerator(name, presets[name])
);
