import PropTypes from "@arc-fusion/prop-types";

// NOTE: in-table-ad and in-line-brandconnect-ad are almost exactly the same. They differ
// in two ways:
//
//  1. They have a different set of ads in the adType dropdown (bigbox v. brandconnect)
//  2. in-table-ad is safelisted for jsonapp while in-line-brandconnect is not.
//
// Once the apps support the brandconnect ad, the brandconnect ad types can be included in
// in-table-ad and in-line-brandconnect can be deleted. Of course, any brandconnect features
// will need to be swapped out for the in-table-ad feature with the correct settings.

import { bigBoxAdTypes, sharedProps } from "~/proptypes/zeus";

export const defaultAdType = "bigbox";

const adTypes = {
  ...bigBoxAdTypes
};

export const InTableAdPropTypes = {
  customFields: PropTypes.shape({
    hideOnDesktop: PropTypes.bool.tag({
      name: "Hide feature on desktop",
      description:
        "Check this to hide this feature at desktop breakpoints (exact breakpoints depend on chain layout).",
      defaultValue: false
    }),
    adType: PropTypes.oneOf(Object.keys(adTypes)).tag({
      labels: { ...adTypes },
      defaultValue: defaultAdType
    }),
    mobilePreset: PropTypes.string.tag({
      name: "Mobile preset",
      description: "Select the mobile rendering of this feature.",
      defaultValue: "As is (but not hidden)",
      hidden: true
    })
  }),
  ...sharedProps
};
