// Filter for a single ANS story, that powers a story card.
// Contains the bare minimum needed to power a story card.
// May be able to be tweaked once we are fully hooked up to Prism.

export default `
{
    _id
    additional_properties {
        publicationDisplayDate
        audio {
            podtrac_url
            adswizz_url
            complete_url
            duration
        }
        audio_article {
            automated {
                manifest {
                    voices {
                        id
                        label
                        s3Key
                        rawUrl
                        adsUrl
                        marksUrl
                        duration
                    }
                }
            }
            human {
                ads_url
                caption
                duration
                raw_url
                source_file_id
                authorNarratedFlag
            }
            type
        }
        series_meta {
            seriesName
            seriesSlug
            subscriptionLinks {
                alexa
                amazonMusic
                applePodcasts
                googlePlay
                iheartRadio
                radioPublic
                rss
                spotify
                stitcher
                tuneIn
            }
            keywords
            images {
              coverImage {
                  url
                  width
                  height
              }
            }
        }
        advertising {
          adSetUrls {
            apps
            desktop
            mweb
            tablet
          }
          allowPrerollOnDomain
          autoPlayPreroll
          commercialAdNode
          enableAdInsertion
          enableAutoPreview
          playAds
          playVideoAds
          videoAdZone
        }
        first_display_date
        gifAsThumbnail
        imageResizerUrls {
          url
          width
          height
          size
        }
        time_to_read
        subsection
        videoCategory
        youtubeEventId
        publicationEndDate
        streamName
        streamDisplayName
        rhapsochord {
          preRoll
          postRoll
          midRollPoints
        }
    }
    first_publish_date 
    headlines {
        basic
    }
    label {
      transparency {
        text
      }
    }
    label_display {
      basic {
        headline_prefix
        text
        url
      }
      transparency {
        text
      }
    }
    canonical_url
    credits {
        audio_narrators {
            _id
            name
            url,
            org
            additional_properties {
                original {
                  byline
                }
            }
        }
        audio_producers {
            _id
            name
            url,
            org
            additional_properties {
                original {
                  byline
                }
            }
        }
        by {
            _id
            name
            url,
            org
            additional_properties {
                original {
                  byline
                }
            }
        }
        contributors {
            _id
            name
            url,
            org
            additional_properties {
                original {
                  byline
                }
            }
        }
        host_talent {
            _id
            name
            url,
            org
            additional_properties {
                original {
                  byline
                }
            }
        }
    }
    description {
        basic
    }
    display_date,
    duration
    fusion_additions {
        audio {
          id
        }
        bright {
            _id
            type
            url
            alt_text
            credits_display
            credits_caption_display
            width
            height
            image_type
            aspect_ratio
            provenance
        }
        count_info {
          count
        }
        excerpt
        links_to_use {
            basic
            offline
            dangerous {
                canonical
                redirect
                enhanced_experience
                short
            }
        }
        live_ticker {
          is_live,
          poll_frequency,
          items {
            subtype
            canonical_url
            headlines {
              basic
            }
            display_date
          }
        }
        promo_image {
            _id
            type
            url
            alt_text
            credits_display
            credits_caption_display
            width
            height
            image_type
            aspect_ratio
            provenance
        }
        rating_info {
          type
          value
          count
          max
          provenance
        }
        recipe_info {
            total_time
            courses {
              id
              description
            }
        }
        headshot {
            type
            url
            provenance
        }
        tracking {
            arc_id
            author_id
            author
            author_name
            author_desk
            author_subdesk
            blog_name
            clavis_keywords
            tracking_tags
            cms_system
            commercial_node
            content_category
            content_id
            content_type
            headline
            hierarchy
            page_name
            first_publish_date
            section
            seo_keywords
            subsection
            source
        }
    }
    owner {
        name
        source
    },
    promo_image {
      url
      width
      height
      credits {
        by {
          name
          org
          url
        }
      }
    }
    promo_items {
      bright {
        url
        caption
      }
      gif {
          url
          width
          height
      }
      basic {
          _id
          url
          width
          height
          credits_caption_display
          credits_display
          caption
          copyright
          credits {
            by {
              name
              org
              type
            }
            affiliation {
              name
            }
          }
          vanity_credits {
            by {
              name
            }
            affiliation {
              name
            }
          }
      }
      video
    }
    publish_date
    related_content {
        redirect {
          redirect_url
        }
        primary_slot {
          primary_slot
        }
    },
    short_url
    source {
        name
    },
    streams {
      bitrate
      filesize
      provider
      stream_type
      url
      width
      height
    }
    subtitles {
      urls {
        format
        url
      }
    }
    subheadlines {
        basic
    }
    subtype
    taxonomy {
        primary_site {
            description
            name
            _id
        }
        primary_section {
            name
            _id
        }
        tags {
            text
            slug
        }
        sections {
            name
        }
    }
    tracking {
      commercial_node
    }
    type
    vanity_credits {
      affiliation {
        name
      }
      by {
        name
      }
    }
    video_type
    website_url
}
`;
