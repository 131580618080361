import React from "react";
import PropTypes from "prop-types";

const VideoContext = React.createContext();

export const VideoProvider = ({ children }) => {
  return (
    <VideoContext.Provider value={{}}>
      {children}
    </VideoContext.Provider>
  );
};

VideoProvider.propTypes = {
  children: PropTypes.node
};
