import get from "lodash.get";

export const FOR_YOU_SURFACE_MV_TAB = "assembler-fy-mv-tab";
export const FOR_YOU_SURFACE_DEFAULT = "assembler-fy";

/**
 * For You attributes for tracking information, intended for the child stories of a common container.
 * @param {object} content of the card
 */
export const getForYouChildAttrs = (content) => {
  const article_id = get(content, "additional_properties.for_you.article_id");
  if (!article_id) return {};

  return {
    "data-articleid": article_id
  };
};

/**
 * For You attributes for tracking information, intended for the common container of several child stories.
 * @param {object} items of the table
 * @param {string} surface general area this for you card is appearing. Will attempt to get this info from the request.
 * @returns data attrs relevant to For You for attaching to a StoryCard
 */
export const getForYouParentAttrs = (items, surface) => {
  const request_id = get(items, "0.additional_properties.for_you.request_id");
  surface = get(items, "0.additional_properties.for_you.surface", surface);
  if (!request_id) return {};

  return {
    "data-fy-request-id": request_id,
    "data-fy-surface": surface
  };
};
