/**
 * Environment variables for Assembler runtimes
 *
 * Uses Next.js runtime config, or will fallback to process.env
 */
import getConfig from "next/config";
import { internal } from "../prism.config";

const defaultApiUrl = "http://localhost:8001";

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() || {};
const runtimeConfig = { ...publicRuntimeConfig, ...serverRuntimeConfig };

export const apiUrl =
  process.env.NEXT_PUBLIC_API_LOCATION ||
  runtimeConfig.NEXT_PUBLIC_API_LOCATION ||
  defaultApiUrl;
export const outputType = process.env.OUTPUT_TYPE || runtimeConfig.OUTPUT_TYPE;
export const assetPrefix =
  process.env.ASSET_PREFIX || runtimeConfig.ASSET_PREFIX || "";
export const buildId =
  process.env.NEXT_PUBLIC_BUILD_ID ||
  runtimeConfig.NEXT_PUBLIC_BUILD_ID ||
  "none";
export const gitCommitHash =
  process.env.GIT_COMMIT_HASH || runtimeConfig.GIT_COMMIT_HASH || "";
export const internalApiUrl =
  process.env.NEXT_PUBLIC_INTERNAL_API_LOCATION ||
  runtimeConfig.NEXT_PUBLIC_INTERNAL_API_LOCATION ||
  defaultApiUrl;
export const internalContentApiUrl = internal || "http://localhost:3001";
export const nileAppVersion =
  process.env.NILE_APP_VERSION || runtimeConfig.NILE_APP_VERSION;
export const nileEnv =
  process.env.NEXT_PUBLIC_NILE_ENV ||
  process.env.NILE_ENV ||
  runtimeConfig.NEXT_PUBLIC_NILE_ENV ||
  runtimeConfig.NILE_ENV ||
  "local";
export const nileNamespace =
  process.env.NILE_NAMESPACE || runtimeConfig.NILE_NAMESPACE;
export const redisHost = process.env.REDIS_HOST || runtimeConfig.REDIS_HOST;
export const vercelEnv =
  process.env.NEXT_PUBLIC_VERCEL_ENV || runtimeConfig.NEXT_PUBLIC_VERCEL_ENV;
