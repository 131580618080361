import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

const useEvent = (ev, fn, options) => {
  const { condition = true, interval, element } = options || {};
  const cb = useRef(fn);
  useEffect(() => {
    const target = element || window;
    if (typeof target !== "undefined" && condition) {
      const handler = /^\d+$/.test(interval)
        ? debounce(cb.current, interval)
        : cb.current;
      target.addEventListener(ev, handler);
      return () => {
        target.removeEventListener(ev, handler);
      };
    }
    // eslint-disable-next-line react/display-name
    return () => null;
  }, [cb, ev, condition, interval, element]);
};

useEvent.propTypes = {
  fn: PropTypes.func,
  ev: PropTypes.string,
  options: PropTypes.shape({
    condition: PropTypes.bool,
    interval: PropTypes.number,
    element: PropTypes.object
  })
};

export const useUnload = (fn, options) => useEvent("beforeunload", fn, options);
export const useClick = (fn, options) => useEvent("click", fn, options);
export const useScroll = (fn, options) => useEvent("scroll", fn, options);
export const useResize = (fn, options) => useEvent("resize", fn, options);
