/* eslint-disable prefer-template */

/**
 * Removes a query parameter from a url
 *
 * @param {string} url the current url
 * @param {string} parameter the query param to strip out
 * @returns {string} sanitized url
 */
export function removeQueryParameter(url, parameter) {
  return url
    .replace(new RegExp("[?&]" + parameter + "=[^&#]*(#.*)?$"), "$1")
    .replace(new RegExp("([?&])" + parameter + "=[^&]*&"), "$1");
}

/**
 * Update and replace current browser history entry without the tid and itid params
 * Also strip out the utm_term param as this is a deprecated query param
 */
export function stripOutInternalParams() {
  const currUrl = window.location.href;
  const urlRemoveParams = ["tid", "itid", "utm_term"].reduce((acc, param) => {
    return removeQueryParameter(acc, param);
  }, currUrl);

  if (currUrl !== urlRemoveParams) {
    window.history.replaceState({}, document.title, urlRemoveParams);
  }
}

/**
 * Get all query parameters from provided URL
 */
export function getQueryParams(url) {
  const vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
}

/**
 * Forces a trailing slash in a url if not present
 */
export function addTrailingSlash(url) {
  if (url.substr(-1) !== "/") url += "/";
  return url;
}
