import PropTypes from "prop-types";
import { dividerWeights } from "~/components/features/fronts/utilities/common-front-properties";

export const NewsletterPropTypes = {
  id: PropTypes.string.isRequired,
  context: PropTypes.shape({
    isAdmin: PropTypes.bool
  }),
  customFields: PropTypes.shape({
    newsletterContentConfig: PropTypes.contentConfig(["newsletter"]).tag({
      defaultValue: {
        contentService: "newsletter",
        contentConfigValues: {
          id: "8c2717f7-6973-40e3-bec1-1e0bf277c7f7"
        }
      },
      name: "Content Config"
    }),
    customHeadline: PropTypes.richtext.tag({
      name: "Headline"
    }),
    hideBlurb: PropTypes.bool.tag({
      name: "Hide blurb",
      defaultValue: false
    }),
    customBlurb: PropTypes.richtext.tag({
      name: "Blurb"
    }),
    signUpLink: PropTypes.string.tag({
      name: "Sign up link",
      description: "The standalone sign-up page (needed for app fallback)"
    }),
    hideImage: PropTypes.bool.tag({
      name: "Hide image",
      defaultValue: false
    }),
    customImageUrl: PropTypes.richtext.tag({
      name: "16:9 Image",
      description: "Enter image url"
    }),
    imageAlt: PropTypes.richtext.tag({
      name: "Image alt text",
      description: "Image alt text"
    }),
    hidePromoLabel: PropTypes.bool.tag({
      name: "Hide promo label",
      defaultValue: false
    }),
    promoLabel: PropTypes.richtext.tag({
      name: "Promo Label",
      description:
        "Define newsletter frequency whether it's Daily, Weekly, Weekly for 12 weeks, etc.",
      defaultValue: "Every Day"
    }),
    isSlim: PropTypes.bool.tag({
      name: "Slim Newsletter",
      description:
        "This command flattens the look of the newsletter. Refresh the admin after checking/unchecking this box to update the preview."
    }),
    rowSpan: PropTypes.number.tag({
      name: "Row Spans",
      description:
        "If there is too much white space use this to adjust row height",
      defaultValue: 1
    }),
    buttonColor: PropTypes.oneOf(
      Object.keys({
        blue: "blue",
        red: "red",
        green: "green",
        white: "white",
        offblack: "offblack",
        "subs-blue": "subs-blue",
        "subs-pink": "subs-pink",
        "subs-green": "subs-green",
        "subs-navy": "subs-navy"
      })
    ).tag({
      name: "Button Color",
      description: "Select newsletter sign up button color",
      defaultValue: "offblack"
    }),
    horizontalDividerWeight: PropTypes.oneOf(Object.keys(dividerWeights)).tag({
      labels: dividerWeights,
      name: "Horizontal divider",
      description:
        "Select the weight of the horizontal divider, which appears below the feature when there is another feature positioned below it",
      defaultValue: "normal"
    }),
    verticalDividerWeight: PropTypes.oneOf(Object.keys(dividerWeights)).tag({
      labels: dividerWeights,
      name: "Vertical divider",
      description:
        "Select the weight of the vertical divider, which appears to the left of the feature when there is another feature to the left of it",
      defaultValue: "normal",
      hidden: true
    }),
    previewAppFallback: PropTypes.bool.tag({
      name: "Preview app fallback",
      defaultValue: false
    }),
    mobilePreset: PropTypes.string.tag({
      name: "Mobile preset",
      description: "Select the mobile rendering of this feature.",
      defaultValue: "As is (but not hidden)",
      hidden: true
    })
  })
};
