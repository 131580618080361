import get from "lodash.get";
import set from "lodash.set";

import getEnv from "~/components/utilities/env";

export const removeWWW = (url = "") => {
  if (/(?:https?:\/\/)?(?:www\.)?washingtonpost\.com(.*)/.test(url)) {
    return `${RegExp.$1}`;
  }
  return url;
};

/** String -> String */
export const addWww = (contentPath) => {
  if (!contentPath) return "";
  if (!/^(\/|www\.washingtonpost\.com)/.test(contentPath)) return contentPath;
  try {
    const url = new URL(contentPath, "https://www.washingtonpost.com");
    return url.href;
  } catch (e) {
    return "";
  }
};

/*
 * Transfroms a content config object to remove the washingtonpost domain from urls.
 * This code contains the known content sources and field names that could have
 * such a domain. Enhance this as necessary.
 *
 * But why? There are proxy services that libraries (the places with books) use that choke
 * if the washingtonpost domain is present in Fusion.contentCache.
 *
 * WARNING: The underlying content source must work w/o the domain (!) which is the case
 * for the prism content sources here.
 *
 * @param {object} config -- the content config to transform. Note that content config
 *   can have THREE forms and this code can transform either.
 *
 *  FORM 1:
 *  {
 *    contentService: <string>,
 *    contentConfigValues: <object>
 *  }
 *
 *  FORM 2:
 *  {
 *    source: <string>,
 *    query: <object>
 *  }
 *
 *  FORM 3:
 *  [{ source: <string>, query: <object> }, { source: <string>, query: <object> }]
 *
 * Some keys in contentConfigValues/query can contain the washingtpost domain. The goal
 * is to remove the domain from them.
 *
 * @returns {object} - the transformed config object
 */
export const formatContentConfig = (config) => {
  // slideshowListOfData is passed to us as an array of configs
  if (Array.isArray(config)) return config.map(formatContentConfig);

  const empty = { source: "empty", contentService: "empty" };

  const source = get(config, "contentService", get(config, "source"));
  if (!source) {
    return { ...config, ...empty };
  }

  const root = get(config, "query") ? "query" : "contentConfigValues";
  let key;
  switch (source) {
    case "prism":
    case "prism-promo":
      key = `${root}.content_path`;
      break;
    case "prism-query":
      key = `${root}.query`;
      break;
    default:
  }

  if (key) {
    const value = get(config, key);
    if (removeWWW(value)) {
      set(config, key, removeWWW(value));
    } else {
      set(config, "source", "empty");
      set(config, "contentService", "empty");
    }
    if (/^prism(-promo)?$/.test(source) && !/^\//.test(get(config, key))) {
      return { ...config, ...empty };
    }
  }

  // NOTE: Add env to content config. This will get passed to content sources which allows
  // them to be environment sensitive which can be important for hitting test endpoints
  // (like elections or olympics) and for changing behavior in different environments, like say,
  // if the cookie-monster needed to preserve a different set cookies in different environments.
  const env = getEnv();
  if (config[root]) config[root].env = env;

  return config;
};
