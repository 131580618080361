import get from "lodash.get";
import { getResizedImage } from "~/shared-components/video/video-helpers";
import getResizedUrl from "~/components/core/elements/image/resize-url.js";
import { removeWWW } from "~/components/utilities/www-remover.js";

export const getVideoImg = (videoData, altArt) => {
  const altArtVideoSize = (url) => getResizedUrl(url, { w: 324 });

  const resizedUrls = get(
    videoData,
    "additional_properties.imageResizerUrls",
    ""
  );
  if (altArt) return altArtVideoSize(altArt);
  if (resizedUrls && resizedUrls.length) {
    const urls = get(videoData, "additional_properties.imageResizerUrls");
    return getResizedImage(urls, 400);
  }
  return get(videoData, "promo_image.url");
};

export const getVideoHeadline = (videoData, headline) => {
  return headline || get(videoData, "headlines.basic");
};

export const getVideoTease = (videoData, tease) => {
  return tease || get(videoData, "description.basic");
};

export const getVideoUrl = (videoData, url) => {
  return url || get(videoData, "canonical_url");
};

export const getSlotConfig = (url) => {
  return typeof url === "string" && url.length
    ? {
        source: "prism-query",
        query: { query: removeWWW(url) }
      }
    : { source: "empty" };
};

/* returns {altArt, headline, headlineLink, headlinePrefix, tease, url, videoLabel, count}
 */
export const getVideoData = (
  videoDataObj,
  stateData,
  videoObjIndex,
  isAdmin
) => {
  if (isAdmin && !videoDataObj && !!stateData) {
    const videoLabel = stateData[`video${videoObjIndex}Url`]
      ? { videoLabel: `Video ${videoObjIndex} has ended` }
      : { videoLabel: `No Video ${videoObjIndex} url` };
    return videoLabel;
  }
  if (!videoDataObj) return undefined;
  const keyPrefix = `video${videoObjIndex}`;
  return {
    ...videoDataObj,
    alternateArt: getVideoImg(videoDataObj, stateData[`${keyPrefix}AltArt`]),
    headline: getVideoHeadline(videoDataObj, stateData[`${keyPrefix}Headline`]),
    headlineLink:
      stateData[`${keyPrefix}HeadlineLink`] ||
      (videoDataObj && videoDataObj.canonical_url),
    headlinePrefix: stateData[`${keyPrefix}HeadlinePrefix`],
    tease: getVideoTease(videoDataObj, stateData[`${keyPrefix}Tease`]),
    url: getVideoUrl(videoDataObj, stateData[`${keyPrefix}Url`]),
    videoLabel: `Video ${videoObjIndex}`,
    count: videoObjIndex
  };
};

export const convertVideoPropsToDataAttrs = (videoProps) => {
  const dataAttrs = {};
  Object.entries(videoProps).forEach(([key, val]) => {
    const convertedKey = key
      .replace(/^/, "data-")
      .replace(/([A-Z])/g, "-$1")
      .toLowerCase();
    dataAttrs[convertedKey] = val;
  });
  return dataAttrs;
};
