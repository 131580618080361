import PropTypes from "prop-types";

import get from "lodash.get";

import {
  generateVideoCaption,
  getAspectRatio as getVideoAspectRatio
} from "~/shared-components/video/video-helpers";

import {
  getAspectRatio as getImageAspectRatio,
  isSameImage
} from "../_utilities/helpers";

// enumerated types
export const NORMAL_CAPTION = "Normal caption";
export const CREDITS_ONLY_CAPTION = "Credits only";
export const NO_CAPTION = "None";

// all available caption options
// (this should probably go in a config file somewhere)
export const captionOptions = [
  NORMAL_CAPTION,
  CREDITS_ONLY_CAPTION,
  NO_CAPTION
];

const unmatchable = "enocmlzdg9wagvyifiuiet";

/**
 * createImageIdForCaption - take an image url and return a 25char long probably unique string
 * @param {string} url - image url for the flex feature
 * @returns {string} - 25 character long probably unique string for use as a key in content editable
 */
const createImageIdForCaption = (url) => {
  if (!url) return "";
  // Seems like special characters mess up the use of these keys in content editable
  // so we only want letters and numbers
  const cleanedUrl = url.replace(/[^a-zA-Z0-9]/g, "");
  const modulo = Math.round(cleanedUrl.length / 25);
  return cleanedUrl
    .split("")
    .filter((itm, i) => i % modulo === 0)
    .join("");
};

/**
 * returns the anglerfish id or a key generated for the image/video url
 * @param {object} artSlot an object returned from calling getArtSlotInfo()
 * at a minimum it must have a url property
 * @return {object} the generated key
 */
const generateCaptionKey = (artSlot) => {
  // NOTE: Order matters!
  return (
    artSlot.videoId ||
    artSlot.anglerfishId ||
    createImageIdForCaption(artSlot.url)
  );
};

const getVideoCaption = (videoData, captionContent) => {
  // NOTE: Elaborate on this as it becomes necessary
  if (captionContent === NO_CAPTION) return undefined;
  let text =
    generateVideoCaption(videoData) || get(videoData, "source.name", "");
  text = text ? `(${text})` : text;
  if (captionContent === NORMAL_CAPTION) {
    const subheadline = get(videoData, "subheadlines.basic", "");
    text = subheadline ? `${subheadline} ${text}` : text;
  }
  return text;
  // NOTE: The logic was once this simple but is probably inadequate
  // return generateVideoCaption(videoData) || undefined;
};

const getImageCaption = ({
  content,
  imageData,
  artSlot = {},
  overrides = {}
}) => {
  const { captionContent = NORMAL_CAPTION } = overrides;

  let text;

  if (!!artSlot.url && captionContent !== NO_CAPTION) {
    const key =
      captionContent === NORMAL_CAPTION
        ? "credits_caption_display"
        : "credits_display";

    const bright = get(content, "fusion_additions.bright");
    const promoImage = get(content, "fusion_additions.promo_image");

    if (artSlot.anglerfishId === get(imageData, "_id", unmatchable)) {
      if (captionContent === NORMAL_CAPTION) {
        text = get(
          imageData,
          "fusion_additions.caption_info.dangerous.raw_caption",
          undefined
        );
      }
      if (!text) {
        text = get(
          imageData,
          `fusion_additions.caption_info.${key}`,
          undefined
        );
      }
    } else if (artSlot.url === get(artSlot, "raw.basic", unmatchable)) {
      text =
        get(content, `promo_items.basic.${key}`, undefined) ||
        get(artSlot, key, undefined); // this one is necessary for Assembler
    } else if (artSlot.type !== "alt-image" && isSameImage(artSlot, bright)) {
      // NOTE: In these two "if" statements, if artSlot is derived from altArt and altArt
      // is the same as bright/promoImage, do not do this cuz altArt takes precedence
      text = get(bright, key, undefined);
    } else if (
      artSlot.type !== "alt-image" &&
      isSameImage(artSlot, promoImage)
    ) {
      text = get(content, `fusion_additions.promo_image.${key}`, undefined);
    } else {
      text = get(artSlot, key, undefined);
    }
  }
  return text;
};

export const getCaption = ({
  content,
  artSlot = {},
  imageData,
  videoData,
  overrides = {},
  isAdmin = false
}) => {
  // NOTE: Goal is to produce this. At some point, this could be
  // integrated into a clean and comprehensive getImage or getMedia
  /*
    {
      text: "string"
    }
  */

  const { captionContent = NORMAL_CAPTION, inlineVideo = false } = overrides;

  // RETURN undefined: No caption is due to be shown.
  if (captionContent === NO_CAPTION) return undefined;

  if (artSlot?.isCoverArtActive) {
    const coverArtCaption = artSlot?.raw?.coverArtCaption;
    return { caption: coverArtCaption, text: coverArtCaption };
  }

  // START: Harvest caption.
  // TODO: Consider baking artSlot enhancements into artSlot code itself
  artSlot.useVideoIdForCaptionKey = !!inlineVideo && !!videoData;

  artSlot.videoId = artSlot.useVideoIdForCaptionKey
    ? get(videoData, "_id", undefined)
    : undefined;

  const captionKey = `feature_image_${generateCaptionKey(artSlot)}`;

  let text = overrides[captionKey] || get(content, `${captionKey}`, "");
  let aspectRatio;

  // NOTE: imageData will always be undefined when isAdmin=false.
  // That means if caption from imageData goes unedited, it won't
  // appear when published. The notPublishable flag will be used
  // in the admin to indicate the caption will not appear when published.
  let notPublishable = false;
  let forbiddenButUnstoppable = false;

  let placeholderValue = "Write caption here";
  if (!text && artSlot.anglerfishId === get(imageData, "_id", unmatchable)) {
    placeholderValue =
      (captionContent === NORMAL_CAPTION &&
        get(
          imageData,
          "fusion_additions.caption_info.dangerous.raw_caption"
        )) ||
      (captionContent === CREDITS_ONLY_CAPTION &&
        get(imageData, "fusion_additions.caption_info.credits_display")) ||
      placeholderValue;

    notPublishable = true;
  } else if (
    text &&
    text.trim() ===
      get(
        imageData,
        "fusion_additions.caption_info.dangerous.raw_caption",
        unmatchable
      ).trim()
  ) {
    placeholderValue = text;
    forbiddenButUnstoppable = true;
  }

  if (!text || text === placeholderValue) {
    if (artSlot.useVideoIdForCaptionKey) {
      text = getVideoCaption(videoData, captionContent);
    } else {
      text = getImageCaption({ content, imageData, artSlot, overrides });
    }
    // NOTE: This may be useless (increasingly convinced)
    if (!text) {
      text = artSlot.caption || undefined;
    }
  }
  // END: Harvest caption

  if (!isAdmin && text === placeholderValue) {
    text = undefined;
  } else if (isAdmin || (text && text !== placeholderValue)) {
    text = text || placeholderValue;
  }

  // START: Aspect ratio
  if (videoData) {
    aspectRatio = getVideoAspectRatio(videoData);
  } else {
    aspectRatio = getImageAspectRatio({ artSlot, overrides });
  }
  // END: Aspect ratio

  // NOTE: Replace line breaks with spaces, continuous spaces with one space, only space with blank string
  text =
    text &&
    text
      .replace(/(\r\n|\n|\r)/gm, " ")
      .replace(/\s+/g, " ")
      .replace(/^\s+$/, "");

  return {
    text,
    caption: text,
    aspectRatio,
    notPublishable: isAdmin ? notPublishable : false,
    forbiddenButUnstoppable: isAdmin ? forbiddenButUnstoppable : false,
    path: captionKey
  };
};

getCaption.propTypes = {
  content: PropTypes.object,
  artSlot: PropTypes.object,
  imageData: PropTypes.object,
  videoData: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};
