import PropTypes from "prop-types";

// Ripped from https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
export const ConditionalWrapper = ({ condition, wrapper, children }) => {
  // This can break the page if no children are passed in,
  // which can happen in cases of no content supplied.
  if (!children) return null;
  return condition ? wrapper(children) : children;
};

ConditionalWrapper.propTypes = {
  condition: PropTypes.any,
  wrapper: PropTypes.any,
  children: PropTypes.any
};
