/* eslint-disable */

/**
 * Contains the startup snippets supplied by Permutive copied directly (but with prettier applied)
 * DO NOT MODIFY
 * These are saved here in a separate file so we can disable eslint (not our code)
 */

/**
 * Copied directly from Permutive's startup snippet
 * DO NOT MODIFY (should re-copy from Permutive snippet if need be)
 *
 * @desc Populates the Google Publisher Tag object with custom targeting key-values.
 * This snippet is set up to fetch this user's segment codes with values that are
 * cached in localStorage by the Permutive library's code on a previous page load.
 * This lets us use the user's previous segments while new segments are calculated.
 * These will be overwritten once the Permutive script loads.
 */
export function loadPreviousSegments() {
  (window.googletag = window.googletag || {}),
    (window.googletag.cmd = window.googletag.cmd || []),
    window.googletag.cmd.push(function () {
      if (0 === window.googletag.pubads().getTargeting("permutive").length) {
        var e = window.localStorage.getItem("_pdfps");
        window.googletag
          .pubads()
          .setTargeting("permutive", e ? JSON.parse(e) : []);
        var o = window.localStorage.getItem("permutive-id");
        o &&
          (window.googletag.pubads().setTargeting("puid", o),
          window.googletag
            .pubads()
            .setTargeting("ptime", Date.now().toString())),
          window.permutive.config.viewId &&
            window.googletag
              .pubads()
              .setTargeting("prmtvvid", window.permutive.config.viewId),
          window.permutive.config.workspaceId &&
            window.googletag
              .pubads()
              .setTargeting("prmtvwid", window.permutive.config.workspaceId);
      }
    });
}
