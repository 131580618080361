import { pageView as gaPageView } from "./google-analytics";
import clavisPageView, { contentToClavisConfig } from "./clavisService";
import { stripOutInternalParams } from "../history";

/* eslint no-underscore-dangle: 0 */
/* eslint global-require: 0 */

const SCRIPT_URL =
  "https://www.washingtonpost.com/wp-stat/analytics/latest/main.js";

global._twpaq = global._twpaq || [];

function getInitializedLib() {
  require("scriptjs")(SCRIPT_URL);

  return new Promise((resolve) => {
    global._twpaq.push(resolve);
  }).then((Analytics) => {
    Analytics.init({
      suppressTrackPageLoad: true,
      service: { comscore: false, chartbeat: true }
    });
    return Analytics;
  });
}

let libPromise;
export function getLib() {
  if (!libPromise) {
    libPromise = getInitializedLib();
  }
  return libPromise;
}

/**
 * Sending a page view to Google Analytics and Clavis
 * @param {object} props The global content props
 * @param {object} pwapi The pwapi object
 */
export function sendGAPageview(globalContent, pwapi, props) {
  if (globalContent) {
    gaPageView(globalContent, pwapi, props);
    clavisPageView({
      ...contentToClavisConfig(globalContent),
      currentUrl: globalContent.canonical_url || window.location.href
    });
    stripOutInternalParams();
  }
}

/**
 * Sending a click event to Google Analytics
 * @param {string} category The click event category
 * @param {string} action The click event action
 * @param {string} label The click event label
 */
export function sendGAClick(category, action, label, additionalParams) {
  if (typeof window === "undefined" || !window.dataLayer) return;
  const e = {
    event: `site-${action}`,
    category,
    action,
    label,
    ...additionalParams
  };

  if (additionalParams && additionalParams.event)
    e.event = additionalParams.event;
  window.dataLayer.push(e);
}

/**
 * Send a page view to Omniture
 * @param {object} props The global content props
 */
export function sendOmniturePageview(props, options) {
  getLib().then((Analytics) => {
    Analytics.sendPageview({ ...props, site: "fusion" }, options);
  });
}

/** */
export function getTidValue(props) {
  const prefix = props.prefix ? `${props.prefix}` : "";
  const idFirst = props.idFirst ? `_${props.idFirst}` : "";
  const idSecond = props.idSecond ? `_${props.idSecond}` : "";
  const idThird = props.idThird ? `_${props.idThird}` : "";
  const index = props.index ? `_${props.index}` : "";
  const paramValue = `${prefix}${idFirst}${idSecond}${idThird}${index}`;

  return paramValue;
}

/**
 * Returns the tracking ID query param by itself:
 * @param {object} props has optional fields url, prefix, idFirst, idSecond, idThird
 * @returns {string} generated ?itid= or &itid= query param
 */
export function getTidQueryParam(props) {
  const paramValue = getTidValue(props);

  return props.url
    ? `${props.url.indexOf("?") > -1 ? "&" : "?"}itid=${paramValue}`
    : "";
}

/**
 * Returns the concatenation of props.url and the tracking ID query param
 * @param {object} props has optional fields url, prefix, idFirst, idSecond, idThird
 * @returns {string} empty string if props.url is falsy. Otherwise returns the TID appended to props.url
 */
export function generateTid(props) {
  if (!props.url) return "";
  return `${props.url}${getTidQueryParam(props)}`;
}
