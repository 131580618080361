import curry from "lodash.curry";

export const defaultTo = curry((defaultValue, value) => value || defaultValue);

export const prop = (key) => (obj) => {
  try {
    const o = obj && typeof obj === "object" && !Array.isArray(obj) ? obj : {};
    return o[key];
  } catch (e) {
    return undefined;
  }
};

export const mapValues = (fnOrKey) => (obj) => {
  try {
    const o = obj && typeof obj === "object" && !Array.isArray(obj) ? obj : {};
    const isFn = typeof fnOrKey === "function";
    return Object.entries(o).reduce((acc, [key, val]) => {
      return { ...acc, [key]: isFn ? fnOrKey(val) : val[fnOrKey] };
    }, {});
  } catch (e) {
    return {};
  }
};
