import { isVideoUrl } from "~/shared-components/story-card/_children/Video.helpers";
import {
  isHTMLFile,
  isHTMLFragment
} from "~/shared-components/story-card/_children/CustomHTML.helpers";

/**
 * Check if the alternate art url is for a video
 * @param {object|string} art the content of the alternate art
 * @return {boolean} returns true if this url is for a video
 */
export const isVideoAltArt = (art = "") => {
  let altArt = art;
  if (typeof altArt === "object") {
    altArt = (art && art.altArt) || "";
  }
  return isVideoUrl(altArt);
};

/**
 * Check if the alternate art url is a html file
 * @param {object|string} art the content of the alternate art
 * @return {boolean} returns true if this ends in .html
 */
export const isHTMLFileAltArt = (art = "") => {
  let altArt = art;
  if (typeof altArt === "object") {
    altArt = (art && art.altArt) || "";
  }
  return isHTMLFile(altArt);
};

/**
 * Check if the alternate art is a HTML fragment
 * @param {object|string} art the content of the alternate art
 * @return {boolean} returns true if this matches an html tag
 */
export const isHTMLFragmentAltArt = (art = "") => {
  let altArt = art;
  if (typeof altArt === "object") {
    altArt = (art && art.altArt) || "";
  }
  return isHTMLFragment(altArt);
};
