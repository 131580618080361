import get from "lodash.get";
import { useAppContext } from "fusion-context";
import useApi from "~/components/utilities/use-api";
import { buildCanonicalUrl } from "~/components/utilities/wapo-canonical-url";
import { getUserId, getJucid } from "~/components/utilities/login-details";
import {
  hasLocalStorage,
  hasSessionStorage
} from "~/components/utilities/hasLocalStorage";
import { removeWWW } from "~/components/utilities/www-remover";
import { transform } from "../helpers/transform/transform-foryou-flex-headlines";
import { useRenderedContentContext } from "../../components/contexts/rendered-content-context";

/**
 * Makes a call to the for you flex endpoint
 *
 * docs:
 * https://foryou-flex-dev.perso.washpost.io/docs
 * https://foryou-flex-staging.washingtonpost.com/docs
 * https://foryou-flex.washingtonpost.com/docs ???
 *
 * */

const sourceName = "foryou-flex-headlines";

const defaultQuery = {
  env: "prod"
};

// TODO: Update these endpoints
const endpoints = {
  local:
    "https://foryou-flex-staging.washingtonpost.com/recommendations/v1/articles/",
  sandbox:
    "https://foryou-flex-staging.washingtonpost.com/recommendations/v1/articles/",
  prod: "https://foryou-flex.washingtonpost.com/recommendations/v1/articles/"
};

const EMPTY = "[]";

/**
 * @param {array} Array of readlist objects where the u key is the url
 * @returns {array} array of urls (really just paths)
 */
const mapToUrl = ({ u }) => u;

/**
 * @returns {array} The sessionStorage.readlist4u value as an array.
 * sessionStorage.readlist4u IS maintained by this code
 */
const getReadlist4u = () =>
  JSON.parse(
    hasSessionStorage() ? sessionStorage.getItem("readlist4u") || EMPTY : EMPTY
  );

/**
 * @returns {array} The localStorage.readlist value as an array.
 * localStorage.readlist IS NOT maintained by this code
 */
const getReadlist = () =>
  JSON.parse(
    hasLocalStorage() ? localStorage.getItem("readlist") || EMPTY : EMPTY
  );

/**
 * @returns {array} A subset of the localStorage.readlist value as an array. Any item in
 * the array whose url ('u' value) is in sessionStorage.readlist4u is removed from the
 * returned array.
 */
const getReadlistToUse = ({ excludeIfClicked = false }) => {
  const readlist = getReadlist();
  const readlist4u = excludeIfClicked ? [] : getReadlist4u();
  return readlist.filter(({ u }) => !readlist4u.map(mapToUrl).includes(u));
};

const getExclusionsFromList = (urls) => {
  return JSON.stringify(
    urls
      .map((url) => removeWWW(url))
      .filter(
        (_, i, self) => self.indexOf(_) === i // uniquify
      )
      .filter(Boolean)
      .filter((url) => /^\//.test(url))
  );
};

/**
 * @param {content} the 4u results
 * @returns {array} An array of urls fit for being added to the localStorage.exclusions value
 */
const getExclusionsFromContent = (content, existing) => {
  const fromContent = get(content, "items", []).map(
    ({ canonical_url }) => canonical_url
  );
  return getExclusionsFromList([...existing, ...fromContent]);
};

/**
 * @returns {array} The localStorage.xclusions value as an array.
 * "localStorage.exclusions IS maintained by this code
 */
const getExclusionsFromLocalStorage = () =>
  JSON.parse(
    hasLocalStorage() ? localStorage.getItem("exclusions") || EMPTY : EMPTY
  );

/**
 * Sets localStorage.exclusions based on existing value and new items in content
 * @param {content} the 4u results
 */
const setExclusionsInLocalStorage = (content) => {
  if (hasLocalStorage()) {
    const existing = getExclusionsFromLocalStorage();
    const exclusions = getExclusionsFromContent(content, existing);
    if (exclusions.length) {
      localStorage.setItem("exclusions", exclusions);
    }
  }
};

/**
 * Removes localStorage.exclusions, which is removed at various points in the lifecyle of
 * the multi-view page
 */
const removeAllExclusionsInLocalStorage = () => {
  if (hasLocalStorage()) {
    localStorage.removeItem("exclusions");
  }
};

/**
 * Adds a url to localStorage.readlist
 * @param {url} string - the url (pathname, really) to add
 */
const addToReadlist = (item) => {
  const { u: url } = item;
  if (url && hasLocalStorage()) {
    const readlist = getReadlist();
    readlist.unshift(item);
    localStorage.setItem(
      "readlist",
      JSON.stringify(
        readlist
          .filter(
            ({ u }, i, self) => self.map(mapToUrl).indexOf(u) === i // uniquify
          )
          .slice(0, 50)
      )
    );
  }
};

/**
 * Adds a url to sessionStorage.readlist4u
 * @param {url} string - the url (pathname, really) to add
 */
const addToReadlist4u = (item) => {
  const { u: url } = item;
  if (url && hasSessionStorage()) {
    const readlist4u = getReadlist4u();
    readlist4u.unshift(item);
    sessionStorage.setItem(
      "readlist4u",
      JSON.stringify(
        readlist4u
          .filter(
            ({ u }, i, self) => self.map(mapToUrl).indexOf(u) === i // uniquify
          )
          .slice(0, 50)
      )
    );
  }
};

// START: Needed to handle multi-view lifecycle
/**
 * Conditionally removes localStorage.exclusions on tab change
 * @param {activeTab} string - the active tab
 * @param {isAlwaysActive} bool - only remove if it's not always active
 */
const onTabChange = ({ activeTab, isAlwaysMounted }) => {
  if (sourceName === activeTab && !isAlwaysMounted)
    removeAllExclusionsInLocalStorage();
};

/**
 * Conditionally add url to sessionStorage.readlist4u
 * @param {a} object - the anchor element that should have a pathname
 * @param {activeTab} string - the active tab
 */
const onClick = ({ a, activeTab, ...rest }) => {
  if (sourceName === activeTab && a?.pathname) {
    const env = rest?.query?.query?.env;
    const t = new Date().getTime();
    const item = { u: a.pathname, t };
    addToReadlist4u(item);
    if (env !== "prod") {
      addToReadlist(item);
    }
  }
};

/**
 * Removes localStorage.exclusions when the page unloads
 */
const onUnload = () => {
  removeAllExclusionsInLocalStorage();
};
// END: Needed to handle multi-view lifecycle

const getCanonicalUrl = (props) =>
  removeWWW(
    buildCanonicalUrl(props) ||
      (typeof window !== "undefined" ? window.location.pathname : undefined)
  );

const resolveHeaders = () => {
  return {
    accept: "application/json",
    "content-type": "application/json"
  };
};

const resolveBody = (query = {}) => {
  const {
    limit = 10,
    exclusionStrategy = "localStorage",
    exclusionsFromPage = [],
    surface,
    ctx,
    bp = "xs"
  } = query;

  let readlistToUse = EMPTY;
  if (hasLocalStorage()) {
    readlistToUse = JSON.stringify(getReadlistToUse(query));
  }

  let exclusions = EMPTY;
  if (exclusionStrategy === "localStorage" && hasLocalStorage()) {
    exclusions = localStorage.getItem("exclusions") || EMPTY;
  } else if (exclusionStrategy === "page") {
    exclusions = getExclusionsFromList(exclusionsFromPage) || EMPTY;
  }

  // NOTE: Don't send wapo_login_id if it's falsey
  const userId = getUserId();
  const wapoLoginId = userId ? `"wapo_login_id": "${userId}",\n` : "";

  return `{
    "limit": ${limit},
    "readlist": ${readlistToUse},
    "exclusions": ${exclusions},
    "contentType": "story",
    "rules": {},
    "interface": "site",
    "surface": "${surface}",
    "surface_variant": "${bp}",
    ${wapoLoginId}
    "j_ucid": "${getJucid()}",
    "current_url": "${getCanonicalUrl(ctx)}"
  }`;
};

const getDomain = (env) => {
  if (!env) env = "prod";
  return endpoints[env] || endpoints.prod;
};

const resolveOptions = (query = {}) => ({
  method: "POST",
  credentials: "include",
  headers: resolveHeaders(query),
  body: resolveBody(query)
});

const resolve = (query = {}) => {
  query = { ...defaultQuery, ...query };
  const endpoint = getDomain(query.env);
  return endpoint;
};

// NOTE: return truthy or falsey
const callback = (content, isAdmin) => {
  // NOTE: Don't want results getting exhausted in the admin
  if (isAdmin) return true;
  if (content?.items) {
    setExclusionsInLocalStorage(content);
    return true;
  }
  return false;
};

/**
 *
 * Foryou endpoint contract can be found at
 * https://paper.dropbox.com/doc/ForYouUser-Info-Data-Contract--B~G5Qr8TaxysBg~3FLulbGugAg-gC9BKEVuvD5R6z2Vd9zZP
 *
 */
const useHook = (query = {}) => {
  const ctx = useAppContext();
  const { getRenderedContent } = useRenderedContentContext();
  const exclusionsFromPage = getRenderedContent({
    chainDisplayNames: [
      "hp-banner-high",
      "hp-top-table-high",
      "hp-banner-main",
      "hp-top-table-main",
      "hp-more-top-stories"
    ]
  }).map(([, v]) => v.canonical_url);
  const withEnhancements = { exclusionsFromPage, ctx, ...query };

  const options = resolveOptions(withEnhancements);
  const endpoint = resolve(withEnhancements);
  const config = { callback, transform: (data) => transform(data, { query }) };
  const data = useApi({ endpoint, options, ...config });
  return data;
};

export default {
  resolveOptions,
  resolve,
  params: {
    // The below parameters are determined programmatically
    bp: "text",
    env: "text",
    // The below parameters are provided through OverrideFormWidget in the admin
    limit: "number",
    surface: "text"
  },
  callback,
  transform,
  handlers: { onTabChange, onClick, onUnload },
  useHook,
  defaultContentConfig: {
    exclusionStrategy: "page"
  }
};
