import compose from "lodash.compose";
import { defaultTo } from "./utils";

const defaultToNull = defaultTo(null);

const toLower = (str) => {
  if (typeof str === "string") {
    return str.toLowerCase();
  }
  return "";
};

/**
 * Default processing pipeline for formatted metadata values
 */
const processOutput = compose(defaultToNull, toLower);

export function formatArcId({ _id }) {
  return defaultToNull(_id);
}

export function formatAuthors({ authors = [] } = {}) {
  if (!authors) {
    return null;
  }

  const delimiter = "; ";
  return processOutput(authors.join(delimiter));
}

export function formatBlogName({ blogName } = {}) {
  return processOutput(blogName);
}

export function formatClavisKeywords({ clavisKeywords }) {
  const delimiter = "; ";
  return clavisKeywords.join(delimiter);
}

export function formatCmsSystem({ cms }) {
  return processOutput(cms);
}

export function formatCommercialNode({ commercialNode } = {}) {
  return processOutput(commercialNode);
}

export function formatContentCategory({ contentCategory } = {}) {
  return defaultToNull(contentCategory);
}

function getContentId({ _id, cms, postId, sourceId } = {}) {
  switch (cms) {
    case "wordpress":
      return postId;
    case "methode":
      return sourceId;
    default:
      return _id;
  }
}

export function formatContentId(metadata = {}) {
  const contentId = getContentId(metadata);
  return processOutput(contentId);
}

function getContentType({ contentType, blogName }) {
  if (contentType === "story") {
    return blogName ? "blog" : "article";
  }

  return contentType;
}

export function formatContentType(metadata = {}) {
  const contentType = getContentType(metadata);
  return processOutput(contentType);
}

export function formatHeadline({ headline } = {}) {
  return defaultToNull(headline);
}

export function formatHierarchy({ contentType, section, subsection } = {}) {
  const primary = section;
  const secondary = subsection && subsection !== section ? subsection : null;
  const tertiary = processOutput(contentType);
  return processOutput(
    [primary, secondary, tertiary].filter(Boolean).join("|")
  );
}

function getPagenameContentId(metadata) {
  let contentId = getContentId(metadata);
  if (metadata.cms === "methode") {
    const contentIdParts = contentId.split("-");
    contentId = contentIdParts[contentIdParts.length - 1];
  }

  return contentId;
}
/* This formats a date as YYYYMMDD which is close
 * to Sweden's standard date format
 */

function formatDateForTracking(dateString) {
  return Date.parse(dateString)
    ? new Date(dateString)
        .toLocaleString("sv-SE", {
          timeZone: "UTC",
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        })
        .replace(/-/g, "")
    : "";
}

/*
 * Pagenames are constructed using the following template:
 * section:contentType:blogName - contentId - publishDate - in_url_headline
 *
 * Note that null/undefined are not included
 */
export function formatPageName(metadata = {}) {
  const { section, blogName, firstPublishDate, inUrlHeadline } = metadata;

  const contentType = getContentType(metadata);
  const contentId = getPagenameContentId(metadata);
  const formattedPublishDate = formatDateForTracking(firstPublishDate);

  /*
   * const blogNameInclusionContentTypeWhitelist = ['blog', 'liveblog'];
   * const shouldIncludeBlogName = blogNameInclusionContentTypeWhitelist.includes(
   *   contentType
   * )
   */
  // For now we're going to rely on the content API to tell us when there's a
  // blog name correctly.
  const shouldIncludeBlogName = true;
  const pageName = [
    [section, contentType, shouldIncludeBlogName ? blogName : null]
      .filter(Boolean)
      .join(":"),
    ...[contentId, formattedPublishDate, inUrlHeadline]
  ]
    .filter(Boolean)
    .join(" - ");

  return processOutput(pageName);
}

/*
 * TODO: remove blog name from section
 */
export function formatSection({ section } = {}) {
  return processOutput(section);
}

export function formatSeoKeywords({ seoKeywords }) {
  const delimiter = "; ";
  return seoKeywords.join(delimiter);
}

export function formatSource({ source } = {}) {
  return processOutput(source);
}

export function formatSubsection({ section, subsection } = {}) {
  if (!section) {
    return null;
  }
  const dedupeSection = (sectionString) => {
    // This seems to be the pattern that is gaurenteed to appear in this edge case.
    // example: wp - history - history - retropolis
    // will become : wp - history - retropolis
    const sectionRegex = new RegExp(`${section} - ${section} -`, "i");
    return sectionString.replace(sectionRegex, `${section} -`);
  };
  return subsection && subsection !== section
    ? dedupeSection(`${section} - ${subsection}`)
    : section;
}

export default function formatTracking(metadata) {
  return {
    arc_id: formatArcId(metadata),
    author: formatAuthors(metadata),
    blog_name: formatBlogName(metadata),
    clavis_keywords: formatClavisKeywords(metadata),
    cms_system: formatCmsSystem(metadata),
    commercial_node: formatCommercialNode(metadata),
    content_category: formatContentCategory(metadata),
    content_id: formatContentId(metadata),
    content_type: formatContentType(metadata),
    headline: formatHeadline(metadata),
    hierarchy: formatHierarchy(metadata),
    page_name: formatPageName(metadata),
    section: formatSection(metadata),
    seo_keywords: formatSeoKeywords(metadata),
    subsection: formatSubsection(metadata),
    source: formatSource(metadata)
  };
}
