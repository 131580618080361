import React from "react";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import Card from "./Card/default";
import { ZeusAd } from "../zeus/default";

const Divider = styled("hr", {
  margin: "$100 0", // 16px
  border: `1px solid ${theme.colors.gray400}`,
  borderBottom: "none"
});

/**
 * Process content and create cards for a Multi-view feed.
 *
 * @returns the list of cards, with ads inserted
 */
export const insertAds = (
  content,
  globalContent,
  idxStart,
  adLength,
  adFrequency
) => {
  let adIndex = idxStart;

  return content?.reduce((acc, { _id, ...item }, i) => {
    acc.push(<Card key={_id} id={_id} item={item} />);

    // start inserting ads after first adFrequency stories
    if ((i + 1) % adFrequency === 0 && adIndex - idxStart < adLength) {
      acc.push(
        <ZeusAd
          id={`${_id}_ad`}
          adType={`bigbox_${adIndex}`}
          isAdmin={false}
          globalContent={globalContent}
        />
      );
      adIndex += 1;
      return acc;
    }

    if (i !== content?.length - 1) {
      acc.push(<Divider id={`${_id}_divider`} />);
    }

    return acc;
  }, []);
};
