import get from "lodash.get";
import { getCanonicalUrl } from "./ans-helper";

/**
 * Washington Post buisness logic around what the real URL for the page is.
 *
 * @param {object} props - containing at lease siteProperties.siteDomainURL and globalContent
 * @param {*boolean} partnerRedirect - (defualt false) should the url use the partner redirect
 */
export const buildCanonicalUrl = (props, partnerRedirect = false) => {
  const {
    siteProperties: { siteDomainURL },
    globalContent
  } = props;

  const canonicalMetaValue =
    siteDomainURL &&
    props?.metaValue("canonicalUrl") &&
    siteDomainURL + props?.metaValue("canonicalUrl");
  if (canonicalMetaValue) return canonicalMetaValue;

  if (partnerRedirect) {
    // There is a requirement for partners, such as bloomberg, to override the top level canonical_url
    // and use partner url instead
    const partnerUrl = get(
      globalContent,
      "additional_properties.review_info.partner_url"
    );
    if (partnerUrl) return partnerUrl;
  }

  return getCanonicalUrl(siteDomainURL, globalContent);
};

/**
 * Build the Real URL for the page and ensure you use the partner redirect.
 * Explict version of buildCanoncialUrl(props, true) - for clarity when used around the site.
 *
 * @param {object} props - containing at lease siteProperties.siteDomainURL and globalContent
 */
export const buildCanonicalUrlWPartnerRedirect = (props) => {
  return buildCanonicalUrl(props, true);
};
