import { useApi } from "~/components/utilities/use-api";
import { transform } from "../helpers/transform/transform-foryou-topics";

const resolveOptions = ({ includeCredentials = false }) => {
  return {
    method: "GET",
    ...(includeCredentials ? { credentials: "include" } : {})
  };
};

const defaultQuery = {
  env: "prod",
  limit: 1
};

const endpoints = {
  prod: "https://www.washingtonpost.com/subscribe/foryouapi/v2",
  sandbox: "https://subs-stage.washingtonpost.com/foryouapi/v2"
};

const getDomain = ({ env = "prod", includeCredentials = false }) => {
  const endpoint = env !== "prod" ? endpoints.sandbox : endpoints.prod;
  return `${endpoint}${includeCredentials ? "/current" : ""}/recommendations`;
};

const getLocalEndpoint = (props) => {
  const { endpoint, debug, ...query } = props;

  return debug === true
    ? `/api/foryou-topics?debug=true`
    : `/api/foryou-topics?source=${encodeURIComponent(
        endpoint
      )}&options=${encodeURIComponent(JSON.stringify(resolveOptions(query)))}`;
};

const resolve = (query = {}) => {
  query = { ...defaultQuery, ...query };
  const { env, limit } = query;

  const domain = getDomain(query);
  const params = `?size=${limit}`;

  const endpoint = `${domain}${params}`;

  // NOTE: bypass CORS error selectively
  return env === "local" ? getLocalEndpoint({ ...query, endpoint }) : endpoint;
};

export const useForYouTopics = (query = {}) => {
  const options = resolveOptions(query);
  const endpoint = resolve(query);
  const data = useApi({ endpoint, options, transform });
  return data.state === "SUCCESS" ? data.content || [] : [];
};

export default {
  resolveOptions,
  resolve,
  params: {
    limit: "number",
    env: "text"
  }
};
