export default `
{
  site {
    additional_info {
      hp_subscribe_nav_text
      hp_subscribe_sections_text
      subscribe_nav_text
      subscribe_sections_text
      hp_gift_nav_show
      hp_gift_nav_show_sub
      hp_gift_url_genesis
      hp_gift_nav_text
      gift_url_genesis
      gift_nav_text
      gift_nav_show
      gift_nav_show_sub
      gift_nav_tid
      gift_nav_user_tid
    }
  }
}
`;
