import { Box, styled } from "@washingtonpost/wpds-ui-kit";

export const AlwaysPresentContent = styled(Box, {
  variants: {
    display: {
      true: {
        display: "block"
      },
      false: {
        display: "none"
      }
    }
  }
});
