import { removeWWW } from "../../../utilities/www-remover";
import { FOR_YOU_SURFACE_DEFAULT } from "../utilities/for-you";

export const contentConfigCustomFieldNames = [
  "flexFeatureContentConfig",
  "liveGraphicsContentConfig",
  "carouselContentConfig",
  "alertsContentConfig",
  "newsletterContentConfig"
];

// Define the shape each content source config needs
export const getAssembledContentConfigValue = (
  contentConfigType,
  { contentPath, authorSlug, query, section, tag, limit, offset, id, surface }
) => {
  // specialized content configs
  switch (contentConfigType) {
    case "liveGraphicsContentConfig":
      return {
        "elex-live-graphic": { id },
        "olympics-live-graphic": { id },
        "no-content": {}
      };
    case "alertsContentConfig":
      return { alerts: { day: limit } };
    case "newsletterContentConfig":
      return { newsletter: { id } };
    case "carouselContentConfig":
      return {
        "no-content": {},
        "prism-author": { "author-slug": authorSlug, limit, offset },
        "prism-query": { query, limit, offset },
        "prism-section": { section, limit, offset },
        "prism-tag": { tag, limit, offset },
        "wsk-collection": { id, limit, offset },
        "most-read": { section, limit },
        "recipe-promos-by-tag": { tag, limit }
      };
    case "flexFeatureContentConfig":
    default:
      return {
        "prism-promo": { content_path: contentPath || null },
        "prism-author": { "author-slug": authorSlug, limit },
        "prism-query": { query, limit },
        "prism-section": { section, limit },
        "prism-tag": { tag, limit },
        "wsk-collection": { id, limit },
        "most-read": { section, limit },
        "recipe-promos-by-tag": { tag, limit },
        "foryou-flex-headlines": {
          limit,
          surface: surface || FOR_YOU_SURFACE_DEFAULT
        },
        "prism-placeholder-feed": { limit }
      };
  }
};

// If they change content sources, blow away custom data for certain fields
export const resetSelectCustomFields = (entity, fieldsToReset) => {
  fieldsToReset.forEach((fieldName) => {
    entity.props.customFields[fieldName] = "";
  });
};

export const displayNameMap = {
  "foryou-flex-headlines": "foryou-flex",
  "recipe-promos-by-tag": "recipe-tag"
};

export const paramKeyMap = {
  "author-slug": "authorSlug"
};

export const defaultContentConfig = {
  authorSlug: "",
  contentPath: "",
  query: "",
  section: "",
  tag: "",
  limit: "5",
  offset: "0",
  id: "",
  surface: ""
};

export const keysToTriggerUpdate = [
  "content_path",
  "section",
  "id",
  "author-slug",
  "query",
  "tag"
];

export const getShouldUpdate = (oldContentConfigVals, newContentConfigVals) => {
  return keysToTriggerUpdate.find(
    (key) =>
      typeof oldContentConfigVals[key] !== "undefined" &&
      removeWWW(oldContentConfigVals[key]) !==
        removeWWW(newContentConfigVals[key])
  );
};
