import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

export const getRenderedAds = (adType, renderedAds) => {
  return renderedAds && renderedAds.length !== 0
    ? renderedAds.includes(`slug_${adType}`)
    : false;
};

const useAdEvent = (ev, fn, options) => {
  const { condition = true, interval, slots = [] } = options || {};
  const cb = useRef(fn);
  useEffect(() => {
    if (typeof window !== "undefined" && window.googletag && condition) {
      const handler = !Number.isNaN(interval)
        ? debounce(cb.current, interval)
        : cb.current;
      window.googletag.cmd = window.googletag.cmd || [];
      window.googletag.cmd.push(() => {
        window.googletag.pubads().addEventListener(ev, (event) => {
          const slotId = event.slot.getSlotElementId() || "";
          if (slots.includes(slotId.replace(/slug_/, ""))) {
            handler(event);
          }
        });
      });
    }
    // eslint-disable-next-line react/display-name
    return () => null;
  }, [cb, ev, condition, interval, slots]);
};

useAdEvent.propTypes = {
  fn: PropTypes.func,
  ev: PropTypes.string,
  options: PropTypes.shape({
    condition: PropTypes.bool,
    interval: PropTypes.number,
    slots: PropTypes.array
  })
};

export const useAdLoad = (fn, options) =>
  useAdEvent("slotRenderEnded", fn, options);
export const useAdView = (fn, options) =>
  useAdEvent("impressionViewable", fn, options);
