import PropTypes from "prop-types";

export const CustomHTMLPropTypes = {
  customFields: PropTypes.shape({
    rawHtml: PropTypes.richtext.tag({
      name: "HTML",
      description: "Enter HTML."
    })
  })
};
