import compose from "lodash.compose";
import get from "lodash.get";
import { defaultTo, mapValues, prop } from "./utils";

const defaultToEmptyString = defaultTo("");
const defaultToEmptyObject = defaultTo({});

const getTracking = compose(defaultToEmptyObject, prop("tracking"));

const getId = prop("_id");

function getAuthors({ credits = {} }) {
  const { by = [] } = credits;
  return by.map(prop("name"));
}

const getBlogName = compose(prop("blog_name"), getTracking);

export function getClavisKeywords(ans) {
  if (!ans.taxonomy || !ans.taxonomy.keywords) {
    return [];
  }

  return ans.taxonomy.keywords.map((kw) => kw.keyword);
}

const getCommercialNode = compose(prop("commercial_node"), getTracking);

const getContentCategory = compose(prop("content_category"), getTracking);

const getContentType = prop("type");

const getHeadline = compose(
  prop("basic"),
  defaultToEmptyObject,
  prop("headlines")
);

const getInUrlHeadline = compose(prop("in_url_headline"), getTracking);

const getPostId = compose(prop("post_id"), getTracking);

const getPublishDate = prop("publish_date");

function getFirstPublishDate(ans) {
  return get(ans, "first_publish_date", null);
}

function getSections(ans) {
  const { tracking } = ans;

  if (!tracking) {
    return { section: null, subsection: null };
  }

  return { section: tracking.section, subsection: tracking.subsection };
}

export function getSeoKeywords(ans) {
  if (!ans.taxonomy || !ans.taxonomy.seo_keywords) {
    return [];
  }

  return ans.taxonomy.seo_keywords;
}

function getSourceMetadata(ans) {
  return ans.source || {};
}

function getCms(ans) {
  const { system } = getSourceMetadata(ans);

  if (!system) {
    return null;
  }

  return system;
}

function getSource(ans) {
  if (!ans.owner || !ans.owner.name) {
    return null;
  }

  return ans.owner.name;
}

function getSourceId(ans) {
  const { source_id: sourceId } = getSourceMetadata(ans);

  if (!sourceId) {
    return null;
  }

  return sourceId;
}

/**
 * Casts the values in metadata to an expected type.
 */
const castRawMetadata = mapValues(defaultToEmptyString);

/**
 * Extracts fields from a raw ANS object needed for constructing tracking
 * metadata.
 */
export default function extractRawMetadata(ans = {}) {
  const { section, subsection } = getSections(ans);

  return castRawMetadata({
    _id: getId(ans),
    authors: getAuthors(ans),
    blogName: getBlogName(ans),
    clavisKeywords: getClavisKeywords(ans),
    cms: getCms(ans),
    commercialNode: getCommercialNode(ans),
    contentCategory: getContentCategory(ans),
    contentType: getContentType(ans),
    headline: getHeadline(ans),
    inUrlHeadline: getInUrlHeadline(ans),
    postId: getPostId(ans),
    publishDate: getPublishDate(ans),
    firstPublishDate: getFirstPublishDate(ans),
    section,
    seoKeywords: getSeoKeywords(ans),
    source: getSource(ans),
    sourceId: getSourceId(ans),
    subsection
  });
}
