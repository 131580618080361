/**
 * Config for Prism in Assembler runtimes
 */
const btoa = require("btoa");

if (typeof window === "undefined") {
  // eslint-disable-next-line global-require
  require("dotenv").config();
}

const NEXT_PUBLIC_EXTERNAL_CONTENT_LOCATION =
  process.env.NEXT_PUBLIC_EXTERNAL_CONTENT_LOCATION;
const NEXT_PUBLIC_INTERNAL_CONTENT_LOCATION =
  process.env.NEXT_PUBLIC_INTERNAL_CONTENT_LOCATION;
const PRISM_CONTENT_SOURCE = process.env.PRISM_CONTENT_SOURCE;
const PRISM_KEY = process.env.PRISM_KEY;

const prismEnvs = {
  local: {
    external: "http://localhost:3001",
    internal: "http://localhost:3001",
    contentSource: PRISM_CONTENT_SOURCE || "prism-content-api"
  },
  ci: {
    external: "https://www.washingtonpost.com/prism",
    internal: "https://www.washingtonpost.com/prism",
    contentSource: PRISM_CONTENT_SOURCE || "prism-content-api",
    headers: {
      Authorization: `Basic ${btoa(`prismproxy:${PRISM_KEY}`)}`
    }
  },
  sandbox: {
    contentSource: "prism-content-api",
    external: "https://www.washingtonpost.com/prism",
    internal: "https://prism-content-source-assembler.wpit.nile.works",
    headers: {
      Authorization: `Basic ${btoa(`prismproxy:${PRISM_KEY}`)}`
    }
  },
  prod: {
    contentSource: "prism-content-api",
    external: "https://www.washingtonpost.com/prism",
    internal: "https://prism-content-source-assembler.wpit.nile.works"
  }
};

const overrideExternal = NEXT_PUBLIC_EXTERNAL_CONTENT_LOCATION;
const overrideInternal = NEXT_PUBLIC_INTERNAL_CONTENT_LOCATION;

const getEnv = () => {
  let env;

  if (overrideExternal) {
    env = {
      external: overrideExternal,
      internal: overrideInternal || overrideExternal
    };
  } else {
    const prismEnv = process.env.PRISM_ENV || "prod";
    env = prismEnvs[prismEnv];
  }
  return env;
};

module.exports = getEnv();
