import React from "react";

const DefaultEditableProp = () => null;
export const AssemblerContext = React.createContext({
  EditableProperty: DefaultEditableProp,
  EditableArt: DefaultEditableProp,
  setTree: () => {},
  lastPublishedTimestamp: null,
  isMobile: false,
  isNoNav: false
});
export const useAssemblerContext = () => React.useContext(AssemblerContext);
