/*
 * formAction could have url params like:
 *    https://www.washingtonpost.com/recipes/?_focus=term
 * All the search params need to be added to the form as hidden input fields.
 * This method creates a list of objects that will become the hidden fields.
 *
 * @param {URL} url -- URL object
 * @returns {array} -- array of { type: "hidden" param, value } objects
 */
const getHiddenFields = (url) =>
  url?.search
    ? url.search
        .replace("?", "")
        .split("&")
        .reduce((acc, p) => {
          const [param, value] = p.split("=");
          if (param) acc.push({ type: "hidden", param, value });
          return acc;
        }, [])
    : [];

export const getForm = ({ overrides }) => {
  const {
    formShow = false,
    formMethod = "GET",
    formAction,
    formType = "search",
    formParam,
    formPlaceholder,
    formNameSpace
  } = overrides;

  if (!formShow || !formAction || !formParam) return undefined;

  let url;
  try {
    url = new URL(formAction);
  } catch (e) {
    /* do nothing */
  }
  if (!url) return undefined;

  const action = `${url.origin}${url.pathname}`;
  const hiddenFields = getHiddenFields(url);

  return {
    namespace: formNameSpace,
    method: formMethod,
    rawAction: formAction, // NOTE: for apps
    action,
    fields: [
      {
        type: formType,
        param: formParam,
        placeholder: formPlaceholder
      },
      ...hiddenFields
    ],
    linkDetail: "inline-search"
  };
};

/**
 * Grooms the form generated by getFrom() for jsonapp outputType. The overall
 * goal is to alter the object as little as possible.
 *
 * Hidden fields are removed from the list of fields. Since those search params
 * are already on the rawAction, replace the action with the rawAction.
 *
 * delete rawAction and namespace to match the jsonapp contract.
 *
 * @param {object} form - The form to groom
 * @return {object} - The groomed form
 */
export const groomFormForJsonApp = ({ form }) => {
  if (!form) return undefined;

  // NOTE: map rawAction to action
  form.action = form.rawAction;
  delete form.rawAction;

  form.fields = (form?.fields || []).filter(({ type }) => type !== "hidden");

  delete form.namespace;

  return form;
};
